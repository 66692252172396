@use "../../global"as *;

.p-block7 {
  margin: 40px 0 0;
  padding: 0 12px;

  @include mq('md', min) {
    margin: 120px 0 0;
    padding: 0 100px;
  }

  &.-gray {
    padding: 40px 12px 80px;
    background: $neutral--100;

    @include mq('md', min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }

    + .p-block7.-gray,
    + .p-block7.-blue {
      margin-top: 0;
    }
  }

  &.-blue {
    padding: 40px 12px 80px;
    background: $primary--700;

    @include mq('md', min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
      border-top: 1px solid $neutral--300;
    }

    + .p-block7.-gray,
    + .p-block7.-blue {
      margin-top: 0;
    }
  }
}

.p-block7__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block7__text1 {
  margin: 40px 0 0;
  @include mq('md', min) {
    margin: 48px 0 0;
  }
}

.p-block7__list1 {
  margin: 40px 0 0;
  @include mq('md', min) {
    margin: 48px 0 0;
  }
}