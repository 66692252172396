@use "../../global"as *;

.c-modal1 {
  background: rgba($text-icon---primary--black, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: grid;
  align-items: center;
  justify-content: center;
}

.c-modal1__inner1 {
  position: relative;
}

.c-modal1__movie1 {
  width: 1048px;
  height: 590px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.c-modal1__button1 {
  position: absolute;
  top: -40px;
  right: 0;
}
