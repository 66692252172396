@use "../../global"as *;

.p-block3 {
  padding: 40px 12px 80px;
  margin: 120px 0 0;
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    padding: 120px 100px 160px;
  }

  &.-white {
    margin: 40px 0 0;
    padding: 0 12px;
    background: $text-icon---primary--white;

    @include mq('md', min) {
      margin: 120px 0 0;
      padding: 0 100px;
    }

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }
  }

  &:not(.-white)+.p-block3:not(.-white) {
    @include mq('md', min) {
      margin-top: 0;
    }
  }

  @at-root {
    .p-block2 + & {
      @include mq('md', min) {
        margin-top: 80px;
      }
    }
  }
}

.p-block3__inner1 {

  @include mq('md', min) {
    position: relative;
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block3__title1 {
  display: none;

  @include mq('md', min) {
    display: block;
  }

  &.-show1 {
    display: block;
  }
}

.p-block3__title2 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }
}

.p-block3__list1 {
  margin: 32px 0 0;
  @include mq('md', min) {
    margin: 40px 0 0;
  }
}

.p-block3__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 32px 0 0;

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}


.p-block3__button1 {
  display: none;

  @include mq('md', min) {
    position: absolute;
    top: 18px;
    right: 0;
    margin: 0;
    display: block;
  }
}

.p-block3__pager1 {
  margin: 36px 0 0;
  padding: 12px 16px;

  @include mq('md', min) {
    margin: 40px 0 0;
  }
}

.p-block3__pager1__list1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.p-block3__pager1__list1__item1 {
  &>a {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background: $text-icon---primary--white;
    border-radius: 10rem;
    font-size: 16px;
    line-height: 1.25;
    color: $text-icon---primary--black;
    font-family: $font-family-en;
    text-decoration: none;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
      color: $text-icon---primary--white;
    }

    @at-root {
      .p-block3__pager1__list1__item1.-current>a {
        background: $text-icon---primary--black;
        color: $text-icon---primary--white;
        pointer-events: none;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--white;
          color: $text-icon---primary--black;
        }
      }
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }
  }

  &.-prev>a,
  &.-next>a {
    line-height: 1.8;
    width: 48px;
    height: auto;
    color: $text-icon---disabled--black;
    pointer-events: none;

    @media (prefers-color-scheme: dark) {
      color: rgba($text-icon---disabled--black, 0.5);
    }

    @at-root {

      .p-block3__pager1__list1__item1.-prev.-active>a,
      .p-block3__pager1__list1__item1.-next.-active>a {
        color: $text-icon---primary--black;
        pointer-events: all;

        @media (prefers-color-scheme: dark) {
          color: $text-icon---primary--white;
        }
      }
    }
  }

  &.-next>a {
    text-align: right;
  }

  &.-extend>a {
    color: $text-icon---disabled--black;
    pointer-events: none;

    @media (prefers-color-scheme: dark) {
      color: rgba($text-icon---disabled--black, 0.5);
    }
  }
}
