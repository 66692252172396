@use "../../global" as *;

.c-button21 {
  border: none;
  width: 100%;
  position: relative;
  display: block;
  text-decoration: none;
  color: $text-icon---primary--black;
  border-radius: 10rem;
  text-align: center;
  font-size: 16px;
  padding: 2px 40px 2px 32px;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;
  }

  @at-root {
    .p-keyVisual1:has(.p-keyVisual1__unit1__layer1:hover) .c-button21 {
      background: $neutral--200;
    }
  }
}
