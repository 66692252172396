@use "../../global" as *;

.p-block31 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  background: rgba(#000, 0.64);
  z-index: 10;

  &:has(.p-block31__button1 button.-agree) {
    display: none;
  }
}

.p-block31__inner1 {
  @include mq("md", min) {
    display: grid;
    grid-template-columns: 1fr 156px;
    gap: 24px;
    align-items: center;
  }
}

.p-block31__text1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.8;
  color: $text-icon---secondary--white;

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  a {
    color: $text-icon---primary--white;
  }

  br {
    display: none;

    @include mq("md", min) {
      display: block;
    }
  }
}

.p-block31__button1 {
  margin: 24px 0 0;

  @include mq("md", min) {
    margin: 0;
  }
}
