@use "../../global"as *;

.p-block28 {
  position: relative;
  padding: 40px 12px 80px;

  @include mq('md', min) {
    padding: 120px 100px 231px;
  }

  &.is-company {
    background: url('../images/block28_bg1_1.jpg') 0 0 / cover no-repeat;

    @include mq('md', min) {
      background: url('../images/block28_bg1_1_desktop.jpg') 0 0 / cover no-repeat;
    }
  }

  &.is-solution {
    background: url('../images/block28_bg1_2.jpg') 0 0 / cover no-repeat;

    @media (prefers-color-scheme: dark) {
      background: url('../images/block28_bg1_2_dark.jpg') 0 0 / cover no-repeat;
    }

    @include mq('md', min) {
      background: url('../images/block28_bg1_2_desktop.jpg') 0 0 / cover no-repeat;

      @media (prefers-color-scheme: dark) {
        background: url('../images/block28_bg1_2_desktop_dark.jpg') 0 0 / cover no-repeat;
      }
    }
  }

  &.is-innovation {
    background: url('../images/block28_bg1_3.jpg') 0 0 / cover no-repeat;

    @include mq('md', min) {
      background: url('../images/block28_bg1_3_desktop.jpg') 0 0 / cover no-repeat;
    }
  }

  &.is-sustainability {
    background: url('../images/block28_bg1_4.jpg') 0 0 / cover no-repeat;

    @include mq('md', min) {
      background: url('../images/block28_bg1_4_desktop.jpg') 0 0 / cover no-repeat;
    }
  }
}

.p-block28__image1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-block28__inner1 {
  position: relative;

  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block28__title1 {}

.p-block28__text1 {
  font-size: 16px;
  line-height: 1.8;
  color: $text-icon---primary--white;
  margin: 40px 0 0;

  @at-root {
    .p-block28.is-solution & {
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }
    }
  }

  @include mq('md', min) {
    margin: 48px 0 0;
    font-size: $font-size-body;
  }
}

.p-block28__list1 {
  margin: 32px 0 0;
  display: grid;
  gap: 12px;

  @include mq('md', min) {
    margin: 64px 0 0;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-block28__list1__item1 {}

.p-block28__button1 {
  margin: 36px 0 0;

  @include mq('md', min) {
    margin: 0;
    position: absolute;
    top: 18px;
    right: 0;
  }
}
