@use "../../global"as *;

.p-block6 {
  margin: 40px 0 0;
  padding: 36px 12px;
  background: $neutral--100;

  @include mq('md', min) {
    padding: 80px 100px;
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-block6__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block6__title1 {
  +.p-block6__list1 {
    margin-top: 16px;

    @include mq('md', min) {
      margin-top: 24px;
    }
  }
}

.p-block6__title2 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  margin: 40px 0 0;

  @include mq('md', min) {
    font-size: 32px;
    margin: 64px 0 0;
  }

  &:first-child {
    margin: 0;
  }

  @include mq('md', min) {}
}

.p-block6__list1 {
  @include mq('md', min) {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  +.p-block6__title1 {
    margin-top: 24px;

    @include mq('md', min) {
      margin-top: 40px;
    }
  }

  @at-root {
    .p-block6__title2+& {
      margin: 15px 0 0;

      @include mq('md', min) {
        margin: 24px 0 0;
      }
    }
  }
}

.p-block6__list1__item1 {}
