@use "../../global"as *;

.c-card14 {
  padding: 20px 0 16px;
  box-shadow: 0 8px 16px rgba($text-icon---primary--black, 0.12);
  background: $text-icon---primary--white;
  height: 100%;

  @include mq('md', min) {
    padding: 22px 0 5px;
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.c-card14__inner1 {}

.c-card14__title1 {
  font-size: 24px;
  text-align: center;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 32px;
  }
}

.c-card14__text1 {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 20px 0 0;

  @include mq('md', min) {
    font-size: 20px;
    margin: 4px 0 0;
  }
}

.c-card14__image1 {
  margin: 27px auto 0;
  width: 89px;

  @include mq('md', min) {
    margin: 48px auto 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  @at-root {
    .c-card14__title1 + & {
      @include mq('md', min) {
        margin-top: 80px;
      }
    }
  }
}

.c-card14__list1 {
  display: grid;
  margin: 14px 0 0;
  grid-template-columns: repeat(2, 1fr);

  @include mq('md', min) {
    margin: 19px 0 0;
  }

  &>li {
    display: grid;

    &>span {
      text-align: center;

      &:nth-of-type(1) {
        font-size: 16px;
        text-align: center;
        font-weight: 700;

        @include mq('md', min) {
          line-height: 1.5;
          font-size: 24px;
        }
      }

      &:nth-of-type(2) {
        font-size: 40px;
        line-height: 1;
        font-weight: 700;
        font-family: $font-family-en;
        display: block;
        margin: 5px 0 0;

        &>span {
          font-size: 28px;

          @include mq('md', min) {
            font-size: 24px;
          }
        }
      }
    }
  }

  &.-type1 {
    display: flex;
    justify-content: center;
    gap: 36px;
  }
}

.c-card14__text2 {
  font-size: 12px;
  margin: 12px 0 0;
  text-align: center;

  @include mq('md', min) {
    margin: 0;
  }
}
