@use "../../global"as *;

.c-button18 {
  position: relative;
  display: block;
  text-decoration: none;
  color: $text-icon---primary--black;
  border-radius: 10rem;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  padding: 9px 40px 9px 32px;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  @include mq('md', min) {
    border: 1px solid $text-icon---primary--black;
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;
    border: 1px solid $neutral--300;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    margin: auto;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button18:hover::after {
        animation: arrow1 0.35s ease-out;
      }
    }

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }
}
