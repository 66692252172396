@use "../../global"as *;

.c-card11 {
  padding: 24px 12px;
  border: 1px solid $neutral--300;
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);
  background: $text-icon---primary--white;

  @include mq('md', min) {
    padding: 32px;
    height: 100%;
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.c-card11__title1 {
  font-size: 24px;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  align-items: center;

  @include mq('md', min) {
    //width: 286px;
    font-size: 32px;
  }
}

.c-card11__text1 {
  margin: 16px 0 0;
  font-size: 16px;

  @include mq('md', min) {
    //width: 286px;
    margin: 24px 0 0;
    font-size: 24px;
  }
}