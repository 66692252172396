@use "../../global"as *;

// block1
.p-history-block1 {
  @include mq('md', min) {
  }
  &.-s1950 {
    background: $neutral--0 url('/assets/images/history_img1_1.jpg') 0 0 / 100% auto no-repeat;
    @include mq('md', min) {
      background: $neutral--0 url('/assets/images/history_img1_1_desktop.jpg') 0 0 / 100% auto no-repeat;
    }
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
      background: $neutral--1000 url('/assets/images/history_img1_1_darkmode.jpg') 0 0 / 100% auto no-repeat;
      @include mq('md', min) {
        background: $neutral--1000 url('/assets/images/history_img1_1_desktop_darkmode.jpg') 0 0 / 100% auto no-repeat;
      }
    }
  }
  &.-s1970 {
    background: $neutral--200 url('/assets/images/history_img1_2.jpg') 0 0 / 100% auto no-repeat;
    @include mq('md', min) {
      background: $neutral--200 url('/assets/images/history_img1_2_desktop.jpg') 0 0 / 100% auto no-repeat;
    }
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
      background: $neutral--900 url('/assets/images/history_img1_2_darkmode.jpg') 0 0 / 100% auto no-repeat;
      @include mq('md', min) {
        background: $neutral--900 url('/assets/images/history_img1_2_desktop_darkmode.jpg') 0 0 / 100% auto no-repeat;
      }
    }
  }
}
.p-history-block1__inner {
  padding: 40px 12px 80px;
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 120px 0 160px;
    margin: auto;
  }
  @at-root {
    .p-history-block1 + .p-history-block1 {
      .p-history-block1__inner {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: calc(41px + 12px);
          display: block;
          width: 4px;
          height: 40px;
          background: $text-icon---primary--black;
          transform: translateX(-50%);
          @include mq('md', min) {
            left: 156px;
            height: 80px;
          }
          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }
      }
    }
  }
}

.p-history-block1__head {}
.p-history-block1__head__title1 {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  @include mq('md', min) {
    font-size: 56px;
  }
  .-num {
    margin-right: 10px;
    font-size: 60px;
    line-height: 1.4;
    font-family: $font-family-en;
    @include mq('md', min) {
      font-size: 120px;
      line-height: 0.7;
    }
  }
  .-label {
    display: block;
    margin-left: 20px;
    padding: 8px 16px;
    border: 1px solid $text-icon---primary--black;
    font-size: 16px;
    line-height: 1.5;
    @include mq('md', min) {
      margin-left: 40px;
      font-size: 24px;
    }
    @media (prefers-color-scheme: dark) {
      border-color: $text-icon---primary--white;
    }
  }
}
.p-history-block1__head__text1 {
  margin: 20px 0 0;
  font-size: 24px;
  font-weight: 700;
  @include mq('md', min) {
    margin: 40px 0 0;
    font-size: 32px;
  }
}
.p-history-block1__head__text2 {
  margin: 20px 0 0;
  font-size: 16px;
  @include mq('md', min) {
    margin: 40px 0 0;
    font-size: $font-size-body;
  }
}

.p-history-block1__body {
  position: relative;
  margin: 40px 0 -80px;
  padding: 0 0 40px;
  @include mq('md', min) {
    margin: 64px 0 -160px;
    padding: 0 0 80px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 41px;
    display: block;
    width: 4px;
    height: 100%;
    background: $text-icon---primary--black;
    transform: translateX(-50%);
    @include mq('md', min) {
      left: 156px;
    }
    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }
}

.p-history-block1__card {
  display: flex;
  + .p-history-block1__card {
    margin-top: 40px;
  }
}
.p-history-block1__card__head {
  position: relative;
  flex: 100px 0 0;
  width: 100px;
  @include mq('md', min) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 312px 0 0;
    width: 312px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 55px;
    right: 0;
    display: block;
    width: 60px;
    height: 1px;
    background: $text-icon---primary--black;
    @include mq('md', min) {
      top: 50%;
      width: 50%;
    }
    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }
}
.p-history-block1__card__body {
  width: 100%;
  background: $neutral--0;
  box-shadow: 0 2px 8px 0 rgba($neutral--1000, 0.12);
  @include mq('md', min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (prefers-color-scheme: dark) {
    background: $neutral--800;
  }
}

.p-history-block1__card__year {
  position: relative;
  top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  color: $text-icon---primary--white;
  background: $text-icon---primary--black;
  font-size: 12px;
  line-height: 1.16;
  font-weight: 700;
  @include mq('md', min) {
    top: 0;
    width: 120px;
    height: 120px;
    font-size: 16px;
  }
  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
    background: $text-icon---primary--white;
  }
  &.-point {
    top: 42px;
    left: 29px;
    width: 24px;
    height: 24px;
    border: 4px solid $text-icon---primary--black;
    background: $text-icon---primary--white;
    @include mq('md', min) {
      top: 0;
      left: 0;
    }
    @media (prefers-color-scheme: dark) {
      border-color: $text-icon---primary--white;
      background: $text-icon---primary--black;
    }
  }
  .-num {
    font-size: 24px;
    font-family: $font-family-en;
    @include mq('md', min) {
      font-size: 36px;
    }
  }
}

.p-history-block1__card__text {
  flex: auto 1 1;
  padding: 36px 24px;
}
.p-history-block1__card__text__title {
  font-size: 20px;
  font-weight: 700;
  @include mq('md', min) {
    font-size: 32px;
  }
}
.p-history-block1__card__text__text {
  font-size: 16px;
  line-height: 1.8;
  margin: 16px 0 0;
  @include mq('md', min) {
    font-size: $font-size-body;
  }
}
.p-history-block1__card__image {
  flex: auto 0 0;
  img {
    width: 100%;
    height: auto;
  }
}