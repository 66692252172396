@use "../../global"as *;

.p-heading3 {
  margin: 40px 0 0;
  padding: 0 12px;
  overflow: hidden;

  @include mq('md', min) {
    position: relative;
    padding: 0 100px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.p-heading3__inner1 {
  @include mq('md', min) {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 0 100px;
    box-sizing: content-box;
    margin: auto;
    z-index: 10;
  }
}

.p-heading3__image1 {
  position: relative;
  height: 600px;
  margin: 0 -12px;
  overflow: hidden;

  @include mq('md', min) {
    height: 835px;
    margin: 0 -100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-heading3__image1__labelJp1 {
  font-size: 40px;
  line-height: 1.5;
  font-weight: 900;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0 12px;
  z-index: 1;
  height: 100%;
  display: grid;
  align-items: center;
  color: $text-icon---primary--white;

  @include mq('md', min) {
    font-size: 80px;
    padding: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 0 100px;
    box-sizing: content-box;
    right: 0;
    margin: auto;
  }

  &.-small {
    @include mq('md', min) {
      font-size: 64px;
      line-height: 1.5;
    }
  }
}

.p-heading3__image1__labelEn1 {
  font-size: 120px;
  line-height: 1;
  font-family: $font-family-en;
  font-weight: 600;
  white-space: nowrap;
  position: absolute;
  left: 24px;
  bottom: -24px;
  color: $text-icon---primary--white;
  text-transform: uppercase;

  @include mq('md', min) {
    font-size: 240px;
    line-height: 0.4;
    left: initial;
    right: -80px;
    bottom: 20px;
  }

  &.-sustainability {
    @include mq('md', min) {
      right: -496px;
    }
  }

  &.-management {
    @include mq('md', min) {
      right: 87px;
    }
  }

}

.p-heading3__stock1 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 0 0 0 auto;
    width: 368px;
  }
}

.p-heading3__stock1__iframe {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border: none;
  @include mq('md', min) {
    margin: 0;
  }
}

// large
.p-heading3.is-large {
  .p-heading3__image1 {
    height: 667px;
    @include mq('md', min) {
      height: 1080px;
    }
  }
  @at-root {
    .l-document:has(.include_header_default.is-absolute) {
      .p-heading3.is-large {
        .p-heading3__image1__labelJp1 {
          padding-top: 72px;
          @include mq('md', min) {
            padding-top: 86px;
          }
        }
      }
      .include_localNav_career1 + .c-breadcrumbs1.is-absolute + main {
        .p-heading3.is-large {
          .p-heading3__image1__labelJp1 {
            padding-top: calc(72px + 40px);
            @include mq('md', min) {
              height: calc(100% - 86px - 68px - 40px);
              padding-top: calc(86px + 68px + 40px);
            }
          }
        }
      }
    }
  }
}