@use "../../global"as *;

.c-card18 {}

.c-card18__image1 {
  width: 240px;
  margin: auto;

  @include mq('md', min) {
    width: 324px;
  }

  @at-root {
    .c-card18:has(.c-card18__text4) & {
      width: 284px;
    }
  }
}

.c-card18__text1 {
  margin: 10px 0 0;
  font-size: 16px;
  text-align: center;

  @include mq('md', min) {
    margin: 16px 0 0;
    font-size: $font-size-body;
  }
}

.c-card18__text2 {
  margin: 10px 0 0;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  font-weight: 700;

  @include mq('md', min) {
    margin: 16px 0 0;
  }

  @at-root {
    .c-card18__image1+& {
      margin: 16px 0 0;
    }
  }
}

.c-card18__text3 {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;

  @include mq('md', min) {
    margin: 16px 0 0;
    font-size: $font-size-body;
  }
}

.c-card18__text4 {
  font-size: 16px;
  font-weight: 700;
  margin: 16px 0 0;
  @include mq('md', min) {
    font-size: $font-size-body;
  }
}
