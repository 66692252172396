@use "../../global"as *;

.c-textLink4 {
  color: $text-icon---primary--black;
  font-size: 16px;
  line-height: 1.8;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px 0 0;
  transition: all 0.7s $easeOutQuint;

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  &:hover {
    text-decoration: none;
    background: $neutral--200;

    @at-root {
      .c-textLink4.-hoverWhite:hover {
        background: $text-icon---primary--white;
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-textLink4:hover::before {
        animation: arrow1 0.35s ease-out;
      }

      .c-textLink4.-disabled::before {
        background: $text-icon---disabled--black;
      }
    }
  }

  &.-disabled {
    color: $text-icon---disabled--black;
    text-decoration: none;
    pointer-events: none;
  }
}
