@use "../../global"as *;

.c-card8 {
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
    border: 1px solid $neutral--300;
  }

  &.-white {
    background: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
      border: 1px solid $neutral--300;
    }
  }
}

.c-card8__head1 {
  padding: 12px 12px 24px;
  border-bottom: 1px solid $neutral--300;

  @include mq('md', min) {
    padding: 40px;
    display: grid;
    gap: 56px;
    grid-template-columns: 420px 1fr;
    align-items: center;
  }
}

.c-card8__head2 {
  padding: 24px 12px;
  border-bottom: 1px solid $neutral--300;

  @include mq('md', min) {
    padding: 40px 64px;
  }
}

.c-card8__head2__main1 {
  padding: 0 64px 0 0;
  position: relative;

  @include mq('md', min) {
    margin: 0;
    padding-right: 88px;
  }

  &::after {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @include mq('md', min) {
      right: 24px;
    }

    @at-root {
      .c-card8__toggleButton1:hover .c-card8__head2__main1::after {
        animation: arrow2 0.35s ease-out;
      }

      .c-card8__toggleButton1.-open .c-card8__head2__main1::after {
        transform: rotate(-90deg);
      }

      .c-card8__toggleButton1.-open:hover .c-card8__head2__main1::after {
        animation: arrow3 0.35s ease-out;
      }
    }
  }
}

.c-card8__toggleButton1 {
  cursor: pointer;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }
}

.c-card8__head2__text1 {
  margin: 8px 0 0;
  font-size: 16px;
  line-height: 1.5;

  @include mq('md', min) {
    margin: 16px 0 0;
    font-size: $font-size-body;
  }
}

.c-card8__head1 {
  padding: 12px 12px 24px;
  border-bottom: 1px solid $neutral--300;

  @include mq('md', min) {
    padding: 40px;
    display: grid;
    gap: 56px;
    grid-template-columns: 420px 1fr;
    align-items: center;
  }
}

.c-card8__head1__image1 {
  img {
    width: 100%;
    height: auto;
  }
}

.c-card8__head1__main1 {
  margin: 16px 0 0;
  padding: 0 64px 0 0;
  position: relative;

  @include mq('md', min) {
    margin: 0;
    padding-right: 88px;
  }

  &::after {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @include mq('md', min) {
      right: 24px;
    }

    @at-root {
      .c-card8__toggleButton1:hover .c-card8__head1__main1::after {
        animation: arrow2 0.35s ease-out;
      }

      .c-card8__toggleButton1.-open:hover .c-card8__head1__main1::after {
        animation: arrow3 0.35s ease-out;
      }

      .c-card8__toggleButton1.-open .c-card8__head1__main1::after {
        transform: rotate(-90deg);
      }
    }
  }
}

.c-card8__head1__text1 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 24px;
  }
}

.c-card8__head1__text2 {
  font-size: 24px;
  line-height: 1.5;
  margin: 8px 0 0;

  @include mq('md', min) {
    font-size: 40px;
  }
}

.c-card8__head1__text3 {
  font-size: 16px;
  line-height: 1.5;
  margin: 8px 0 0;

  @include mq('md', min) {
    font-size: 24px;
  }
}

.c-card8__toggleArea1 {
  height: 0;
  overflow: hidden;
}

.c-card8__main1 {
  padding: 24px 12px;

  @include mq('md', min) {
    padding: 40px 64px 80px;
  }
}

.c-card8__main2 {
  padding: 24px 12px;

  @include mq('md', min) {
    padding: 40px 64px;
  }
}

.c-card8__main1__list1 {
  margin: 24px 0 0;
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    margin: 32px 0 0;
  }
  //.c-card8__main1__title1 + & {
  //  margin-top: 0;
  //}
}

.c-card8__main1__list2 {
  margin: 24px 0 0;
  display: grid;
  gap: 16px;
  @include mq('md', min) {
    margin: 32px 0 0;
  }
}

.c-card8__main1__list1__item1 {
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    display: flex;
    gap: 16px;
    font-size: $font-size-body;
  }

  &>span {
    &:first-child {
      white-space: nowrap;
    }
  }
}

.c-card8__main1__title1 {
  margin: 64px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }

  &:first-child {
    margin: 0;
  }
}

.c-card8__main1__title2 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }

  &:first-child {
    margin: 0;
  }
}

.c-card8__main1__title3 {
  margin: 80px 0 0;

  @include mq('md', min) {
    margin: 160px 0 0;
  }

  &:first-child {
    margin: 0;
  }
}

.c-card8__main1__title4 {
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 32px 0 0;
  }

  &:first-child {
    margin: 0;
  }
}

.c-card8__main1__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 32px 0 0;
    font-size: $font-size-body;
  }

  &:first-child {
    margin: 0;
  }

  a {
    color: inherit;
  }

  &.-bold {
    font-weight: 700;
    + .c-card8__main1__text1 {
      margin-top: 8px;
    }
  }

  &.-indent {
    @include mq('md', min) {
      margin-left: calc(24px + 16px);
    }
  }
}

.c-card8__main1__list3 {
  margin: 16px 0 0;

  @include mq('md', min) {
    margin: 24px 0 0;
  }

  &:first-child {
    margin: 0;
  }

  .c-list12 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

.c-card8__list1 {
  display: grid;
  gap: 16px;

  @include mq('md', min) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

.c-card8__main1__button1 {
  display: block;
  margin-top: 24px;
  @include mq('md', min) {
    margin-top: 40px;
  }
  .c-button5,
  .c-button9,
  .c-button15 {
    display: block;
    @include mq('md', min) {
      display: inline-block;
      width: auto;
      min-width: 169px;
    }
  }
  .c-button12 {
    width: 100%;
    display: flex;
    @include mq('md', min) {
      display: inline-flex;
      width: auto;
      min-width: 360px;
    }
  }
}

.c-card8__main1__image1 {
  margin-top: 24px;
  text-align: center;
  @include mq('md', min) {
    margin-top: 32px;
  }
  img {
    margin: 0 auto;
  }
}

.c-card8__main1__image2 {
  margin-top: 32px;
  text-align: center;
  @include mq('md', min) {
    margin-top: 64px;
  }
  img {
    width: 100%;
    height: auto;
  }
  &.-type1 {
    img {
      @include mq('md', min) {
        display: inline-block;
        width: calc(100% - (147px * 2));
        max-width: 100%;
      }
    }
  }
  &.-type2 {
    img {
      @include mq('md', min) {
        display: inline-block;
        width: 732px;
        max-width: 100%;
      }
    }
  }
}

.c-card8__main1__image3 {
  margin: 24px 0 0;
  @include mq('md', min) {
    margin: 32px 0 0;
  }

  img {
    width: 100%;
    height: auto;
    @include mq('md', min) {
      width: auto;
    }
  }
}

.c-card8__main1__image4 {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-top: 32px;
  text-align: center;
  @include mq('md', min) {
    margin-top: 64px;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


.c-card8__main1__notes1 {
  margin-top: 24px;
  @include mq('md', min) {
    margin-top: 32px;
    font-size: $font-size-note;
  }
}

.c-card8__main1__table1 {
  margin: 24px 0 0 0;

  @include mq('md', min) {
    margin: 40px 0 0;
  }

  &:has(.c-table5.-scroll) {
    margin-right: -12px;

    @include mq('md', min) {
      margin-right: 0;
    }
  }
}

.c-card8__main1__row1 {
  margin: 24px 0 0;
  background: $neutral--100;

  @include mq('md', min) {
    display: flex;
    gap: 40px;
    margin: 32px 0 0;
  }

  + .c-card8__main1__row1 {
    margin-top: 0;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $neutral--900;
  }

  &.-white {
    background: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }
  }
}

.c-card8__main1__row1__unit {
  padding: 16px;

  @include mq('md', min) {
    width: calc(50% - 20px);
    padding: 64px;
  }

  >*:first-child {
    margin-top: 0;
  }
}

.c-card8__main1__row2 {
  @include mq('md', min) {
    display: flex;
    margin: 32px -20px 0;
  }
}
.c-card8__main1__row2__unit {
  margin: 40px 0 0;
  @include mq('md', min) {
    width: 33.33%;
    margin: 0;
    padding: 0 20px;
  }
  *:first-child {
    @include mq('md', min) {
      margin-top: 0;
    }
  }
}

.c-card8__main1__row3 {
  @include mq("md", min) {
    display: flex;
    gap: 40px;
  }

  &.-reverse {
    @include mq("md", min) {
      flex-direction: row-reverse;
    }
  }

  &.-type1 {
    @include mq("md", min) {
      justify-content: space-between;
    }
  }
}

.c-card8__main1__row3__unit {
  @include mq("md", min) {
    width: calc(50% - 20px);
  }

  .c-card8__main1__title2 {
    margin: 40px 0 0;

    @include mq('md', min) {
      margin: 64px 0 0;
    }
  }

  @at-root {
    .c-card8__main1__row3.-type1 & {
      &:nth-child(odd) {
        @include mq("md", min) {
          width: 962px;
        }
      }

      &:nth-child(even) {
        @include mq("md", min) {
          width: 461px;
        }
      }
    }
    .c-card8__main1__row3.-type1.-reverse & {
      &:nth-child(odd) {
        @include mq("md", min) {
          width: 461px;
        }
      }

      &:nth-child(even) {
        @include mq("md", min) {
          width: 962px;
        }
      }
    }
  }
}