@use "../../global"as *;

.p-block14 {
  padding: 40px 12px 80px;
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    padding: 120px 100px 160px;
  }

  &.-white {
    margin: 40px 0 0;
    padding: 0 12px;
    background: $text-icon---primary--white;

    @include mq('md', min) {
      margin: 120px 0 0;
      padding: 0 100px;
    }

    @media (prefers-color-scheme: dark) {
      background: none;
    }
  }

  @at-root {
    .p-block11.-gray + .p-block14:not(.-white),
    .p-block14:not(.-white) + .p-block14:not(.-white) {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.p-block14__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 48.5%;
    gap: 40px;
  }

  @at-root {
    .p-block14.-type1 & {
      @include mq('md', min) {
        grid-template-columns: 1fr calc(100% * (504/1600));
      }
    }
  }
}

.p-block14__image1 {
  @include mq('md', min) {
    grid-column-start: 2;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-block14__main1 {
  @include mq('md', min) {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

.p-block14__title1 {
  margin: 28px 0 0;

  @include mq('md', min) {
    margin: 0;
  }
}

.p-block14__title2 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
  }

  @at-root {
    .p-block14__text1 + & {
      margin: 32px 0 0;
      @include mq('md', min) {
        margin: 64px 0 0;
      }
    }
  }

}

.p-block14__text1 {
  margin: 36px 0 0;
  display: grid;
  gap: 40px;

  @include mq('md', min) {
    margin: 48px 0 0;
  }

  &>p {
    font-size: 20px;
    line-height: 1.8;
  }

  @at-root {
    .p-block14__title2 + & {
      margin: 24px 0 0;
      @include mq('md', min) {
        margin: 32px 0 0;
      }
    }
  }
}

.p-block14__name1 {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  width: 240px;
  height: 62px;
  background: url('../images/block14_text1_1.png') 0 0 / cover no-repeat;
  margin: 24px 0 0;

  @media (prefers-color-scheme: dark) {
    background: url('../images/block14_text1_2.png') 0 0 / cover no-repeat;
  }
}

.p-block14__button1 {
  display: block;
  margin-top: 24px;


  @include mq("md", min) {
    margin: 40px auto 0;
    min-width: 1000px;
    max-width: 1600px;
  }
  .c-button5,
  .c-button9,
  .c-button15 {
    display: block;
    @include mq("md", min) {
      display: inline-block;
      width: auto;
      min-width: 169px;
    }
  }
  .c-button10,
  .c-button12 {
    width: 100%;
    display: flex;
    @include mq("md", min) {
      display: inline-flex;
      width: auto;
      min-width: 360px;
    }
  }
}
