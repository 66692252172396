@use "../../global"as *;

.c-button9 {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  color: $text-icon---primary--white;
  text-decoration: none;
  text-align: center;
  border: 1px solid $text-icon---primary--white;
  border-radius: 10rem;
  padding: 9px 40px 9px 32px;
  line-height: 1.8;
  transition: all 0.7s $easeOutQuint;
  background: $text-icon---primary--black;

  &:hover {
    background: $neutral--800;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    margin: auto;
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    background: $text-icon---primary--white;
    mask-image: url("/assets/images/icon_arrow1_1.svg");
    mask-size: cover;
    transform: rotate(90deg);
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button9[aria-expanded="true"]::after {
        transform: rotate(-90deg);
      }

      .c-button9[aria-expanded="true"]:hover::after {
        animation: arrow3 0.35s ease-out;
      }

      .c-button9:hover::after {
        animation: arrow2 0.35s ease-out;
      }
    }
  }
}
