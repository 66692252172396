@use "../../global"as *;

.c-title4 {}

.c-title4__title1 {
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    display: flex;
    gap: 40px;
  }
}

.c-title4__title1__number1 {
  font-size: 48px;
  line-height: 1;
  font-family: $font-family-en;

  @include mq('md', min) {
    line-height: 1.16;
    font-size: 72px;
  }
}

.c-title4__title1__mainLabel1 {
  font-size: 32px;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 56px;
  }
}
