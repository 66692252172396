@use "../../global"as *;

.p-block12 {
  margin: 80px 0 0;
  padding: 40px 12px 80px;
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    margin: 160px 0 0;
    padding: 120px 100px 160px;
  }

  &.-white {
    background: none;
    padding: 0 12px;

    @include mq('md', min) {
      margin: 120px 0 0;
      padding: 0 100px;
    }
  }
}

.p-block12__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }

  +.p-block12__inner1 {
    margin: 40px auto 0;

    @include mq('md', min) {
      margin: 100px auto 0;
    }
  }
}

.p-block12__title1 {
  @include mq('md', min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}

.p-block12__title1__button1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 0;
  }
}

.p-block12__list1 {
  margin: 40px 0 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mq('md', min) {
    margin: 48px 0 0;
    gap: 40px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.p-block12__list1__item1 {}

.p-block12__button1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
    text-align: right;
  }

  &>button {
    @include mq('md', min) {
      display: inline-block;
      width: auto;
    }
  }
}
