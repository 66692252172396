@use "../../global"as *;

.c-formError1 {
  margin: 12px 0 0;
  font-size: 16px;
  color: $danger--800;

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  &.error-messeage {
    display: none;
  }
}

