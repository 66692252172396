@use "../../global"as *;

.p-search1 {
  padding: 0 20px;

  @include mq('md', min) {
    padding: 0 100px;
  }
}

.p-search1__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-search1 {
  // programmablesearchengine.google - OverWrite
  form.gsc-search-box {
    margin: 0;
    font-size: 16px;
  }
  table.gsc-search-box {
    display: block;
    margin: 0;
    td.gsc-input {
      display: block;
      padding-right: 0;
    }
    .gsib_a {
      display: block;
      padding: 0;
    }
    .gsib_b {
      display: none;
    }
    tbody,
    tr {
      display: block;
    }
  }
  .gsc-input-box {
    position: relative;
    border: none;
    background: transparent;
    @include mq('md', min) {
      padding: 0;
      border-radius: 10rem;
      cursor: pointer;
    }
    &::before {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      left: 9px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: $text-icon---primary--black;
      mask-image: url('/assets/images/icon_search1_1.svg');

      @include mq('md', min) {
        width: 42px;
        height: 42px;
        left: 0;
        background: url('/assets/images/icon_search1_1.svg') center center / cover no-repeat;
        background-size: 24px auto;
        mask-image: none;
      }

      @at-root {
        .c-formText1.-active::before {
          cursor: auto;
        }
      }
    }
    input.gsc-input {
      content: "フリーワードで探す";
      width: 100%;
      height: auto!important;
      border-radius: 10rem;
      font-size: 16px;
      line-height: 1.5;
      font-family: $font-family-jp;
      margin: 0!important;
      padding: 8px 0 8px 40px!important;
      transition: all 0.7s $easeOutQuint;
      border: 1px solid $neutral--300!important;
      background: $neutral--0!important;
      color: $text-icon---primary--black!important;

      &::placeholder {
        font-size: 16px;
        color: $text-icon---primary--white;

        @include mq('md', min) {
          color: $text-icon---secondary--black;
        }
      }

      &:where(:focus-visible) {
        outline: 2px solid #91B5FD!important;
        outline-offset: 2px!important;
      }
    }
  }
  table.gsc-input {
    display: block;
  }
  .gsc-control-cse {
    padding-right: 0;
    padding-left: 0;
  }
  .gsc-search-button {
    display: none;
  }
}