@use "../../global"as *;

.c-button16 {
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgba($text-icon---primary--black, 0.64);
  font-size: 14px;
  line-height: 1.14;
  font-weight: 600;
  color: $text-icon---primary--white;
  padding: 12px 23px 12px 16px;
  border-radius: 10rem 0 0 10rem;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
    background: rgba($text-icon---primary--white, 0.8);
  }

  &::before {
    display: block;
    content: '';
    width: 17px;
    height: 14px;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_arrow3_1.svg');
    mask-size: cover;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }
  }
}
