@use "../../global"as *;

.c-table6 {
  position: relative;
  overflow: hidden;
}

.c-table6__inner1 {
  overflow: visible;

  @at-root {
    .c-table6.-scroll & {
      overflow-x: scroll;

      .c-table6__thead1__th1 {
        @include mq('md', min) {
          padding: 0;
        }
      }
      .c-table6__thead1__th1__inner1 {
        @include mq('md', min) {
          width: calc(150vw - 200px - 55px - 288px - 30px);
          max-width: 1227px;
          padding: 12px 24px;
          text-align: center;
        }
      }

    }
  }

  table {
    width: 100%;
    border-spacing: 10px;

    :where(th, td):not(:empty) {
      box-shadow: 0 2px 8px rgba($text-icon---primary--black, 12);

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--black;
      }
    }

    thead {

      tr {
        &:first-child {
          th:not(:empty) {
            background: $text-icon---primary--black;
            color: $text-icon---primary--white;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.5;
            padding: 12px 24px;
            text-align: left;
          }

          th {
            &:nth-of-type(1) {
              max-width: 56px;
              min-width: 56px;
            }

            &:nth-of-type(2) {
              max-width: 180px;
              min-width: 180px;

              @include mq('md', min) {
                max-width: 288px;
                min-width: 288px;
              }
            }

            &:nth-of-type(3) {
              width: 100%;
            }
          }
        }

        &:last-child {
          td:not(:empty) {
            font-size: 32px;
            min-width: 116px;
            max-width: 116px;
            line-height: 1.5;
            font-weight: 600;
            background: $neutral--200;
            padding: 10px;

            @include mq('md', min) {
              min-width: 200px;
              max-width: 200px;
              padding: 24px;
            }

            span {
              display: block;
              margin-inline: auto;
              max-inline-size: max-content;
            }
          }
        }
      }
    }

    tbody {
      th[rowspan] {
        position: relative;
        background: $text-icon---primary--black;
        color: $text-icon---primary--white;
        font-size: 24px;
        line-height: 1.5;
        writing-mode: vertical-rl;
        text-orientation: sideways;
      }

      th:not([rowspan]) {
        font-size: 24px;
        line-height: 1.5;
        text-align: left;
        padding: 0 24px;
        min-height: 80px;
        height: 80px;
        background: $neutral--300;

        @at-root {
          .c-table6__inner1 table tbody tr {
            &:nth-of-type(even) th {
              background: $neutral--200;
            }
          }
        }
      }

      td {
        background: $neutral--100;

        @at-root {
          .c-table6__inner1 table tbody tr {
            &:nth-of-type(even) td {
              background: $neutral--0;
            }
          }
        }

        img {
          margin: auto;
        }
      }
    }
  }

}

.c-table6__button1 {
  position: absolute;
  top: 34px;
  right: 0;
}
