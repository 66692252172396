@use "../../global"as *;

.c-formRadio2 {
  position: relative;
}

.c-formRadio2__input1 {
  position: absolute;
  opacity: 0;
}

.c-formRadio2__label1 {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 600;
  display: block;
  display: flex;
  align-items: center;
  gap: 18px;
  border: 1px solid $neutral--300;
  border-radius: 10rem;
  padding: 9px 24px;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  @at-root {
    .c-formRadio2:has(.c-formRadio2__input1:checked) & {
      background: $text-icon---primary--black;
      color: $text-icon---primary--white;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
        color: $text-icon---primary--black;
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 10rem;
    outline: 2px solid rgba($text-icon---secondary--black, 0.8);

    @media (prefers-color-scheme: dark) {
      outline-color: $text-icon---primary--white;
    }

    @at-root {
      .c-formRadio2:has(.c-formRadio2__input1:checked) .c-formRadio2__label1::before {
        outline-color: $text-icon---primary--white;
        background: $text-icon---primary--white;
        border: 3px solid $text-icon---primary--black;

        @media (prefers-color-scheme: dark) {
          outline-color: $text-icon---primary--black;
          background: $text-icon---primary--black;
          border: 3px solid $text-icon---primary--white;
        }
      }
    }
  }

}
