@use "../../global"as *;

.c-list2 {
  display: flex;
  gap: 16px;

  @include mq('md', min) {
    flex-wrap: wrap;
    font-size: $font-size-body;
  }
}

.c-list2__item1 {
  white-space: nowrap;

  &:last-child {
    padding-right: 20px;

    @include mq('md', min) {
      padding-right: 0;
    }
  }
}
