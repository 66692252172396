@use "../../global"as *;

.c-formGroup1 {
  @include mq('md', min) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.c-formGroup1__input1 {
  @include mq('md', min) {
    width: 100%;
  }
}

.c-formGroup1__button1 {
  margin: 16px 0 0;

  @include mq('md', min) {
    margin: 0;
  }
}
