@use "../../global"as *;

.p-block21 {
  padding: 60px 12px 80px;
  background: $neutral--100;

  @include mq('md', min) {
    padding: 120px 100px 160px;
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-block21__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block21 {}

.p-block21__inner1 {}

.p-block21__main1 {
  display: grid;
  gap: 20px;

  @include mq('md', min) {
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
}

.p-block21__unit1 {
  &:nth-of-type(1) {
    @include mq('md', min) {
      grid-column: 1/3;
      grid-row-start: 1;
    }

    @include mq('3xl', min) {
      grid-row: 1/3;
    }
  }

  &:nth-of-type(2) {
    @include mq('md', min) {
      grid-column-start: 3;
      grid-row-start: 1;
      grid-column: 3/5;
    }
  }

  &:nth-of-type(3) {
    @include mq('md', min) {
      grid-column: 1/3;
    }

    @include mq('3xl', min) {
      grid-column-start: 3;
    }
  }

  &:nth-of-type(4) {
    @include mq('md', min) {
      grid-column: 3/5;
      grid-column-start: 3;
    }

    @include mq('3xl', min) {
      grid-column-start: 4;
    }
  }

  &:nth-of-type(5) {
    @include mq('md', min) {
      grid-column: 1/5;
      grid-column-start: 1;
    }

    @include mq('3xl', min) {
      grid-column-start: 1;
      grid-column: 1/2;
    }
  }

  &:nth-of-type(6) {
    @include mq('md', min) {
      grid-column: 1/5;
    }

    @include mq('3xl', min) {
      grid-column: 2/5;
    }
  }
}

.p-block21__title1 {
  margin-top: 20px;

  @include mq('md', min) {
    margin-top: 40px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.p-block21__main2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  @include mq('lg', min) {
    flex-direction: row;
    gap: 40px;
  }

  &.-col3 {
    @include mq('md', min) {
      flex-direction: row;
      gap: 40px;
    }
  }
}

.p-block21__unit2 {
  width: 100%;

  @at-root {
    .p-block21__main2.-col3 & {
      @include mq('md', min) {
        width: 33.33%;
      }
    }
  }
}

.p-block21__notes1 {
  margin-top: 20px;
}