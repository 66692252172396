@use "../../global"as *;

.c-card10 {
  background: $text-icon---primary--white;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.c-card10__title1 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::after {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    transform: rotate(90deg);
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @at-root {
      .c-card10__title1:hover::after {
        animation: arrow2 0.35s ease-out;
      }

      .c-card10__title1.-open::after {
        transform: rotate(-90deg);
      }

      .c-card10__title1.-open:hover::after {
        animation: arrow3 0.35s ease-out;
      }
    }
  }
}

.c-card10__body1 {
  border-top: 1px solid $neutral--300;
  height: 0;
  overflow: hidden;
}

.c-card10__body1__inner1 {
  padding: 24px 16px;

  &>p {
    font-size: 16px;
    line-height: 1.8;
    @include mq('md', min) {
      font-size: $font-size-body;
    }
  }
}
