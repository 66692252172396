@use "../../global"as *;

.p-block10 {
  margin: 80px 0 0;
  padding: 40px 12px 80px;
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    margin: 160px 0 0;
    padding: 120px 100px 160px;
  }

  &.-white {
    margin: 40px 0 0;
    padding: 0 12px;
    background: $text-icon---primary--white;

    @include mq('md', min) {
      margin: 120px 0 0;
      padding: 0 100px;
    }

    @media (prefers-color-scheme: dark) {
      background: none;
    }
  }
}

.p-block10__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block10__title1 {}

.p-block10__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
    font-size: $font-size-body;
  }
}

.p-block10__image1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }
}
