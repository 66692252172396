@use "../../global"as *;

.c-title5 {}

.c-title5__mainLabel1 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 64px;
  }
}
