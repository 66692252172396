@use "../global/" as *;

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: auto !important;
  // display scale 150%
  @include mq("md", min) {
    overflow-x: hidden;
    transform: scale(0.6667);
    transform-origin: left top;
    height: calc(100% / 0.6667);
    width: calc(100% / 0.6667);
  }

  &.-fullscreen {
    @include mq("md", min) {
      overflow: hidden;
    }
  }
}

body {
  font-size: 16px;
  font-family: $font-family-jp;
  line-height: 1.8;
  background: $text-icon---primary--white;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

* {
  box-sizing: border-box;
}

a {
  &:focus-visible {
    outline: #005fcc solid 2px;
    border-radius: 3px;
  }
}

b {
  position: relative;
  font-weight: normal;

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 8px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $primary--200;
    z-index: -1;

    @media (prefers-color-scheme: dark) {
      background: $primary--800;
    }
  }
}
