@use "../../global"as *;

.c-table4 {
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);

  table {
    width: 100%;
    border-collapse: collapse;

    @media (prefers-color-scheme: dark) {
      border: 1px solid $neutral--300;
    }

    @include mq('md', min) {
      table-layout: fixed;
    }

    th,
    td {
      &.-nowrap {
        white-space: nowrap;
      }
      &.-spNowrap {
        white-space: nowrap;
        @include mq('md', min) {
          white-space: normal;
        }
      }
    }

    thead {
      th {
        font-size: 18px;
        line-height: 1.5;
        padding: 8px 12px;
        background: $neutral--300;

        @media (prefers-color-scheme: dark) {
          background: $neutral--700;
        }

        @include mq('md', min) {
          font-size: 24px;
          padding: 16px;
        }

        br {
          @include mq('md', min) {
            display: none;
          }
        }
      }
    }

    tbody {

      tr {
        border-top: 1px solid $neutral--300;

        &:nth-of-type(odd) {
          background: $text-icon---primary--white;

          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--black;
          }
        }
        &:nth-of-type(even) {
          background: $neutral--100;

          @media (prefers-color-scheme: dark) {
            background: $neutral--900;
          }
        }
      }

      th,
      td {
        padding: 8px 12px;
        font-size: 16px;
        line-height: 1.8;
        text-align: center;

        @include mq('md', min) {
          padding: 16px 24px;
          font-size: $font-size-body;
        }

        br {
          @include mq('md', min) {
            display: none;
          }
        }
      }

      th {
        white-space: nowrap;
        background: $neutral--200;
        width: 123px;

        @include mq('md', min) {
          width: 400px;
        }

        @media (prefers-color-scheme: dark) {
          background: $neutral--800;
        }
      }
    }
  }
}
