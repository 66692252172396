@use "../../global"as *;

.c-title10 {
  position: relative;
  padding: 8px 24px;
  background: $primary--900;
}

.c-title10__title1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text-icon---primary--white;

  @include mq('md', min) {
    justify-content: center;
  }
}

.c-title10__title1__mainLabel1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;

  @include mq('md', min) {
    font-size: 40px;
  }
}

.c-title10__title1__subLabel1 {
  font-size: 16px;
  font-weight: 400;

  @include mq('md', min) {
    position: absolute;
    right: 40px;
  }
}