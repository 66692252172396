@use "../../global"as *;

.p-block2 {
  background: $neutral--100;
  margin: 40px 0 0;
  padding: 20px 12px;

  @include mq('md', min) {
    padding: 80px 100px;
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-block2__inner1 {
  display: grid;
  gap: 24px;

  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block2__unit1 {
  position: relative;
}

.p-block2__unit1__inner1 {
  @include mq('md', min) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.p-block2__unit1__title1 {
  font-size: 24px;
  line-height: 1.5;

  @include mq('md', min) {
    position: absolute;
    min-width: 120px;
    max-width: 120px;
  }
}

.p-block2__unit1__list1 {
  margin: 16px 0 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  display: none;

  @include mq('md', min) {
    margin: 0 0 0 calc(120px + 16px);
  }

  &.-active {
    display: flex;
  }

  &:last-child {
    margin-top: 8px;
  }
}

.p-block2__unit1__list1__item1 {}

.p-block2__unit1__button1 {
  position: absolute;
  top: 5px;
  right: 0;

  @include mq('md', min) {
    position: static;
  }
}
