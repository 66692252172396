@use "../../global"as *;

.p-heading2 {
  padding: 0 12px;

  @include mq('md', min) {
    padding: 0 100px;
  }

  + * {
    margin-top: 40px;
  }

  @at-root {
    .l-document:has(.p-localNav1__sticky1) .p-heading2 {
      @include mq('md', max) {
        padding-top: 57px;
      }
    }
  }
}

.p-heading2__inner1 {

  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-heading2__title1 {
  font-size: 40px;
  line-height: 1.25;
  color: $text-icon---primary--black;
  font-weight: 900;
  z-index: 1;
  padding: 40px 0;

  @include mq('md', min) {
    font-size: 64px;
    line-height: 1.5;
    padding: 72px 0;
  }

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }
}

.p-heading2__text1 {
  font-size: 16px;

  @include mq("md", min) {
    font-size: $font-size-body;
  }
}
