@use "../../global"as *;

.p-heading1 {
  margin: 40px 0 0;
  padding: 0 20px;

  @include mq('md', min) {
    padding: 0 100px;
    margin: 72px 0 0;
  }
}

.p-heading1__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-heading1__title1 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    font-size: 64px;
    line-height: 1.5;
  }
}

.p-heading1__subInner1 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 72px 0 0;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.p-heading1__date1 {
  font-size: 16px;
  color: $text-icon---primary--black;
  white-space: nowrap;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }
}

.p-heading1__list1 {
  overflow-x: scroll;
  padding: 0 0 6px;
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 0;
    overflow: visible;
  }

  &::-webkit-scrollbar {
    height: 5px;
    background: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $neutral--300;
    border-radius: 10rem;

    @media (prefers-color-scheme: dark) {
      background: $neutral--200;
    }
  }
}

.p-heading1__list2 {
  margin: 18px 0 0;
  position: relative;

  @include mq('md', min) {
    margin: 0;
    padding: 0 0 0 24px;
  }

  &::before {
    @include mq('md', min) {
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $neutral--300;
    }
  }
}
