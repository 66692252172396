@use "../../global"as *;

.c-tab1 {
  position: relative;
}

.c-tab1__list1 {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
    //background: $text-icon---primary--white;
    background: transparent;


    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $neutral--300;
    border-radius: 10rem;

    @media (prefers-color-scheme: dark) {
      background: $neutral--200;
    }
  }

  &>ul {
    display: table;
    margin: 0 -4px;

    @include mq('md', min) {
      margin: 0 -40px;
    }
  }
}

.c-tab1__list1__item1 {
  display: table-cell;
  padding: 0 4px;

  @include mq('md', min) {
    padding: 0 40px;
  }
}

.c-tab1__list1__item1__button1 {
  background: none;
  border: none;
  display: grid;
  gap: 8px;
  padding: 0 12px 16px;
  min-width: 170px;
  position: relative;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;

  &.-active {
    pointer-events: none;
  }

  @include mq('md', min) {
    padding: 0 24px 32px;
  }

  &::before {
    display: block;
    content: '';
    width: 0%;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: auto;
    background: $text-icon---primary--black;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @at-root {

      .c-tab1__list1__item1__button1:hover::before,
      .c-tab1__list1__item1__button1.-active::before {
        opacity: 100%;
        width: 100%;
      }
    }

  }
}

.c-tab1__list1__item1__button1__mainLabel1 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  color: $text-icon---disabled--black;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $neutral--700;
  }

  @at-root {
    .c-tab1__list1__item1__button1:hover & {
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }
    }
  }

  @include mq('md', min) {
    font-size: 32px;
  }

  @at-root {
    .c-tab1__list1__item1__button1.-active & {
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }
    }
  }
}

.c-tab1__list1__item1__button1__subLabel1 {
  font-size: 12px;
  line-height: 1.8;
  color: $text-icon---disabled--black;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $neutral--700;
  }

  @at-root {
    .c-tab1__list1__item1__button1:hover & {
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }
    }
  }

  @include mq('md', min) {
    font-size: 16px;
  }

  @at-root {
    .c-tab1__list1__item1__button1.-active & {
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }
    }
  }
}

.c-tab1__button1 {
  position: absolute;
  top: 16px;
  right: 0;
  opacity: 0;
  pointer-events: none;
  display: none;

  @include mq('md', min) {
    top: 40px;
  }

  @at-root {
    .c-tab1.-scroll & {
      display: block;
    }
  }
}
