@use "../../global" as *;

.p-localNav1 {
  display: none;

  @include mq("md", min) {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    min-width: 1000px;
    display: block;
    background: $neutral--100;
    border-bottom: 1px solid $neutral--300;
    z-index: 100;

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }

  &.-fixed {
    @include mq("md", min) {
      //position: fixed;
      position: absolute;
      top: 118px;
      left: 0;
    }
  }
  
}

.p-localNav1__inner1 {
  min-width: 1000px;
  max-width: 1600px;
  padding: 0 100px;
  box-sizing: content-box;
  margin: auto;
  position: relative;
}

.p-localNav1__subInner1 {
  display: flex;
  gap: 48px;
  margin-right: -100px;

  @media screen and (min-width: 1815px) {
    margin-right: calc(50% - 50vw - 100px);
  }
}

.p-localNav1__title1 {
  font-size: 32px;
  line-height: 0.62;
  font-weight: 600;
  white-space: nowrap;
  display: grid;
  align-items: center;
  color: $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  & > a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    margin: 0 -24px;
    color: $text-icon---primary--black;
    text-decoration: none;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:not(.-current):hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }
  }
}

.p-localNav1__list1 {
  display: flex;
  gap: 16px;
  position: relative;
  //padding: 0 0 0 16px;
  width: 100%;
  //overflow-x: auto;

  &::-webkit-scrollbar {
    height: 5px;
    background: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $neutral--300;
    border-radius: 10rem;

    @media (prefers-color-scheme: dark) {
      background: $neutral--200;
    }
  }
}

.p-localNav1__list1__item1 {
  position: relative;

  & > a {
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: $text-icon---primary--black;
    font-size: 18px;
    //line-height: 1.11;
    line-height: 1.33;
    position: relative;
    text-decoration: none;
    padding: 22px 24px;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &.-open,
    &:not(.-current):hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &.-current {
      font-weight: 600;
    }

    &::before {
      display: none;
      content: "";
      width: 100%;
      height: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-localNav1__list1__item1 > a.-current::before {
          display: block;
        }
      }
    }
  }

  &[aria-controls],
  &:has(.p-localNav1__list2) {
    & > a {
      gap: 10px;
      &::after {
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        background: $text-icon---primary--black;
        mask-image: url("/assets/images/icon_arrow1_1.svg");
        mask-size: cover;
        transform: rotate(90deg);
        transition: all 0.7s $easeOutQuint;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--white;
        }

        @at-root {
          .p-localNav1__list1__item1 > a.-open::after,
          .p-localNav1__list1__item1:hover > a::after {
            transform: rotate(-90deg);
          }

          .p-localNav1__list1__item1 > a.-open:hover::after {
            animation: arrow3 0.35s ease-out;
          }

          .p-localNav1__list1__item1 > a:hover::after {
            animation: arrow2 0.35s ease-out;
          }
        }
      }
    }
  }
}

.p-localNav1__button1 {
  & > a {
    display: flex;
    align-items: center;
    padding: 0 24px;
    font-size: 18px;
    line-height: 1.11;
    background: $neutral--300;
    color: $text-icon---primary--black;
    text-decoration: none;
    height: 100%;
    white-space: nowrap;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $neutral--700;
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $text-icon---primary--black;
      color: $text-icon---primary--white;
    }
  }
}

.p-localNav1__list2 {
  position: absolute;
  top: 68px;
  left: 0;
  z-index: 10;
  background: rgba($text-icon---primary--white, 0.96);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  &.-open {
    opacity: 1;
    pointer-events: all;
  }

  @at-root {
    .p-localNav1__list1__item1:hover & {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.p-localNav1__list2__item1 {
  border-top: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    border-color: rgba($text-icon---primary--white, 0.24);
  }

  &:first-child {
    border: none;
  }

  a {
    font-size: 16px;
    line-height: 2.25;
    text-decoration: none;
    color: $text-icon---primary--black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px 10px 40px;
    gap: 10px;
    transition: all 0.7s $easeOutQuint;
    white-space: nowrap;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }

      //@media (prefers-color-scheme: dark) {
      //  background: $neutral--900;
      //}
    }

    @at-root {
      .p-localNav1__list2__item1:first-child a {
        padding-left: 20px;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-localNav1__list2__item1:hover a:after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

.p-localNav1__sticky1 {
  position: absolute;
  display: flex;
  gap: 8px;

  @include mq("md", min) {
    top: calc(120px + 16px);
    z-index: 101;
    right: 24px;
  }

  @include mq("md", max) {
    z-index: 101;
    width: 100%;
    padding: 0 8px;
    transform: translateY(8px)!important;
  }

  &.-fixed {
    @include mq("md", min) {
      top: 110px;
      right: 24px;
      margin-top: 24px;
    }

    @include mq("md", max) {
      position: fixed;
      top: 72px;
      right: 0;
      width: 100%;
    }
  }

  &.-careertop1 {
    @include mq("md", max) {
      display: none;
    }
    &.-fixed {
      @include mq("md", max) {
        display: flex;
      }
    }
  }

  @at-root {
    .l-document:has(.c-breadcrumbs1.is-absolute) .p-localNav1__sticky1 {
      @include mq("md", max) {
        top: 112px;
      }

      &.-fixed {
        @include mq("md", max) {
          top: 72px;
        }
      }
    }
  }
}

.p-localNav1__sticky1__button1 {
  @include mq("md", max) {
    width: calc(50% - 4px);
  }

  .c-button15 {
    display: inline-block;

    @include mq("md", max) {
      padding: 8px;
      font-size: 13px;
      line-height: 1.5;
      transition: all 0s;
    }

    &::after {
      @include mq("md", max) {
        display: none;
      }
    }
  }

  @at-root {
    .p-localNav1__sticky1.-fixed .p-localNav1__sticky1__button1 {
      .c-button15 {
        @include mq("md", max) {
          //font-size: 11px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

// is-darkmode
.is-darkmode {
  .p-localNav1 {
    @include mq("md", min) {
      //background: $neutral--900;
      background: rgba($neutral--1000, 0.88);
    }
  }
  .p-localNav1__title1 {
    color: $text-icon---primary--white;
    & > a {
      color: $text-icon---primary--white;
      &:not(.-current):hover {
        background: $neutral--800;
      }
    }
  }
  .p-localNav1__list1 {
    &::-webkit-scrollbar {
      background: $text-icon---primary--black;
    }
    &::-webkit-scrollbar-thumb {
      background: $neutral--200;
    }
  }
  .p-localNav1__list1__item1 {
    & > a {
      color: $text-icon---primary--white;
      &.-open,
      &:not(.-current):hover {
        background: $neutral--800;
      }
      &::before {
        background: $text-icon---primary--white;
      }
      &::after {
        background: $text-icon---primary--white;
      }
    }
  }
  .p-localNav1__button1 {
    & > a {
      background: $neutral--700;
      color: $text-icon---primary--white;
    }
  }
  .p-localNav1__list2 {
    background: rgba($neutral--0, 0.96);
  }
  .p-localNav1__list2__item1 {
    a {
      //&:hover {
      //  background: $neutral--900;
      //}
    }
  }
}

// is-transparent
.is-transparent {
  .p-localNav1 {
    @include mq("md", min) {
      background: rgba($neutral--1000, 0);
      transition: background 0.7s $easeOutQuint;
    }
  }
  .p-localNav1__title1 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--black;
    }
    & > a {
      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--black;
      }
    }
  }
  .p-localNav1__list1__item1 {
    & > a {
      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--black;
      }
      &::before {
        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--black;
        }
      }
    }
    &[aria-controls] {
      & > a {
        &::after {
          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--black;
          }
        }
      }
    }
  }
  .p-localNav1 {
    &.-fixed,
    &:hover {
      @include mq("md", min) {
        background: $text-icon---primary--white;
        //background: $neutral--900;
        @media (prefers-color-scheme: dark) {
          background: rgba($neutral--1000, 0.88);
        }
      }
      .p-localNav1__title1 {
        color: $text-icon---primary--black;
        @media (prefers-color-scheme: dark) {
          color: $text-icon---primary--white;
        }
        & > a {
          color: $text-icon---primary--black;
          @media (prefers-color-scheme: dark) {
            color: $text-icon---primary--white;
          }
          &:not(.-current):hover {
            background: $neutral--200;
            @media (prefers-color-scheme: dark) {
              background: $neutral--800;
            }
          }
        }
      }
      .p-localNav1__list1 {
        &::-webkit-scrollbar {
          background: $text-icon---primary--black;
        }
        &::-webkit-scrollbar-thumb {
          background: $neutral--200;
        }
      }
      .p-localNav1__list1__item1 {
        & > a {
          color: $text-icon---primary--black;
          @media (prefers-color-scheme: dark) {
            color: $text-icon---primary--white;
          }
          &.-open,
          &:not(.-current):hover {
            background: $neutral--200;
            @media (prefers-color-scheme: dark) {
              background: $neutral--800;
            }
          }
          @at-root {
            .p-localNav1:not(:hover) .p-localNav1__list1__item1 > a.-open {
              color: $text-icon---primary--black;
              @media (prefers-color-scheme: dark) {
                color: $text-icon---primary--white;
              }
            }
          }
          &::before {
            background: $text-icon---primary--black;
            @media (prefers-color-scheme: dark) {
              background: $text-icon---primary--white;
            }
          }
          &::after {
            background: $text-icon---primary--black;
            @media (prefers-color-scheme: dark) {
              background: $text-icon---primary--white;
            }
            @at-root {
              .p-localNav1:not(:hover) .p-localNav1__list1__item1 > a.-open::after {
                background: $text-icon---primary--black;
                @media (prefers-color-scheme: dark) {
                  background: $text-icon---primary--white;
                }
              }
            }
          }
        }
      }
      .p-localNav1__button1 {
        & > a {
          background: $neutral--700;
          color: $text-icon---primary--white;
        }
      }
      .p-localNav1__list2 {
        @media (prefers-color-scheme: dark) {
          background: $neutral--900;
        }
      }
      .p-localNav1__list2__item1 {
        a {
          //&:hover {
          //  background: $neutral--900;
          //}
        }
      }
    }
  }

  &.is-darkmode {
    .p-localNav1__title1 {
      color: $text-icon---primary--white;
      & > a {
        color: $text-icon---primary--white;
        &:not(.-current):hover {
          background: $neutral--800;
        }
      }
    }
    .p-localNav1__list1 {
      &::-webkit-scrollbar {
        background: $text-icon---primary--black;
      }
      &::-webkit-scrollbar-thumb {
        background: $neutral--200;
      }
    }
    .p-localNav1__list1__item1 {
      & > a {
        color: $text-icon---primary--white;
        &.-open,
        &:not(.-current):hover {
          background: $neutral--800;
        }
        &::before {
          background: $text-icon---primary--white;
        }
        &::after {
          background: $text-icon---primary--white;
        }
      }
    }
    .p-localNav1__button1 {
      & > a {
        background: $neutral--700;
        color: $text-icon---primary--white;
      }
    }
    .p-localNav1__list2 {
      background: $neutral--900;
    }
  }
}

