@use "../../global"as *;

.c-card2 {
  display: block;
  position: relative;
  overflow: hidden;
  aspect-ratio: 776/437;

  &::before {
    display: block;
    content: '';
    width: 64px;
    height: 64px;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_play1_1.svg');
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card2:hover img {
        transform: scale(1.1);
      }
    }
  }
}
