@use "../../global"as *;

.c-textLink1 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 8px;
  color: $text-icon---primary--black;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  font-size: 16px;
  line-height: 1.8;

  @include mq('md', min) {
    font-size: 24px;
    line-height: 1.5;
    padding: 10px 16px;
  }

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::before {
    flex: 20px 0 0;
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow2_1.svg');
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-textLink1:hover::before {
        animation: arrow4 0.35s ease-out;
      }
    }

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }

  .-notes {
    display: block;
    margin-left: 0.5em;
    font-size: 12px;
    font-weight: normal;
    text-indent: -0.5em;

    @include mq('md', min) {
      font-size: $font-size-note;
    }
  }
}
