@use "../../global"as *;

.c-list9 {
  list-style: decimal;
  margin: 0 0 0 1em;
}

.c-list9__item1 {
  font-size: 16px;
  line-height: 1.8;

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}
