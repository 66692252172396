@use "../../global"as *;

.c-formSelect2 {
  width: 100%;
  position: relative;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;
  }

  &>select {
    width: 100%;
    font-size: 16px;
    color: $text-icon---primary--black;
    background: none;
    appearance: none;
    padding: 9px 40px 9px 16px;
    border: 1px solid $neutral--300;
  }

  &::before {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transform: rotate(90deg);
  }
}
