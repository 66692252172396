@use "../../global" as *;

.p-block5 {
  background: $neutral--100;
  padding: 40px 20px;
  margin: 40px 0 0;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq("md", min) {
    padding: 80px 100px 120px;
  }
}

.p-block5__inner1 {
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block5__editor1 {
  color: $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  & > p {
    font-size: $font-size-body;
    margin: 28px 0 0;

    @include mq('md', min) {
      font-size: $font-size-body;
    }
  }

  & .bl_wysiwyg {
    p {
      font-size: $font-size-body;

      @include mq('md', min) {
        font-size: $font-size-body;
      }
    }
  }

  & .hs_cos_wrapper_type_icon {
    svg {
      @media (prefers-color-scheme: dark) {
        fill: #fff;
      }
    }
  }

  & > *:first-child {
    margin-top: 0 !important;
  }

  .p-block5__editorTitle1 {
    //import .c-title1__mainlabel1
    margin-top: 80px;
    font-size: 32px;
    line-height: 1.5;
    color: $text-icon---primary--black;
    font-weight: 600;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    @include mq("md", min) {
      margin-top: 120px;
      font-size: 56px;
    }
    + * {
      margin-top: 40px;
    }
  }

  .p-block5__editorTitle2 {
    //import .c-title2__mainlabel1
    margin-top: 48px;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;

    @include mq("md", min) {
      margin-top: 80px;
      font-size: 40px;
    }
    + * {
      margin-top: 24px;
      @include mq("md", min) {
        margin-top: 32px;
      }
    }
  }

  .p-block5__editorTitle3 {
    margin-top: 32px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;

    @include mq("md", min) {
      margin-top: 64px;
      font-size: 32px;
    }
    + * {
      margin-top: 16px;
      @include mq("md", min) {
        margin-top: 24px;
      }
    }
  }

  .p-block5__editorTitle4 {
    margin-top: 24px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;

    @include mq("md", min) {
      margin-top: 40px;
      font-size: 24px;
    }
    + * {
      margin-top: 8px;
      @include mq("md", min) {
        margin-top: 16px;
      }
    }
  }

  .p-block5__editorNotes1 {
    margin: 32px 0 0;
    font-size: 12px;

    @include mq('md', min) {
      font-size: $font-size-note;
    }
    > li {
      position: relative;
      padding-left: 1.25em;
      &::before {
        content: "*";
        position: absolute;
        left: 0;
        display: inline-block;
      }
    }
  }

  .p-block5__editorList1 {
    //import .c-list12
    display: grid;
    gap: 16px;
    list-style: disc;
    margin: 32px 0 0 1.25em;

    @include mq("md", min) {
      margin: 32px 0 0 calc(2em + 4px);
    }

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    & > li {
      & > p {
        margin: 8px 0 0;

        @include mq('md', min) {
          font-size: $font-size-body;
        }

        &:first-child {
          margin: 0;
        }
      }
    }
  }

  .p-block5__editorList2 {
    //import .c-list10
    counter-reset: listnum;
    list-style: none;
    display: grid;
    gap: 24px;
    margin: 32px 0 0;

    @include mq("md", min) {
      gap: 16px;
    }

    & > li {
      display: grid;

      @include mq("md", min) {
        display: flex;
        gap: 4px;
        font-size: $font-size-body;
      }

      &::before {
        counter-increment: listnum;
        content: "0" counter(listnum) ".";
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        font-family: $font-family-en;

        @include mq("md", min) {
          display: flex;
          justify-content: center;
          width: 32px;
          font-size: $font-size-body;
          transform: translateY(6px);
        }
      }
    }

    @at-root {
      .p-block5__editorList2.-type1 > li::before {
        content: counter(listnum) ".";
      }
    }
  }

  .p-block5__editorList3 {
    //import .c-list11
    counter-reset: listnum;
    list-style: none;
    display: grid;
    gap: 24px;
    margin: 32px 0 0;

    @include mq("md", min) {
      gap: 16px;
    }

    & > li {
      display: grid;
      gap: 4px;

      @include mq("md", min) {
        display: flex;
      }

      &::before {
        counter-increment: listnum;
        content: counter(listnum, upper-alpha) ".";
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        font-family: $font-family-en;
        display: block;

        @include mq("md", min) {
          display: flex;
          justify-content: center;
          width: 32px;
          font-size: $font-size-body;
          transform: translateY(6px);
        }
      }

      & > p {
        font-size: 16px;
        line-height: 1.8;

        @include mq("md", min) {
          font-size: $font-size-body;
        }
      }
    }

    @at-root {
      .p-block5__editorList3.-type1 > li::before {
        content: counter(listnum, lower-alpha) ".";
      }
    }
  }

  .p-block5__editorList4 {
    list-style: none;
    display: grid;
    gap: 24px;
    margin: 32px 0 0;

    @include mq("md", min) {
      gap: 16px;
    }

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    & > li {
      display: grid;
      gap: 4px;

      @include mq("md", min) {
        display: flex;
        font-size: $font-size-body;
      }

      & > .-mark {
        font-family: $font-family-en;
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;

        @include mq("md", min) {
          display: flex;
          justify-content: center;
          width: 32px;
          font-size: $font-size-body;
          transform: translateY(6px);
        }
      }
    }
  }

  .p-block5__editorButton1 {
    //import .c-button15
    position: relative;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    color: $text-icon---primary--white;
    text-decoration: none;
    text-align: center;
    border: 1px solid $text-icon---primary--white;
    border-radius: 10rem;
    margin: 32px 0 0;
    padding: 9px 40px 9px 32px;
    line-height: 1.8;
    transition: all 0.7s $easeOutQuint;
    background: $text-icon---primary--black;

    @include mq("md", min) {
      width: auto;
      min-width: 169px;
      font-size: $font-size-body;
    }

    &:hover {
      background: $neutral--800;
    }

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background: $text-icon---primary--white;
      mask-image: url("../images/icon_arrow1_1.svg");
      mask-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto;

      @at-root {
        .p-block5__editorButton1:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
    &.is-external {
      &::after {
        right: 8px;
        mask-image: url("/assets/images/icon_external1_1.svg");
        mask-size: cover;

        @at-root {
          .p-block5__editorButton1:hover::after {
            animation: arrow1 0.35s ease-out;
          }
        }
      }
    }
  }

  .p-block5__editorImage1 {
    margin: 32px 0 0;
    text-align: center;

    @include mq("md", min) {
      margin-top: 64px;
    }

    + .p-block5__editorImage1 {
      margin-top: 24px;
      @include mq("md", min) {
        margin-top: 40px;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
