@use "../../global"as *;

.p-block25 {
  padding: 0 12px;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
    padding: 0 100px;
  }

  &+& {
    margin: 120px 0 0;

    @include mq('md', min) {
      margin: 240px 0 0;
    }
  }
}

.p-block25__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block25__inner2 {
  display: grid;
  gap: 60px;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 88px 0 0;
    gap: 160px;
  }
}

.p-block25__title1 {}

.p-block25__title2 {}

.p-block25__text1 {
  font-size: 16px;
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 32px 0 0;
    font-size: $font-size-body;
  }
}

.p-block25__title3 {
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 32px 0 0;
  }

  .p-block25__list1+& {
    margin: 40px 0 0;
  }
}

.p-block25__list1 {
  margin: 24px 0 0;
  display: grid;
  gap: 20px;

  @include mq('md', min) {
    margin: 40px 0 0;
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}
