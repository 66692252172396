@use "../../global"as *;

.c-button3 {
  display: block;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  color: $text-icon---primary--black;
  text-decoration: none;
  padding: 16px 24px;
  border: 1px solid $neutral--300;
  border-radius: 10rem;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &.-active {
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
      color: $text-icon---primary--black;
    }
  }
}
