@use "../../global"as *;

.c-button2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.5;
  color: $text-icon---primary--white;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid $text-icon---primary--white;
  padding: 26px 0 26px 24px;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--800;
  }

  &::after {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    min-width: 48px;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button2:hover::after {
        animation: arrow1 0.35s ease-out;
      }
    }
  }
}
