@use "../../global"as *;

.c-button14 {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: block;
  width: 48px;
  height: 48px;
  display: block;
  z-index: 20;
  background: rgba($text-icon---primary--white, 0.64);
  border: 1px solid $text-icon---primary--black;
  border-radius: 10rem;
  //transition: all 0.7s $easeOutQuint;
  transition: background 0.7s $easeOutQuint;

  @include mq('md', min) {
    position: absolute;
    right: 40px;
    //bottom: 40px;
    top: calc((100vh * 1.515) - 48px - 40px);
  }

  &:hover {
    background: $neutral--200;
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    inset: 0;
    margin: auto;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow2_1.svg');
    mask-size: cover;
    transition: all 0.7s $easeOutQuint;
    transform: rotate(-180deg);

    @at-root {
      .c-button14:hover::before {
        animation: arrow6 0.35s ease-out;
      }
    }
  }
}
