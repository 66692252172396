@use "../../global" as *;

.c-formSelect1 {
  position: relative;
  top: -2px;
}

.c-formSelect1__selected1 {
  color: $text-icon---secondary--black;
  font-size: 16px;
  padding: 10px 16px 10px 8px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: $text-icon---primary--white;
  cursor: pointer;
  transition: all 0.7s $easeOutQuint;
  border: 1px solid $neutral--300;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  @at-root {
    .p-header1.-open & {
      &:hover {
        background: $neutral--200;

        @media (prefers-color-scheme: dark) {
          @include mq("md", min) {
            background: $neutral--800;
          }
        }
      }
    }
  }

  @include mq("md", min) {
    position: relative;
    height: 24px;
    padding: 0;
    border: none;
    gap: 4px;
    background: none;
    color: $text-icon---primary--black;
    font-size: 14px;
    justify-content: center;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }

  &::after {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    background: $text-icon---secondary--black;
    margin: 0 0 0 auto;
    mask-image: url("/assets/images/icon_arrow1_1.svg");
    mask-size: cover;
    transform: rotate(90deg);

    @include mq("md", min) {
      width: 24px;
      height: 24px;
    }

    @media (prefers-color-scheme: dark) {
      @include mq("md", min) {
        background-color: $text-icon---primary--white;
      }
    }
  }

  @at-root {
    .c-formSelect1__selected1:hover::after {
      animation: arrow2 0.35s ease-out;
    }

    .c-formSelect1__selected1.-open::after {
      transform: rotate(-90deg);
    }

    .c-formSelect1__selected1.-open:hover::after {
      animation: arrow3 0.35s ease-out;
    }
  }
}

.c-formSelect1__list1 {
  position: absolute;
  width: 100%;
  background: $text-icon---primary--white;
  margin: -1px 0 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.7s $easeOutQuint;
  border: 1px solid $neutral--300;

  @include mq("md", min) {
    right: 0;
    padding: 8px 0;
    width: 250px;
    box-shadow: 0 8px 32px 0 rgba($text-icon---primary--black, 0.16);
  }

  @at-root {
    .c-formSelect1__selected1.-open + & {
      opacity: 1;
      pointer-events: all;
    }
  }

  li {
    padding: 10px 16px;
    color: $text-icon---primary--black;
    font-size: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;

    @at-root {
      .c-formSelect1 {
        &:has(.c-formSelect1__selected1.-japan) .c-formSelect1__list1__item1.-japan {
          font-weight: 700;
          background: $neutral--100;
        }
        &:has(.c-formSelect1__selected1.-global) .c-formSelect1__list1__item1.-global {
          font-weight: 700;
          background: $neutral--100;
        }
      }
    }
  }
}

.c-formSelect1__list1__item1 {
  cursor: pointer;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;
  }
}
