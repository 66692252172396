@use "../../global"as *;

.p-block4 {
  margin: 40px 0 0;
  padding: 0 20px;

  @include mq('md', min) {
    padding: 0 100px;
    margin: 120px 0 0;
  }
}

.p-block4__inner1 {
  @include mq('md', min) {
    position: relative;
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block4__title1 {}

.p-block4__list1 {
  margin: 20px 0 0;

  @include mq('md', min) {
    margin: 40px 0 0;
  }
}

.p-block4__button1 {
  margin: 36px 0 0;

  @include mq('md', min) {
    margin: 0;
    position: absolute;
    top: 18px;
    right: 0;
  }
}

.p-block4__modal1 {
  position: relative;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.7s $easeOutQuint;

  &.is-open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}
