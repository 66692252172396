@use "../../global"as *;

.c-button6 {
  width: 40px;
  height: 40px;
  background: none;
  border: none;

  &::before {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_close1_1.svg');
  }
}
