@use "../../global"as *;

.c-list5 {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include mq('md', min) {
    justify-content: end;
  }

  &.-md-right {
    @include mq('md', min) {
      justify-content: end;
    }
  }
}

.c-list5__item1 {}
