@use "../../global" as *;

.c-notes1 {
  font-size: 12px;

  @include mq('md', min) {
    font-size: $font-size-note;
  }
  > li {
    position: relative;
    padding-left: 1.25em;
    &::before {
      content: "*";
      position: absolute;
      left: 0;
      display: inline-block;
    }
  }

  &.is-fs16 {
    font-size: 16px;
  }
}
