@use "../../global"as *;

.c-card5 {
  display: block;
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    top: 24px;
    right: 12px;
    border-radius: 10rem;
    border: 1px solid $text-icon---primary--white;
    z-index: 10;
    pointer-events: none;
    transition: all 0.7s $easeOutQuint;

    @include mq('md', min) {
      width: 120px;
      height: 120px;
      top: 40px;
      right: 40px;
      border-width: 4px;
    }

    @at-root {
      .c-card5:hover::before {
        background: $text-icon---primary--white;
      }
    }
  }

  &::after {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 36px;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    z-index: 10;
    transition: all 0.7s $easeOutQuint;
    right: 24px;

    @include mq('md', min) {
      width: 72px;
      height: 72px;
      top: 64px;
      right: 64px;
    }

    @at-root {
      .c-card5:hover::after {
        animation: arrow1 0.35s ease-out;
        background: $text-icon---primary--black;
      }

      .c-card5.is-external::after {
        mask-image: url('/assets/images/icon_external1_1.svg');
        mask-size: cover;
      }

    }
  }
}

.c-card5__image1 {
  display: block;
  overflow: hidden;
  aspect-ratio: 1/1;

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card5:hover .c-card5__image1>img {
        transform: scale(1.1) rotate(2.5deg);
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba($text-icon---primary--black, 0.24);
  }
}

.c-card5__text1 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 24px 12px;
  display: grid;
  color: $text-icon---disabled--white;
  font-weight: 600;
  gap: 16px;

  @include mq('md', min) {
    padding: 40px;
  }
}

.c-card5__text1__mainLabel1 {
  font-size: 32px;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 64px;
  }
}

.c-card5__text1__subLabel1 {
  font-size: 16px;
  line-height: 1.8;

  @include mq('md', min) {
    font-size: 24px;
    line-height: 1.5;
  }
}

.c-card5__text2 {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 1;
  display: block;
  font-size: 12px;
  line-height: 1.333;
  color: $text-icon---disabled--white;
  text-transform: uppercase;
  font-family: $font-family-en;
  font-weight: 700;
  text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.24);

  @include mq('md', min) {
    top: 40px;
    left: 40px;
    font-size: 24px;
  }
}

.c-card5__text2__number1 {
  display: block;
  font-size: 80px;
  line-height: 1;
  font-family: $font-family-en;

  @include mq('md', min) {
    font-size: 160px;
  }
}
