@use "../../global" as *;

.c-notes2 {
  font-size: 12px;
  counter-reset: number 0;

  @include mq('md', min) {
    font-size: $font-size-note;
  }
  > li {
    counter-increment: number 1;
    position: relative;
    padding-left: 2em;
    &::before {
      content: "*" counter(number);
      position: absolute;
      left: 0;
      display: inline-block;
    }
  }

  &[start="2"] {
    counter-reset: number 1;
  }
  &[start="3"] {
    counter-reset: number 2;
  }
  &[start="4"] {
    counter-reset: number 3;
  }
}
