@use "../../global"as *;

.p-block23 {
  padding: 0 12px;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 120px 0 0;
    padding: 0 100px;
  }

  &+& {
    padding: 40px 12px 80px;
    background: $neutral--100;

    @include mq('md', min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }
}

.p-block23__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block23__title1 {}

.p-block23__inner2 {
  margin: 40px 0 0;

  &+& {
    margin: 80px 0 0;

    @include mq('md', min) {
      margin: 120px 0 0;
    }
  }

  @include mq('md', min) {
    margin: 48px 0 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    align-items: start;
  }
}

.p-block23__image1 {
  @at-root {
    .p-block23__inner2.-reverse & {
      @include mq('md', min) {
        grid-column-start: 2;
      }
    }
  }
}

.p-block23__list1 {
  margin: 48px 0 0;
  display: grid;
  gap: 48px;

  @include mq('md', min) {
    margin: 0;
    gap: 48px;
  }

  @at-root {
    .p-block23__inner2.-reverse & {
      @include mq('md', min) {
        grid-column-start: 1;
        grid-row-start: 1;
      }
    }
  }
}
