@use "../../global"as *;

.c-textarea1 {
  width: 100%;
  border: 1px solid $neutral--300;
  resize: none;
  padding: 8px 16px;
  height: 104px;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--white;
  }

  &::placeholder {
    color: $neutral--300;
  }
}
