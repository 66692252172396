@use "../../global"as *;

.c-list4 {
  border-bottom: 1px solid $neutral--300;
}

.c-list4__item1 {
  position: relative;
  border-top: 1px solid $neutral--300;
  padding: 24px 0;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  @include mq('md', min) {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  &.-nolink1 {
    pointer-events: none;
  }
}

.c-list4__item1__date1 {
  font-family: $font-family-en;
  font-size: 16px;
  color: $text-icon---primary--black;
  white-space: nowrap;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}

.c-list4__item1__title1 {
  font-size: 16px;
  color: $text-icon---primary--black;
  margin: 8px 0 0;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    margin: 0;
    font-size: $font-size-body;
  }

  @at-root {
    .c-list4__item1:hover & {
      font-weight: 600;
    }
  }
}

.c-list4__item1__list1 {
  position: relative;
  z-index: 1;
  margin: 16px 0 0;

  @include mq('md', min) {
    margin: 0 0 0 auto;
    min-width: 290px;
    max-width: 290px;
  }
}

.c-list4__item1__link1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.7s $easeOutQuint;
}
