@use "../../global"as *;

.c-card7 {
  text-decoration: none;
  position: relative;
  display: block;
}

.c-card7__image1 {
  img {
    width: 100%;
    height: auto;
  }
}

.c-card7__button1 {
  position: absolute;
  top: 12px;
  right: 12px;

  @include mq('md', min) {
    top: 64px;
    right: 64px;
  }
}
