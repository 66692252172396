@use "../../global"as *;

.c-button13 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $text-icon---primary--black;
  color: $text-icon---primary--white;
  font-size: 24px;
  line-height: 1.5;
  padding: 12px 56px;
  border: none;
  width: 100%;
  border-radius: 10rem;
  font-weight: 600;
  height: 80px;
  white-space: nowrap;
  transition: all 0.7s $easeOutQuint;
  text-decoration: none;

  @media (prefers-color-scheme: dark) {
    border: 1px solid $neutral--300;
  }

  &:hover {
    background: $neutral--800;
  }

  &.-arrow {
    position: relative;
    &::after {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      background: $text-icon---primary--white;
      mask-image: url('../images/icon_arrow1_1.svg');
      mask-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 28px;
      margin: auto;

      @at-root {
        .c-button13.-arrow:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }

  &:disabled {
    background: $text-icon---disabled--black;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
      color: $neutral--800;
    }
  }
}
