@use "../../global"as *;

.p-block20 {
  margin: 80px 0 0;
  padding: 40px 12px 80px;
  background: $neutral--700;

  @include mq('md', min) {
    padding: 120px 100px 184px;
    margin: 288px 0 0;
  }
}

.p-block20__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block20__title1 {}

.p-block20__text1 {
  margin: 40px 0 0;
  color: $text-icon---primary--white;
  font-size: 16px;

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}

.p-block20__table1 {
  margin: 64px 0 0 0;

  @include mq('md', min) {
    margin-right: 0;
  }

  &:has(.c-table6.-scroll) {
    margin-right: -12px;

    @include mq('md', min) {
      margin-right: 0;
    }
  }
}
