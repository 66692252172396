@use "../../global"as *;

.c-list12 {
  display: grid;
  gap: 16px;
  list-style: disc;
  margin: 0 0 0 1.25em;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
  }

  &.-narrow {
    gap: 0;
  }
}

.c-list12__item1 {
  &>p {
    margin: 8px 0 0;

    @include mq('md', min) {
      font-size: $font-size-body;
    }

    &:first-child {
      margin: 0;
    }
  }
}
