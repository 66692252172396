@use "../../global"as *;

.c-formRadio1 {
  position: relative;
}

.c-formRadio1__input1 {
  position: absolute;
  opacity: 0;
}

.c-formRadio1__label1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  display: block;
  display: flex;
  align-items: center;
  gap: 18px;
  border: 1px solid $neutral--300;
  border-radius: 10rem;
  padding: 21px 24px;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  @include mq('md', min) {
    font-size: 32px;
  }

  @at-root {
    .c-formRadio1.-active & {
      background: $text-icon---primary--black;
      color: $text-icon---primary--white;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
        color: $text-icon---primary--black;
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_radio1_1.svg');

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @at-root {
      .c-formRadio1__input1:checked+.c-formRadio1__label1::before {
        background: $text-icon---primary--white;
        mask-image: url('/assets/images/icon_radioCheck1_1.svg');

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--black;
        }
      }
    }
  }

}
