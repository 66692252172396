@charset "UTF-8";

@use "vendor";
@use "foundation";
@use "layout";
@use "object";

@use "global"as *;
.u-hidden {
  display: none;
}
.u-hidden-sp {
  @include mq('md', max) {
    display: none;
  }
}
.u-hidden-pc {
  @include mq('md', min) {
    display: none;
  }
}
.u-fBold {
  font-weight: 700!important;
}
.u-fColorRed {
  color: $danger--700!important;
}
.u-tAlignC {
  text-align: center!important;
}
.u-tAlignR {
  text-align: right!important;
}
.u-tAlignL {
  text-align: left!important;
}
.u-mt0 {
  margin-top: 0!important;
}
.u-mt8 {
  margin-top: 8px!important;
}
.u-mt16 {
  margin-top: 8px!important;
}