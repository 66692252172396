@use "../../global"as *;

.c-button12 {
  background: $text-icon---primary--white;
  border: 1px solid $neutral--300;
  border-radius: 10rem;
  padding: 16px;
  display: block;
  text-decoration: none;
  color: $text-icon---primary--black;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::before {
    display: block;
    content: '';
    flex: 36px 0 0;
    width: 36px;
    height: 36px;

    @at-root {
      .c-button12.-pdf::before {
        background: url('/assets/images/icon_pdf1_2.svg') 0 0 / cover no-repeat;

        @media (prefers-color-scheme: dark) {
          background: url('/assets/images/icon_pdf1_3.svg') 0 0 / cover no-repeat;
        }
      }

      .c-button12.-xls::before {
        background: url('/assets/images/icon_xls1_1.svg') 0 0 / cover no-repeat;

        @media (prefers-color-scheme: dark) {
          background: url('/assets/images/icon_xls1_2.svg') 0 0 / cover no-repeat;
        }
      }

      .c-button12.-zip::before {
        background: url('/assets/images/icon_zip1_1.svg') 0 0 / cover no-repeat;

        @media (prefers-color-scheme: dark) {
          background: url('/assets/images/icon_zip1_2.svg') 0 0 / cover no-repeat;
        }
      }
    }
  }

  &::after {
    display: block;
    content: '';
    flex: 24px 0 0;
    width: 24px;
    height: 24px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_download1_1.svg');
    margin: 0 0 0 auto;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }
}

.c-button12__fileName1 {
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;
  align-items: center;
}

.c-button12__fileName1__mainLabel1 {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 600;
}

.c-button12__fileName1__subLabel1 {
  font-size: 12px;
  line-height: 1.33;
}
