@use "../../global"as *;

.c-title1 {}

.c-title1__mainLabel1 {
  font-size: 32px;
  line-height: 1.5;
  color: $text-icon---primary--black;
  font-weight: 600;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    font-size: 56px;
  }

  @at-root {
    .c-title1.-en & {
      font-family: $font-family-en;
    }

    .c-title1.-white & {
      color: $text-icon---primary--white;
    }

    .c-title1.-gray & {
      color: rgba($text-icon---secondary--black, 0.8);

      @media (prefers-color-scheme: dark) {
        color: rgba($text-icon---primary--white, 0.8);
      }
    }
  }
}
