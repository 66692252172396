@use "../../global"as *;

.c-card20 {
  border: 1px solid $neutral--300;
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);
}

.c-card20__toggleButton1 {
  background: none;
  border: none;
  width: 100%;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  text-align: left;
  padding: 24px 12px;
  display: grid;
  grid-template-columns: 1fr 48px;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid $neutral--300;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  @include mq('md', min) {
    padding: 24px;
  }

  &>span {
    display: grid;
    gap: 8px;

    @include mq('md', min) {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &::before {
      display: block;
      content: 'Q';
      font-size: 48px;
      line-height: 1;
      font-family: $font-family-en;
      font-weight: 700;

      @include mq('md', min) {
        font-size: 40px;
        line-height: 0.75;
      }
    }
  }

  &::after {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('../images/icon_arrow1_1.svg');
    mask-size: cover;
    transform: rotate(90deg);
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @at-root {
      .c-card20__toggleButton1.-open::after {
        transform: rotate(-90deg);
      }
    }
  }
}

.c-card20__toggleArea1 {
  overflow: hidden;
  height: 0;
}

.c-card20__toggleArea1__inner1 {
  padding: 24px 12px;

  @include mq('md', min) {
    position: relative;
    padding: 24px 24px 32px 71px;
  }
}

.c-card20__text1 {
  &>p {
    font-size: 16px;
    display: grid;
    gap: 4px;
    margin: 24px 0 0;

    @include mq('md', min) {
      font-size: $font-size-body;
    }

    &:first-child {
      margin: 0;
    }
  }

  &::before {
    display: block;
    content: 'A';
    font-size: 24px;
    line-height: 1.25;
    font-family: $font-family-en;
    font-weight: 700;

    @include mq('md', min) {
      position: absolute;
      top: 24px;
      left: 24px;
      font-size: 40px;
      line-height: 0.75;
    }
  }
}

.c-card20__link1 {
  margin: 16px 0 0;

  @include mq('md', min) {
    margin: 24px 0 0;
  }
}
