@use "../../global"as *;

.p-block8 {
  margin: 40px 0 0;
  padding: 40px 0 80px;
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    margin: 160px 0 0;
    padding: 120px 100px 160px;
  }
}

.p-block8__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}
