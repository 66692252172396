@use "../../global"as *;

.c-title2 {}

.c-title2__mainLabel1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 40px;
  }
}
