@use "../global"as *;

.l-document {
  position: relative;
  padding: 72px 0 0;
  transition: all 0.7s $easeOutQuint;

  @include mq('md', min) {
    min-width: 1200px;
    overflow: hidden;
    //padding: 86px 0 0;
    padding: 120px 0 0;
  }

  &:has(.p-localNav1) {
    @include mq('md', min) {
      padding: calc(120px + 68px) 0 0;
    }
  }

  &:has(.p-header1__mobile1__header1__button2.-active) {
    padding: 156px 0 0;

    @include mq('md', min) {
      padding: 120px 0 0;
    }
  }

  &:has(.include_header_default.is-absolute) {
    padding-top: 0;
  }

}

.l-contents {
  &.-fullscreen {
    overflow: hidden;
    width: 100%;
    height: 100svh;
    @include mq('md', min) {
      //height: 100vh;
      height: 150vh;
    }
  }
}
