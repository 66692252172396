@use "variables"as *;

// Media Query
// ============================================================================

// How to Use:

// @include mq(引数1, 引数2) {
//   [style]
// }
//
// 引数1: sm, md, lg, number
// 引数2: '', min

// 1. マルチデバイスで共通のスタイル
// 2. 特に小さいデバイス向けの調整スタイル
// 3. スマートフォン、タブレット縦向けのスタイル
// 4. タブレット横、PC向けのスタイル
// 5. 1209px(1208px + 1px)以上向けのスタイル
//
// ```scss
// .example {
//   color: #000; // 1
//
//   @include mq(sm) {
//     content: 'sm'; // 2
//   }
//
//   @include mq(md) {
//     content: 'md'; // 3
//   }
//
//   @include mq(md, min) {
//     content: 'md min'; // 4
//   }
//
//   @include mq(1208px, min) {
//     content: 'number'; // 5
//   }
// }
// ```

// == Media Query
@mixin mq($size, $width: max) {
  $result: 0;

  @if (type-of($size)==number) {
    $result: $size;
  }

  @else {

    @each $key,
    $value in $screen {
      @if ($key==$size) {
        $result: $value;
      }
    }
  }

  @if ($width==max) {
    $result: $result - 1;
  }

  @media (#{$width}-width: #{$result}px) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

// How to Use:

// 非タッチデバイス時向けのスタイル（主にホバーアクション）

// ```scss
// .example:hover {
//
//   @include hoverable {
//      text-decoration: underline;
//   }
//
// }

@mixin hoverable {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}
