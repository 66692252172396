@use "../../global"as *;

.p-block22 {
  background: $neutral--800;
  padding: 40px 12px 80px;
  margin: 0 !important;
  color: $text-icon---primary--white;

  @include mq('md', min) {
    padding: 80px 100px 120px;
  }
}

.p-block22__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block22__text1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;

  &>span {
    font-size: 12px;
    display: block;
  }
}

.p-block22__title1 {
  margin: 64px 0 0;
  text-align: center;
}

.p-block22__list1 {
  margin: 40px 0 0;
  display: grid;
  gap: 40px;

  @include mq('md', min) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1184px;
    margin: 48px auto 0;
  }
}

.p-block22__list2 {
  display: grid;
  gap: 40px;

  @include mq('md', min) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1600px;
    margin: 0 auto;
  }
}
