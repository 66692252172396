@use "../../global"as *;

.c-card1 {
  display: block;
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    top: 24px;
    right: 12px;
    border-radius: 10rem;
    border: 1px solid $text-icon---primary--white;
    z-index: 10;
    pointer-events: none;
    transition: all 0.7s $easeOutQuint;

    @include mq('md', min) {
      right: 24px;
    }

    @at-root {
      .c-card1:hover::before {
        background: $text-icon---primary--white;
      }

      .c-card1.-noArrow::before {
        display: none;
      }

      .c-card1.-col2.-row2::before {
        top: 64px;
        right: 64px;
        width: 120px;
        height: 120px;
        border-width: 4px;
      }
    }
  }

  &::after {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 36px;
    right: 24px;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    z-index: 10;
    transition: all 0.7s $easeOutQuint;

    @include mq('md', min) {
      right: 36px;
    }

    @at-root {
      .c-card1:hover::after {
        background: $text-icon---primary--black;
        animation: arrow1 0.35s ease-out;
      }

      .c-card1.-noArrow::after {
        display: none;
      }

      .c-card1.-col2.-row2::after {
        width: 72px;
        height: 72px;
        top: 88px;
        right: 88px;
      }
    }
  }
}

.c-card1__image1 {
  display: block;
  overflow: hidden;
  aspect-ratio: 1/1;

  @at-root {
    .c-card1.-col2 & {
      aspect-ratio: 776/368;
    }

    .c-card1.-col2.-row2 & {
      aspect-ratio: 1/1;
    }
  }

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card1:hover .c-card1__image1>img {
        transform: scale(1.1) rotate(2.5deg);
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba($text-icon---primary--black, 0.24);

    @at-root {
      .c-card1__image1.-noLayer::before {
        display: none;
      }
    }
  }
}

.c-card1__text1 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  font-size: 32px;
  line-height: 1.25;
  color: $text-icon---disabled--white;
  font-weight: 600;
  text-shadow: 0 0 24px rgba($text-icon---primary--black, 0.24);
  padding: 24px 12px;

  @include mq('md', min) {
    padding: 24px;
  }
}

.c-card1__text1__sub1 {
  font-size: 24px;
}