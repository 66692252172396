@use "../../global" as *;

.c-card4 {
  display: block;
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 24px;
    right: 12px;
    border-radius: 10rem;
    border: 1px solid $text-icon---primary--white;
    z-index: 10;
    pointer-events: none;
    transition: all 0.7s $easeOutQuint;

    @include mq("md", min) {
      right: 24px;
    }

    @at-root {
      .c-card4:hover::before {
        background: $text-icon---primary--white;
      }
    }
  }

  &::after {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    top: 36px;
    right: 24px;
    background: $text-icon---primary--white;
    mask-image: url("/assets/images/icon_arrow1_1.svg");
    mask-size: cover;
    z-index: 10;
    transition: all 0.7s $easeOutQuint;

    @include mq("md", min) {
      right: 36px;
    }

    @at-root {
      .c-card4:hover::after {
        animation: arrow1 0.35s ease-out;
        background: $text-icon---primary--black;
      }
    }
  }
}

.c-card4__image1 {
  display: block;
  overflow: hidden;
  aspect-ratio: 1/1;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card4:hover .c-card4__image1 > img {
        transform: scale(1.1) rotate(2.5deg);
      }
    }
  }

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba($text-icon---primary--black, 0.24);

    @at-root {
      .c-card4__image1.-noLayer::before {
        display: none;
      }
    }
  }
}

.c-card4__text1 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 24px 12px;
  display: grid;
  color: $text-icon---disabled--white;
  font-weight: 600;
  gap: 16px;

  @include mq("md", min) {
    padding: 24px;
  }

  @include mq("md", min) {
    padding: 40px;
  }
}

.c-card4__text1__mainLabel1 {
  font-size: 32px;
  line-height: 1.5;
}

.c-card4__text1__subLabel1 {
  font-size: 16px;
  line-height: 1.8;
}

.c-card4__text1__subLabel2 {
  margin-bottom: -16px;
  font-size: 20px;
  line-height: 1.8;
}
