@use "../../global"as *;

.c-formLabel1 {
  font-weight: 600;

  &>span {
    color: $danger--700;
  }
  &>em {
    font-weight: 400;
    font-style: normal;
  }
}
