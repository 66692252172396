@use "../../global"as *;

.p-block17 {
  margin: 40px 0 0;
  padding: 0 12px;

  @include mq('md', min) {
    margin: 120px 0 0;
    padding: 0 100px;
  }

  &.-gray {
    padding: 40px 12px 80px;
    background: $neutral--100;

    @include mq('md', min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }
}

.p-block17__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block17__iframe1 {
  width: calc(100% + 24px);
  margin: 0 -12px;
  padding: 0;
  border: none;
  @include mq('md', min) {
    margin: 0;
  }
}