@use "../../global"as *;

.c-title3 {}

.c-title3__mainLabel1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 40px;
  }
}

.c-title3__title1 {
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    display: flex;
    gap: 24px;
  }
}

.c-title3__title1__number1 {
  font-size: 40px;
  line-height: 1;
  font-family: $font-family-en;

  @include mq('md', min) {
    line-height: 1.2;
    font-size: 56px;
  }
}

.c-title3__title1__mainLabel1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 40px;
  }
}
