@use "../../global" as *;

// heading1
.p-message-heading1 {
  overflow: hidden;
  background: $neutral--1000 url("/assets/images/img_career_message_1.png") 0 67px / 100% auto no-repeat;
  @include mq("md", min) {
    background: $neutral--1000 url("/assets/images/img_career_message_1_desktop.png") 0 0 / 100% auto no-repeat;
  }
  &.-type1 {
    background: $neutral--1000 url("/assets/images/img_career_future_1.jpg") 0 67px / 100% auto no-repeat;
    @include mq("md", min) {
      background: url("/assets/images/img_career_future_1_desktop.jpg") 50% 0 / cover no-repeat;
    }
  }
  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
  }
}

.p-message-heading1__inner {
  padding: 160px 12px 80px;
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 353px 100px 445px;
    box-sizing: content-box;
    margin: auto;
  }
  @at-root {
    .p-message-heading1.-type1 .p-message-heading1__inner {
      @include mq("md", min) {
        padding-bottom: 300px;
      }
    }
  }
}

.p-message-heading1__label1 {
  text-align: right;
  font-size: 50px;
  line-height: 1.25;
  font-weight: 900;
  @include mq("md", min) {
    font-size: 136px;
  }
}

.p-message-heading1__text1 {
  margin: 31px 0 0;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;

  @include mq("md", min) {
    width: 816px;
    margin: 62px 0 0 auto;
    font-size: 40px;
  }
}

.p-message-heading1__body1 {
  position: relative;
  @include mq("md", min) {
    margin: 1130px 0 0;
    text-shadow: 0 0 12px rgba(0, 0, 0, 1);
  }
  //&::before {
  //  position: absolute;
  //  content: "";
  //  display: block;
  //  width: 440px;
  //  height: 389px;
  //  background: url("/assets/images/img_career_message_0.png") 0 0 / 440px 389px no-repeat;
  //  @include mq("md", min) {
  //    top: -250px;
  //    left: 56%;
  //    width: 1140px;
  //    height: 1007px;
  //    background: url("/assets/images/img_career_message_0.png") 0 0 / 1140px 1007px no-repeat;
  //  }
  //}
  &.-type1 {
    @include mq("md", min) {
      margin: 1020px 0 0;
    }
  }
}

.p-message-heading1__label2 {
  margin-top: 350px;
  color: $text-icon---primary--white;
  @include mq("md", min) {
    display: flex;
    align-items: center;
    gap: 53px;
    margin-top: 0;
  }
  @at-root {
    .p-message-block1.-type4 & {
      margin-top: 0;
    }
  }
}
.p-message-heading1__label2__num {
  display: block;
  font-size: 180px;
  line-height: 1;
  font-family: $font-family-en;
  font-weight: 700;
  @include mq("md", min) {
    font-size: 360px;
  }
}
.p-message-heading1__label2__text {
  margin-top: 30px;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 900;
  @include mq("md", min) {
    margin-top: 0;
    font-size: 88px;
    line-height: 1.363636363636364;
  }
  .-gray {
    opacity: 0.6;
  }
}

.p-message-heading1__text2 {
  margin: 40px 0 0;
  color: $text-icon---primary--white;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    margin: 130px 0 0;
    font-size: 32px;
  }

  &.-type1 {
    @include mq("md", min) {
      margin: 32px 0 0;
    }
  }

  &.-type2 {
    font-size: 24px;

    @include mq("md", min) {
      margin: 32px 0 0;
      font-size: 40px;
    }
  }
}

// block1
.p-message-block1 {
  @include mq("md", min) {
  }
  &.-type1 {
    position: relative;
    background: #0069c8;
    @include mq("md", min) {
      margin-top: calc(-100% * 220 / 1920);
      background: $neutral--1000 url("/assets/images/img_career_message_2_desktop.png") 50% 0 / cover no-repeat;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      margin-top: calc(-100% * 52 / 375);
      background: url("/assets/images/img_career_message_2.png") 0 0 / 100% auto no-repeat;
      @include mq("md", min) {
        display: none;
      }
    }
  }
  &.-type2 {
    position: relative;
    background: $neutral--800;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: calc(100% * 711 / 375);
      background: url("/assets/images/img_career_message_3_desktop.jpg") 50% 0 / cover no-repeat;
      @include mq("md", min) {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        padding: 0;
      }
    }
    &::after {
      @include mq("md", min) {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 50%;
        height: 100%;
        background: url("/assets/images/img_career_message_7.png") -57px 564px / auto no-repeat;
      }
    }
  }
  &.-type3 {
    background: $text-icon---primary--white url("/assets/images/img_career_message_8.png") 0 0 / 100% auto no-repeat;
    @include mq("md", min) {
      position: relative;
      background: $text-icon---primary--white url("/assets/images/img_career_message_8_desktop.png") 0 0 / 100% no-repeat;
    }
  }
  &.-type4 {
    margin-top: calc(-100% * 38 / 375);
    background: url("/assets/images/img_career_future_2.png") 50% 0 / cover no-repeat;
    @include mq("md", min) {
      margin-top: calc(-100% * 300 / 1920);
      background: url("/assets/images/img_career_future_2_desktop.png") 50% 0 / cover no-repeat;
    }
  }
  &.-type5 {
    background: $text-icon---primary--white url("/assets/images/img_career_future_3.png") 0 0 / 100% auto no-repeat;
    @include mq("md", min) {
      position: relative;
      background: $text-icon---primary--white url("/assets/images/img_career_future_3_desktop.png") 0 40% / 100% no-repeat;
    }
  }
}
.p-message-block1__inner {
  padding: 80px 12px 120px;
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 160px 100px 400px;
    box-sizing: content-box;
    margin: auto;
  }
  @at-root {
    .p-message-block1.-type1 {
      .p-message-block1__inner {
        position: relative;
        @include mq("md", min) {
          padding-top: 495px;
        }
      }
    }
    .p-message-block1.-type2 {
      .p-message-block1__inner {
        padding-top: 0;
        position: relative;
        z-index: 2;
        @include mq("md", min) {
          padding-top: 160px;
        }
        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: url("/assets/images/img_career_message_7.png") -154px 177px / 460px 668px no-repeat;
          @include mq("md", min) {
            display: none;
          }
        }
      }
    }
    .p-message-block1.-type3 {
      .p-message-block1__inner {
        padding-top: calc(100% * 312 / 375);
        @include mq("md", min) {
          padding-top: 160px;
        }
      }
    }
    .p-message-block1.-type4 {
      .p-message-block1__inner {
        padding-top: calc(100% * 78 / 375);
        padding-right: 10px;
        padding-bottom: calc(100% * 78 / 375);
        padding-left: 10px;
        @include mq("md", min) {
          padding-top: calc(100% * 820 / 1920);
          padding-bottom: calc(100% * 400 / 1920);
        }
      }
    }
    .p-message-block1.-type5 {
      .p-message-block1__inner {
        @include mq("md", min) {
          padding-top: calc(100% * 366 / 1920);
          padding-bottom: calc(100% * 554 / 1920);
        }
      }
    }
  }
}

.p-message-block1__title1 {
  color: $text-icon---primary--white;
  &.-black {
    color: $text-icon---primary--black;
  }
  &.-type2 {
    text-align: center;
    @include mq("md", min) {
      font-size: 360px;
    }
    .p-message-block1__title1__text {
      margin-top: 0;
    }
  }
}
.p-message-block1__title1__num {
  display: block;
  font-size: 180px;
  line-height: 1;
  font-family: $font-family-en;
  font-weight: 700;
  @include mq("md", min) {
    font-size: 360px;
  }
  @at-root {
    .p-message-block1__title1.-type1 .p-message-block1__title1__num {
      margin-top: -0.5em;
      @include mq("md", min) {
        margin-top: 0;
      }
    }
  }
}
.p-message-block1__title1__text {
  display: block;
  margin-top: 30px;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 900;
  @include mq("md", min) {
    margin-top: 0;
    font-size: 88px;
    line-height: 1.363636363636364;
  }
  .-gray {
    opacity: 0.6;
  }
}

.p-message-block1__text1 {
  margin: 60px 0 0;
  color: $text-icon---primary--white;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    margin: 184px 0 0;
    font-size: 32px;
    line-height: 1.5;
  }
  + .p-message-block1__text1 {
    margin-top: 1em;
  }
  &.-black {
    color: $text-icon---primary--black;
  }

  &.-type1 {
    @include mq("md", min) {
      margin-top: 40px;
    }
  }

  &.-type2 {
    font-size: 24px;

    @include mq("md", min) {
      margin: 40px 0 0;
      font-size: 40px;
    }
  }
}

.p-message-block1__list1 {
  @include mq("md", min) {
    display: flex;
    justify-content: space-between;
  }
}

.p-message-block1__list1__item {
  @include mq("md", min) {
    width: calc(33.33% - 120px);
  }
  + .p-message-block1__list1__item {
    margin-top: 80px;
    @include mq("md", min) {
      margin-top: 0;
    }
  }
  &:nth-child(2) {
    @include mq("md", min) {
      margin-top: 300px;
    }
  }
  &:nth-child(3) {
    @include mq("md", min) {
      margin-top: 600px;
    }
  }
}

.p-message-block1__list1__image {
  overflow: hidden;
  border-radius: 60px;
  @include mq("md", min) {
    border-radius: 80px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.p-message-block1__list1__title {
  margin: 20px 0 0;
  color: $text-icon---primary--white;
  font-size: 60px;
  line-height: 1.3;
  font-family: $font-family-en;
  font-weight: 700;
  @include mq("md", min) {
    margin: 40px 0 0;
    font-size: 96px;
    line-height: 1;
  }
}

.p-message-block1__list1__text {
  margin: 20px 0 0;
  color: $text-icon---primary--white;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    margin: 40px 0 0;
    font-size: 40px;
  }
}
