@use "../../global"as *;

.c-table5 {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);

  table {
    width: 100%;
    border-collapse: collapse;

    @media (prefers-color-scheme: dark) {
      border: 1px solid $neutral--300;
    }

    @at-root {
      .c-table5.-scroll table {
        table-layout: auto;
      }
    }

    th,
    td {
      &.-nowrap {
        white-space: nowrap;
      }
      &.-spNowrap {
        white-space: nowrap;
        @include mq('md', min) {
          white-space: normal;
        }
      }
    }

    thead {

      th {
        font-size: 18px;
        line-height: 2;
        padding: 8px;
        background: $neutral--300;

        @media (prefers-color-scheme: dark) {
          background: $neutral--700;
        }

        @include mq('md', min) {
          font-size: 24px;
          line-height: 1.5;
          padding: 16px;
        }

        &.-small {
          font-size: 16px;
          @include mq('md', min) {
            font-size: $font-size-body;
          }
        }
      }

      td {
        padding: 8px;
        font-size: 16px;
        line-height: 1.8;
        text-align: center;

        @include mq('md', min) {
          padding: 16px 12px;
          font-size: $font-size-body;
        }
      }

    }

    tbody {

      tr {
        border-top: 1px solid $neutral--300;

        &:nth-of-type(odd) {
          background: $text-icon---primary--white;

          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--black;
          }
        }
        &:nth-of-type(even) {
          background: $neutral--100;

          @media (prefers-color-scheme: dark) {
            background: $neutral--900;
          }
        }
      }

      th,
      td {
        padding: 8px 12px;
        font-size: 16px;
        line-height: 1.8;

        @include mq('md', min) {
          padding: 16px 24px;
          font-size: $font-size-body;
        }
      }

      th {
        white-space: nowrap;
        background: $neutral--200;
        width: 123px;

        @media (prefers-color-scheme: dark) {
          background: $neutral--800;
        }
      }

      td {
        text-align: center;
        white-space: nowrap;
      }
    }

    &.-type1 {
      tbody {
        th,
        td {
          @include mq('md', min) {
            padding: 16px 12px;
          }
        }

        td {
          @include mq('md', min) {
            white-space: normal;
          }
        }
      }
    }
  }

  .c-list12 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

.c-table5__inner1 {
  overflow: visible;

  @at-root {
    .c-table5.-scroll & {
      overflow-x: scroll;
    }
  }
}

.c-table5__button1 {
  position: absolute;
  top: 24px;
  right: 0;
  opacity: 0;
  pointer-events: none;
}
