@use "../../global"as *;

.c-formText3 {
  flex: 1;

  &>input {
    width: 100%;
    border: 1px solid $neutral--300;
    padding: 8px 16px;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    &::placeholder {
      color: $neutral--300;
    }
  }
}
