@use "../../global"as *;

// careertop1
.p-careertop-block1 {
  position: relative;
}

.p-careertop-block1__slide1wrap {
  position: relative;
  padding-top: 72px;
  @include mq('md', min) {
    padding-top: 0;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 16px;
    line-height: 0;
    margin: 0;

    @include mq("md", min) {
      bottom: 166px;
    }
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    background: $text-icon---primary--white;
    opacity: 0.32;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: $text-icon---primary--white;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    width: 68px;
    height: 68px;
    border-radius: 10rem;
    transition: all 0.7s $easeOutQuint;

    @include mq("md", min) {
      display: block;
    }

    &:hover {
      animation: arrow1 0.35s ease-out;
    }

    &::after {
      display: none;
    }

    &::before {
      display: block;
      content: "";
      width: 68px;
      height: 68px;
      background: $text-icon---primary--white;
      mask-image: url("../images/icon_arrow1_1.svg");
      mask-size: cover;
    }
  }

  .swiper-button-prev {
    rotate: 180deg;
  }

  .swiper-button-disabled {
    opacity: 0;
  }
}

.p-careertop-block1__slide1 {
  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
  }
}

.p-careertop-block1__slide1__item {
  position: relative;
  background: $neutral--0;
  height: calc(100svh - 72px)!important;

  @include mq('md', min) {
    //min-height: 100vh!important;
    height: calc((100svh * 1.5) - 72px)!important;
    min-height: calc(100vh * 1.5)!important;
  }

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
  }
}

.p-careertop-block1__slide1next {
  display: none;
  @include mq('md', min) {
    position: absolute;
    //top: 100vh;
    top: calc(100vh * 1.5);
    left: 50%;
    display: block;
    padding-bottom: 52px;
    border: none;
    background: transparent;
    font-size: 24px;
    line-height: 1;
    font-family: $font-family-en;
    font-weight: 700;
    transform: translate(-50%, -100%);
  }
  &::after {
    @include mq('md', min) {
      content: "";
      position: absolute;
      bottom: 0;
      left: calc(50% - 24px);
      width: 48px;
      height: 48px;
      background: $text-icon---primary--black;
      mask-image: url('/assets/images/icon_arrow1_1.svg');
      mask-size: cover;
      transform: rotate(90deg);
    }
    @at-root {
      .p-careertop-block1__slide1next:hover::after {
        animation: arrow2 0.35s ease-out;
      }
    }
  }
  &.swiper-button-next {
    @include mq('md', min) {
      right: inherit;
      width: auto;
      height: auto;
      margin-top: 0;
      color: $text-icon---primary--black;
    }
    &:disabled {
      display: none;
    }
  }
}

.p-careertop-block1__slide1nav {
  position: absolute;
  top: calc(100svh - 72px);
  left: 50%;
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-bottom: 20px;
  transform: translate(-50%, -100%);
  @include mq('md', min) {
    //top: 50vh;
    top: calc(50vh * 1.5);
    left: 40px;
    flex-direction: column;
    width: 55px;
    padding-bottom: 0;
    transform: translateY(-50%);
  }
  @at-root {
    .swiper-pagination-vertical.swiper-pagination-bullets.p-careertop-block1__slide1nav {
      top: calc(100svh);
      right: inherit;
      transform: translate(-50%, -100%);
      @include mq('md', min) {
        //top: 50vh;
        top: calc(50vh * 1.5);
        transform: translateY(-50%);
      }
    }
  }
}

.p-careertop-block1__slide1nav__item1 {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: transparent;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  font-family: $font-family-en;
  font-weight: 700;
  transition: all 0.3s ease-out;
  @include mq('md', min) {
    flex-direction: column;
    font-size: 16px;
  }
  &.-current {
    color: $text-icon---primary--white;
    font-size: 24px;
    @include mq('md', min) {
      font-size: 40px;
    }
  }
  + .p-careertop-block1__slide1nav__item1 {
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 1px;
      background: $text-icon---primary--black;
      pointer-events: none;
      @include mq('md', min) {
        display: block;
        width: 1px;
        height: 16px;
      }
    }
  }
  @at-root {
    .swiper-pagination-vertical.swiper-pagination-bullets .p-careertop-block1__slide1nav__item1,
    .swiper-vertical > .swiper-pagination-bullets .p-careertop-block1__slide1nav__item1 {
      &.swiper-pagination-bullet {
        margin: 0;
        display: flex;
        width: auto;
        height: auto;
        background: transparent;
        border-radius: 0;
        opacity: 1;
      }
    }
    .p-careertop-block1__slide1nav__item1.swiper-pagination-bullet:only-child {
      display: block!important;
      margin: 0;
      opacity: 1;
    }
  }
  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
  }
}

.p-careertop-block1__image1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  @include mq('md', min) {
    //height: 100vh;
    height: calc(100vh * 1.5);
  }
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.p-careertop-block1__image2 {
  overflow: hidden;
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.p-careertop-block1__text1 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  background: rgba($neutral--0, 0.48);

  @include mq('md', min) {
    top: 50%;
    left: 50%;
    width: calc(100% * 960/1920);
    padding: 0 calc(100% * 140/1920);
    background: transparent;
    transform: translateY(-50%);
  }
}
.p-careertop-block1__text1__title {
  font-size: 32px;
  line-height: 1.5;
  @include mq('md', min) {
    font-size: 56px;
  }
}
.p-careertop-block1__text1__text {
  margin: 24px 0 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  @include mq('md', min) {
    margin: 32px 0 0;
    font-size: 24px;
  }
}

.p-careertop-block1__action1 {
  position: absolute;
  z-index: 1;
  bottom: 46px;
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0 8px;

  @include mq('md', min) {
    display: none;
  }
}

.p-careertop-block1__action1__button1 {
  width: calc(50% - 4px);

  .c-button15 {
    display: inline-block;
    border: none;
    padding: 8px;
    font-size: 13px;
    line-height: 1.5;

    &::after {
      display: none;
    }
  }
}

// effect
.swiper-slide {
  .p-careertop-block1__text1 {
    opacity: 0;
  }
  .p-careertop-block1__text1__title {
    opacity: 0;
  }
  .p-careertop-block1__text1__text {
    opacity: 0;
  }
  &.swiper-slide-active {
    .p-careertop-block1__text1 {
      opacity: 1;
      transition: opacity 1.2s ease-out 1.2s;
      @include mq('md', min) {
        transition: opacity 1.2s ease-out;
      }
    }
    .p-careertop-block1__text1__title {
      opacity: 1;
      transition: opacity 1.2s ease-out 1.2s;
    }
    .p-careertop-block1__text1__text {
      opacity: 1;
      transition: opacity 1.2s ease-out 1.8s;
    }
  }
}

.p-careertop-block1__text2 {
  padding: 40px 12px;
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
    padding: 40px 100px;
    box-sizing: content-box;
  }
}
.p-careertop-block1__text2__title {
  font-size: 24px;
  line-height: 1.5;
  @include mq('md', min) {
    font-size: 40px;
  }
}

//.swiper-slide-active .p-careertop-block1__image1 img,
//.swiper-slide-duplicate-active .p-careertop-block1__image1 img,
//.swiper-slide-prev .p-careertop-block1__image1 img {
//  animation: zoom-in 10s linear 0s 1 normal both;
//}
//@keyframes zoom-in {
//  0% {
//    transform: scale(1);
//  }
//  100% {
//    transform: scale(1.2);
//  }
//}