@use "../../global"as *;

.p-block18 {
  background: $neutral--100;
  padding: 40px 12px;

  @include mq('md', min) {
    padding: 120px 100px 22px;
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-block18__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block18__title1 {}

.p-block18__text1 {
  font-size: 16px;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
    font-size: $font-size-body;
  }
}

.p-block18__button1 {
  position: relative;
  z-index: 1;
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }

  &>a {
    display: block;

    @include mq('md', min) {
      display: inline-block;
    }
  }
}

.p-block18__map1 {
  position: relative;
  aspect-ratio: 375/236;
  background: url('../images/block18_img1_1.png') 0 0 / cover no-repeat;

  @include mq('md', min) {
    margin: -19.6% 0 0;
  }

  @include mq('2xl', min) {
    margin: -315px 0 0;
  }
}

.p-block18__list1 {
  position: absolute;
  top: 13.6vw;
  left: 0;
  right: 0;
  aspect-ratio: 217/117;
  width: 57.8vw;
  margin: auto;

  @include mq('md', min) {
    aspect-ratio: 920/500;
    width: 57.5%;
    top: 21.7%;
  }

  @include mq('2xl', min) {
    width: 920px;
    top: 217px;
  }

  li {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
    background: rgba($text-icon---primary--white, 0.56);
    border: 1px solid $neutral--300;
    border-radius: 100rem;
    aspect-ratio: 1/1;
    width: 14.9vw;
    display: grid;
    align-items: center;

    @include mq('md', min) {
      width: 26%;
      font-size: 2.5vw;
    }

    @include mq('2xl', min) {
      width: 240px;
      font-size: 40px;
    }

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--black;
    }

    &:nth-of-type(1) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:nth-of-type(2) {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:nth-of-type(3) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
