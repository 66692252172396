@use "../../global"as *;

.c-list3 {
  display: grid;
  gap: 16px;
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
}

.c-list3__item1 {}
