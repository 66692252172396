@use "../../global"as *;

.p-block26 {
  padding: 0 12px;
  margin: -80px 0 0;

  @include mq('md', min) {
    padding: 0 100px;
    margin: -80px 0 0;
  }

  @include mq('xl', min) {
    margin: -126px 0 0;
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $neutral--100;
    z-index: -1;

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }
}

.p-block26__inner1 {
  max-width: 1600px;
  margin: auto;
  position: relative;
  z-index: 1;

  @include mq('md', min) {
    min-width: 1000px;
  }
}

.p-block26__list1 {
  display: grid;
  gap: 12px;

  @include mq('md', min) {
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-block26__list1__item1 {}
