@use "../../global"as *;

.c-card3 {
  padding: 24px 12px;
  background: $text-icon---primary--black;
  color: $text-icon---primary--white;

  @include mq('md', min) {
    background: rgba($text-icon---primary--black, 0.64);
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.c-card3__title1 {
  display: grid;
  gap: 5px;
  text-align: center;
}

.c-card3__title1__mainLabel1 {
  font-size: 24px;
  line-height: 1.5;
}

.c-card3__title1__subLabel1 {
  font-size: 12px;
  line-height: 1.8;
}

.c-card3__list1 {
  display: grid;
  gap: 8px;
  margin: 16px 0 0;
}

.c-card3__list1__item1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $neutral--800;

  &.-type1 {
    padding: 24px;
  }

  &.-type2,
  &.-type3 {
    padding: 14px 24px;
  }
}

.c-card3__list1__item1__label1 {
  font-size: 12px;
  line-height: 1.8;
}

.c-card3__list1__item1__text1 {
  @at-root {
    .c-card3__list1__item1.-type1 & {
      font-size: 40px;
      font-family: $font-family-en;
      font-weight: 600;
      line-height: 0.9;
    }

    .c-card3__list1__item1.-type2 & {
      font-family: $font-family-en;
      font-size: 20px;
      line-height: 1;
      color: $danger--700;
      font-weight: 500;
    }

    .c-card3__list1__item1.-type3 & {
      font-family: $font-family-en;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 5px;

      &>span {
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }
}

.c-card3__text1 {
  text-align: center;
  font-size: 12px;
  line-height: 1.8;
  margin: 8px 0 0;
}

.c-card3__button1 {
  margin: 16px 0 0;
}
