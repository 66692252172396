@use "../../global"as *;

.c-card9 {
  display: block;
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);
  background: $text-icon---primary--white;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
    border: 1px solid $neutral--300;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &.-border {
    border: 1px solid $neutral--300;
  }
}

.c-card9__image1 {
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card9:hover>.c-card9__image1 img {
        transform: scale(1.1) rotate(2.5deg);
      }
    }

  }
}

.c-card9__main1 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 8px;

  @at-root {
    .c-card9.-border & {
      border-top: 1px solid $neutral--300;
    }
  }

  &::before {
    display: block;
    content: '';
    flex: 36px 0 0;
    width: 36px;
    height: 36px;

    @at-root {
      .c-card9__main1:has(.c-card9__fileName1.-pdf)::before {
        background: url('/assets/images/icon_pdf1_2.svg') 0 0 / cover no-repeat;

        @media (prefers-color-scheme: dark) {
          background: url('/assets/images/icon_pdf1_4.svg') 0 0 / cover no-repeat;
        }
      }

      .c-card9__main1:has(.c-card9__fileName1.-xls)::before {
        background: url('/assets/images/icon_xls1_1.svg') 0 0 / cover no-repeat;

        @media (prefers-color-scheme: dark) {
          background: url('/assets/images/icon_xls1_2.svg') 0 0 / cover no-repeat;
        }
      }

      .c-card9__main1:has(.c-card9__fileName1.-voice)::before {
        background: url('/assets/images/icon_play1_2.svg') 0 0 / cover no-repeat;

        @media (prefers-color-scheme: dark) {
          background: url('/assets/images/icon_play1_3.svg') 0 0 / cover no-repeat;
        }
      }
    }
  }

  &::after {
    display: block;
    content: '';
    flex: 24px 0 0;
    width: 24px;
    height: 24px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_download1_1.svg');
    margin: 0 0 0 auto;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }
}

.c-card9__fileName1 {
  display: grid;
  color: $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }
}

.c-card9__fileName1__mainLabel1 {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 600;
}

.c-card9__fileName1__subLabel1 {
  font-size: 12px;
  line-height: 1.33;
}
