@use "../../global"as *;

.p-heading4 {
  margin: 40px 0 0;
  padding: 0 12px;
  overflow: hidden;

  @include mq('md', min) {
    position: relative;
    padding: 0 100px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.p-heading4__inner1 {
  @include mq('md', min) {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 0 100px;
    box-sizing: content-box;
    margin: auto;
    z-index: 10;
  }
}

.p-heading4__image1 {
  position: relative;
  height: 280px;
  margin: 0 -12px;
  overflow: hidden;

  @include mq('md', min) {
    height: 370px;
    margin: 0 -100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-heading4__image1__labelJp1 {
  font-size: 40px;
  line-height: 1.5;
  font-weight: 900;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0 12px;
  z-index: 1;
  height: 100%;
  display: grid;
  align-items: center;
  color: $text-icon---primary--white;

  @include mq('md', min) {
    font-size: 64px;
    line-height: 1.5;
    padding: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 0 100px;
    box-sizing: content-box;
    right: 0;
    margin: auto;
  }
}
