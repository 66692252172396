@use "../../global"as *;

.c-title8 {}

.c-title8__mainLabel1 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 32px;
  }
}

.c-title8__title1 {
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    display: flex;
    gap: 12px;
  }
}

.c-title8__title1__number1 {
  font-size: 32px;
  line-height: 1;
  font-family: $font-family-en;

  @include mq('md', min) {
    line-height: 1.2;
    font-size: 40px;
  }
}

.c-title8__title1__mainLabel1 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 32px;
  }
}
