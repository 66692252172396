@use "../../global"as *;

.c-label1 {
  background: $neutral--700;
  color: $text-icon---primary--white;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.8;
  padding: 0 16px;
  display: inline-block;

  &.is-blue {
    background: $primary--700;
  }
}
