@keyframes arrow1 {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes arrow2 {
  0% {
    transform: translateY(0) rotate(90deg);
  }

  50% {
    transform: translateY(4px) rotate(90deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes arrow3 {
  0% {
    transform: translateY(0) rotate(-90deg);
  }

  50% {
    transform: translateY(-4px) rotate(-90deg);
  }

  100% {
    transform: translateY(0) rotate(-90deg);
  }
}

@keyframes arrow4 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes arrow5 {
  0% {
    transform: translateX(0) rotate(180deg);
  }

  50% {
    transform: translateX(-8px) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(180deg);
  }
}

@keyframes arrow6 {
  0% {
    transform: translateY(0) rotate(-180deg);
  }

  50% {
    transform: translateY(-4px) rotate(-180deg);
  }

  100% {
    transform: translateY(0) rotate(-180deg);
  }
}
