@use "../../global"as *;

.c-list10 {
  counter-reset: listnum;
  list-style: none;
  display: grid;
  gap: 24px;

  @include mq('md', min) {
    gap: 16px;
  }

  &.-large {
    @include mq('md', min) {
      gap: 24px;
    }
  }
}

.c-list10__item1 {
  display: grid;

  @include mq('md', min) {
    display: flex;
    gap: 16px;
    font-size: $font-size-body;
  }

  strong {
    flex: auto 0 0;
  }

  a {
    color: inherit;
  }
}

.c-list10__item1::before {
  counter-increment: listnum;
  content: "0"counter(listnum)".";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  font-family: $font-family-en;

  @include mq('md', min) {
    font-size: $font-size-body;
    transform: translateY(6px);
  }

  @at-root {
    .c-list10.-large .c-list10__item1::before {
      font-size: 24px;

      @include mq('md', min) {
        font-size: 24px;
        transform: translateY(5px);
      }
    }
  }

  // -type1
  @at-root {
    .c-list10.-type1 .c-list10__item1::before {
      content: counter(listnum)".";
    }
  }
}
