@use "../../global"as *;

.p-block15 {
  padding: 0 12px;
  margin: 40px 0 0;

  @include mq('md', min) {
    padding: 0 100px;
    margin: 72px 0 0;
  }
}

.p-block15__inner1 {}

.p-block15__title1 {
  @include mq('md', min) {
    max-width: 776px;
    margin: auto;
  }
}

.p-block15__text1 {
  margin: 40px 0 0;
  display: grid;
  gap: 32px;

  @include mq('md', min) {
    max-width: 776px;
    margin: 72px auto 0;
    font-size: $font-size-body;
  }

  &>p {
    &>span {
      color: $danger--700;
      font-weight: 700;
    }
  }
}

.p-block15__form1 {
  margin: 40px -12px 0;
  padding: 40px 12px 80px;
  background: $neutral--100;

  @include mq('md', min) {
    margin: 40px -100px 0;
    padding: 86px 100px 160px;
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-block15__form1__inner1 {
  @include mq('md', min) {
    max-width: 776px;
    margin: auto;
  }
}

.p-block15__form1__unit1 {
  margin: 24px 0 0;

  &:first-child {
    margin: 0;
  }
}

.p-block15__form1__unit1__label1 {}

.p-block15__form1__unit1__main1 {
  display: flex;
  gap: 16px;
  margin: 8px 0 0;
}

.p-block15__form1__title2 {
  margin: 48px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }
}

.p-block15__form1__privacy1 {
  padding: 24px 12px 40px;
  margin: 16px 0 0;
  background: $text-icon---primary--white;

  @include mq('md', min) {
    margin: 24px 0 0;
    padding: 24px 24px 40px;
  }
}

.p-block15__form1__privacy1__title1 {}

.p-block15__form1__privacy1__list1 {
  margin: 16px 0 0;
}

.p-block15__form1__checkbox1 {
  margin: 24px 0 0;
}

.p-block15__form1__button1 {
  display: grid;
  gap: 24px;
  margin: 48px 0 0;

  @include mq('md', min) {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 80px 0 0;
    text-align: center;
  }

  button {
    @include mq('md', min) {
      width: auto;
      display: inline-block;
    }
  }
}
