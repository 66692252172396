@use "../../global"as *;

.c-card17 {
  padding: 20px 20px 36px;
  background: $primary--700;
  color: $text-icon---primary--white;
  height: 100%;
  box-shadow: 0 8px 16px rgba($text-icon---primary--black, 0.12);

  @include mq('md', min) {
    padding: 20px 20px 30px;
  }
}

.c-card17__inner1 {}

.c-card17__title1 {
  font-size: 24px;
  text-align: center;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 40px;
  }

  &>span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin: -4px 0 0;

    @include mq('md', min) {
      margin: -8px 0 0;
    }
  }
}

.c-card17__main1 {
  margin: 33px 0 0;
  display: grid;
  gap: 48px;

  @include mq('md', min) {
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    gap: 0;
    max-width: 1086px;
  }
}

.c-card17__unit1 {
  &:nth-of-type(1) {
    @include mq('md', min) {
      width: 423px;
    }
  }

  &:nth-of-type(2) {
    @include mq('md', min) {
      width: 160px;
      transform: translateX(-24px);
    }
  }

  &:nth-of-type(3) {
    @include mq('md', min) {
      width: 373px;
    }
  }
}

.c-card17__unit1__image1 {
  img {
    margin: auto;

    @include mq('md', min) {
      width: auto;
      height: 80px;
    }
  }
}

.c-card17__unit1__title1 {
  font-size: 16px;
  text-align: center;
  margin: 20px 0 0;
  white-space: nowrap;

  @include mq('md', min) {
    margin: 37px 0 0;
  }
}

.c-card17__unit1__list1 {
  display: flex;
  justify-content: center;
  margin: 8px 0 0;

  @include mq('md', min) {
    margin: 17px 0 0;
  }

  &>li {
    flex: 1;
    display: grid;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: 1815px) {
      display: flex;
      align-items: baseline;
    }

    &:not(:has(:nth-of-type(2))) {
      @include mq('lg', min) {
        padding: 36px 0 0;
      }

      @media screen and (min-width: 1815px) {
        padding: 0;
      }
    }

    &>span {
      font-size: 16px;
      font-weight: 700;

      @include mq('md', min) {
        font-size: 24px;
        line-height: 1.5;
      }

      &:has(span) {
        line-height: 1;
        font-size: 48px;
        font-weight: 700;
        font-family: $font-family-en;

        @include mq('md', min) {
          font-size: 56px;
        }

        &>span {
          font-size: 32px;

          @include mq('md', min) {
            font-size: 36px;
          }
        }
      }
    }
  }
}
