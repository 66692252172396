@use "../../global"as *;

.c-card15 {
  padding: 20px 0 28px;
  box-shadow: 0 8px 16px rgba($text-icon---primary--black, 0.12);
  background: $text-icon---primary--white;
  height: 100%;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }

  &.-blue {
    background: $primary--700;
    color: $text-icon---primary--white;
  }
}

.c-card15__inner1 {}

.c-card15__title1 {
  font-size: 24px;
  text-align: center;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 32px;
  }
}

.c-card15__text1 {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 20px 0 0;

  @include mq('md', min) {
    font-size: 20px;
    margin: 10px 0 0;
  }

  @at-root {
    .c-card15__image1 + & {
      @include mq('md', min) {
        margin-top: 24px;
      }
    }
    .c-card15__text2 + & {
      margin-top: 0;
    }
  }
}

.c-card15__image1 {
  margin: 20px auto 0;
  width: 104px;

  @include mq('md', min) {
    margin: 34px auto 0;
  }

  &.-type1 {
    width: 88px;
  }

  &.-type2 {
    width: 274px;
    margin-top: 60px;
    margin-bottom: 47px;

    @include mq('md', min) {
      margin-bottom: 60px;
    }
  }

  &.-type3 {
    width: 80px;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  &.-type4 {
    width: 94px;
    margin-top: 58px;
    margin-bottom: 33px;

    @include mq('md', min) {
      width: 108px;
      margin-top: 45px;
      margin-bottom: 38px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.c-card15__text2 {
  text-align: center;
  margin: 29px 0 0;
  font-family: $font-family-en;
  font-weight: 700;
  font-size: 48px;
  line-height: 1;

  @include mq('md', min) {
    font-size: 64px;
    margin: 15px 0 0;
  }

  &>span {
    font-size: 32px;
    line-height: 1.12;

    @include mq('md', min) {
      font-size: 24px;
      line-height: 1.5;
    }
  }

  &.-type1 {
    font-size: 40px;
  }

  @at-root {
    .c-card15__text1 + & {
      margin-top: 8px;
    }
  }
}

.c-card15__list1 {
  display: flex;
  justify-content: center;
  margin: 8px 0 0;

  @include mq('md', min) {
    margin: 17px 0 0;
  }

  &>li {
    flex: 1;
    display: grid;
    gap: 10px;
    text-align: center;

    @include mq('lg', min) {
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    &:not(:has(:nth-of-type(2))) {
      @include mq('lg', min) {
        padding: 36px 0 0;
      }

      @media screen and (min-width: 1815px) {
        padding: 0;
      }
    }

    &>span {
      font-size: 16px;
      font-weight: 700;

      @include mq('md', min) {
        font-size: 24px;
        line-height: 1.5;
      }

      &:has(span) {
        line-height: 1;
        font-size: 48px;
        font-weight: 700;
        font-family: $font-family-en;

        @include mq('md', min) {
          font-size: 56px;
        }

        &>span {
          font-size: 32px;

          @include mq('md', min) {
            font-size: 36px;
          }
        }
      }
    }
  }
}