@use "../../global"as *;

.c-table2 {
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);

  table {
    border-collapse: collapse;
    width: 100%;

    @media (prefers-color-scheme: dark) {
      border: 1px solid $neutral--300;
    }
  }

  tr {
    border-top: 1px solid $neutral--300;

    @include mq('md', min) {

      &:first-child {
        border: none;
      }
    }

    &:first-child {
      border: none;
    }

    &:nth-of-type(odd) {
      background: $text-icon---primary--white;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--black;
      }
    }
    &:nth-of-type(even) {
      background: $neutral--100;

      @media (prefers-color-scheme: dark) {
        background: $neutral--900;
      }
    }
  }

  th,
  td {
    display: block;

    @include mq('md', min) {
      display: table-cell;
    }
  }

  th {
    font-size: 18px;
    line-height: 2;
    padding: 8px 12px;
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }

    @include mq('md', min) {
      width: 320px;
      text-align: left;
      padding: 20px 24px;
      font-size: 24px;
      line-height: 1.5;
    }
  }

  td {
    padding: 8px 12px 0;
    font-size: 16px;
    line-height: 1.8;
    display: grid;
    gap: 8px;

    @include mq('md', min) {
      padding: 24px;
      display: table-cell;
      font-size: $font-size-body;
    }

    &:nth-of-type(1) {
      padding-top: 16px;

      @include mq('md', min) {
        padding: 24px;
        width: 380px;
      }
    }

    &:last-child {
      padding-bottom: 16px;

      @include mq('md', min) {
        padding: 24px;
      }
    }
  }
}
