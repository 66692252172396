@use "../../global"as *;

.c-button7 {
  border: none;
  background: none;
  padding: 4px 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::after {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: rgba($text-icon---secondary--black, 0.8);
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transform: rotate(90deg);
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: rgba($text-icon---secondary--white, 0.8);
    }

    @at-root {
      .c-button7:hover::after {
        animation: arrow2 0.35s ease-out;
      }

      .c-button7.-active::after {
        transform: rotate(-90deg);
      }

      .c-button7.-active:hover::after {
        animation: arrow3 0.35s ease-out;
      }
    }
  }
}
