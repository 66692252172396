@use "../../global"as *;

.c-card16 {
  padding: 20px 10px 8px;
  box-shadow: 0 8px 16px rgba($text-icon---primary--black, 0.12);
  background: $text-icon---primary--white;
  height: 100%;

  @include mq('md', min) {
    padding: 22px 10px 6px;
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.c-card16__inner1 {}

.c-card16__title1 {
  font-size: 24px;
  text-align: center;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 32px;
  }
}

.c-card16__text1 {
  font-size: 16px;
  text-align: center;
  margin: 14px 0 0;

  @include mq('md', min) {
    font-size: 20px;
    margin: 4px 0 0;
  }

  br {
    @include mq('md', min) {
      display: none;
    }

    @include mq('3xl', min) {
      display: block;
    }
  }
}

.c-card16__image1 {
  margin: 15px auto 0;
  width: 83px;

  @include mq('md', min) {
    margin: 19px auto 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.c-card16__text2 {
  text-align: center;
  font-size: 32px;
  font-weight: 900;

  @include mq('md', min) {
    font-size: 40px;
    line-height: 1.5;
    margin: 6px 0 0;
  }
}

.c-card16__text3 {
  text-align: center;
  font-size: 12px;

  @include mq('md', min) {
    margin: 3px 0 0;
  }

  br {
    @include mq('md', min) {
      display: none;
    }

    @media screen and (min-width: 1815px) {
      display: block;
    }
  }
}
