@use "../../global"as *;

.p-block1 {
  padding: 0 12px;
  margin: 40px 0 0;

  @include mq('md', min) {
    padding: 0 100px;
  }

  &.is-darkmode {
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;
  }

  &.is-footer {
    margin: 80px 0 0;
    padding-top: 80px;
    padding-bottom: 80px;

    @include mq('md', min) {
      margin: 160px 0 0;
      padding-top: 40px;
      padding-bottom: 120px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }

  &.-gray {
    padding: 40px 12px 80px;
    background: $neutral--100;

    @include mq('md', min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }
}

.p-block1+* {
  margin-top: 40px !important;
}

.p-block1+.u-hidden-pc+* {
  @include mq('md', min) {
    margin-top: 40px;
  }
}

.p-block1__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
}

.p-block1__text1 {
  font-size: 16px;
  line-height: 1.8;

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}

.p-block1__button1 {
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 0;
  }

  &:first-child {
    margin: 0 0 0 auto;
  }
}
