@use "../../global"as *;

.c-textLink6 {
  color: $text-icon---primary--black;
  font-size: 16px;
  line-height: 1.8;
  gap: 8px;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  &:hover {
    text-decoration: none;
  }
}