@use "../../global"as *;

.c-list13 {
  list-style: none;

  &.-type1 {
    position: relative;
  }
}

.c-list13__item1 {
  @include mq('md', min) {
    position: relative;
    padding-left: 3em;
    font-size: $font-size-body;
  }

  strong {
    flex: auto 0 0;
  }

  a {
    color: inherit;
  }

  &::before {
    @include mq('md', min) {
      position: absolute;
      left: 0;
    }
  }

  &:nth-of-type(1)::before {
    content: "（１）";
  }

  &:nth-of-type(2)::before {
    content: "（２）";
  }

  &:nth-of-type(3)::before {
    content: "（３）";
  }

  &:nth-of-type(4)::before {
    content: "（４）";
  }

  &:nth-of-type(5)::before {
    content: "（５）";
  }

  &:nth-of-type(6)::before {
    content: "（６）";
  }

  &:nth-of-type(7)::before {
    content: "（7）";
  }

  &:nth-of-type(8)::before {
    content: "（8）";
  }

  &:nth-of-type(9)::before {
    content: "（9）";
  }

  // -type1
  @at-root {
    .c-list13.-type1 & {
      padding-left: 1em;

      &::before {
        position: absolute;
        left: 0;
      }

      &:nth-of-type(1)::before {
        content: "①";
      }

      &:nth-of-type(2)::before {
        content: "②";
      }

      &:nth-of-type(3)::before {
        content: "③";
      }

      &:nth-of-type(4)::before {
        content: "④";
      }

      &:nth-of-type(5)::before {
        content: "⑤";
      }

      &:nth-of-type(6)::before {
        content: "⑥";
      }

      &:nth-of-type(7)::before {
        content: "⑦";
      }

      &:nth-of-type(8)::before {
        content: "⑧";
      }

      &:nth-of-type(9)::before {
        content: "⑨";
      }
    }
  }
}
