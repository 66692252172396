@use "../../global"as *;

.c-card21 {
  display: block;
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    top: 24px;
    right: 12px;
    border-radius: 10rem;
    border: 1px solid $text-icon---primary--white;
    z-index: 10;
    pointer-events: none;
    transition: all 0.7s $easeOutQuint;

    @include mq('md', min) {
      right: 24px;
    }

    @at-root {
      .c-card21:hover::before {
        background: $text-icon---primary--white;
      }
    }
  }

  &::after {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 36px;
    right: 24px;
    background: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    z-index: 10;
    transition: all 0.7s $easeOutQuint;

    @include mq('md', min) {
      right: 36px;
    }

    @at-root {
      .c-card21:hover::after {
        animation: arrow1 0.35s ease-out;
        background: $text-icon---primary--black;
      }
    }
  }
}

.c-card21__image1 {
  display: block;
  overflow: hidden;
  aspect-ratio: 1/1;

  @include mq('md', min) {
    aspect-ratio: 504/252;
  }

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card21:hover .c-card21__image1>img {
        transform: scale(1.1) rotate(2.5deg);
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba($text-icon---primary--black, 0.24);
  }

  &.-type1 {
    &::before {
      background: rgba($text-icon---primary--black, 0.32);
    }
  }

  &.-type2 {
    &::before {
      background: rgba($text-icon---primary--black, 0.24);
    }
  }

  &.-type3 {
    &::before {
      background: rgba($text-icon---primary--black, 0.2);
    }
  }
}

.c-card21__text1 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 24px 12px;
  display: grid;
  color: $text-icon---disabled--white;
  font-weight: 600;
  gap: 16px;

  @include mq('md', min) {
    padding: 16px;
    gap: 8px;
  }

  @include mq('lg', min) {
    padding: 24px;
  }

  @include mq('3xl', min) {
    padding: 40px;
  }
}

.c-card21__text1__mainLabel1 {
  font-size: 32px;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 20px;
  }

  @include mq('3xl', min) {
    font-size: 32px;
  }
}

.c-card21__text1__subLabel1 {
  font-size: 16px;
  line-height: 1.8;

  @include mq('md', min) {
    font-size: 12px;
  }

  @include mq('3xl', min) {
    font-size: 16px;
  }
}
