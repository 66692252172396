@use "../../global"as *;

.c-title7 {}

.c-title7__mainLabel1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--black;
  }
}
