@use "../../global"as *;

// bg1
.p-kih-bg1 {
  background: $neutral--1000 url('/assets/images/img_learn_kih_1.jpg') 0 0 / 100% auto no-repeat;
  @include mq('md', min) {
    background: $neutral--1000 url('/assets/images/img_learn_kih_1_desktop.jpg') 0 0 / 100% auto no-repeat;
  }
}

// heading1
.p-kih-heading1 {
  padding: 160px 12px 240px;
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 240px 100px 800px;
    box-sizing: content-box;
    margin: auto;
  }
}
.p-kih-heading1__title {
  color: $text-icon---primary--white;
  font-size: 20px;
  font-weight: 400;
  @include mq('md', min) {
    font-size: 40px;
  }
}
.p-kih-heading1__lead {
  margin: 160px 0 0;
  color: $text-icon---primary--white;
  font-size: 48px;
  line-height: 1.5;
  font-weight: 900;
  @include mq('md', min) {
    margin: 320px 0 0;
    font-size: 136px;
    line-height: 1.25;
  }
}
.p-kih-heading1__text {
  margin: 160px 0 0;
  color: $text-icon---primary--white;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 900;
  @include mq('md', min) {
    margin: 320px 0 0;
    font-size: 64px;
    line-height: 1.875;
  }
}

// block1
.p-kih-block1 {
  @include mq('md', min) {}
  &.-type1 {
    background: #123352 url('/assets/images/img_learn_kih_4.jpg') 0 0 / 100% auto no-repeat;
    @include mq('md', min) {
      background: #123352 url('/assets/images/img_learn_kih_4_desktop.jpg') 0 0 / 100% auto no-repeat;
    }
  }
  &.-type2 {
    background: $neutral--1000 url('/assets/images/img_learn_kih_5.jpg') 0 100% / 100% auto no-repeat;
    @include mq('md', min) {
      background: $neutral--1000 url('/assets/images/img_learn_kih_5_desktop.jpg') 0 100% / 100% auto no-repeat;
    }
  }
  &.-type3 {
    background: $neutral--800;
  }
}
.p-kih-block1__inner {
  padding: 80px 12px 120px;
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 160px 100px 120px;
    box-sizing: content-box;
    margin: auto;
  }
  @at-root {
    .p-kih-heading1 + .p-kih-block1 {
      .p-kih-block1__inner {
        padding-top: 0;
      }
    }
    .p-kih-block1.-type1 {
      .p-kih-block1__inner {
        margin-bottom: 0;
        padding-bottom: 400px;
        @include mq('md', min) {
          padding-bottom: 520px;
        }
      }
    }
    .p-kih-block1.-type2 {
      .p-kih-block1__inner {
        padding-bottom: 480px;
        @include mq('md', min) {
          padding-bottom: 1200px;
        }
      }
    }
    .p-kih-block1.-type3 {
      .p-kih-block1__inner {
        @include mq('md', min) {
          padding-bottom: 200px;
        }
      }
    }
    .p-kih-block1.-type4 {
      .p-kih-block1__inner {
        @include mq('md', min) {
          padding-bottom: 336px;
        }
      }
    }
  }
}

.p-kih-block1__title1 {
  color: $text-icon---primary--white;
}
.p-kih-block1__title1__num {
  display: block;
  font-size: 160px;
  line-height: 1;
  font-family: $font-family-en;
  @include mq('md', min) {
    margin-bottom: 16px;
    font-size: 360px;
  }
}
.p-kih-block1__title1__text {
  display: block;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 900;
  @include mq('md', min) {
    font-size: 64px;
    line-height: 1.875;
  }
}

.p-kih-block1__title2 {
  margin: 160px 0 0;
  color: $text-icon---primary--white;
  font-size: 32px;
  line-height: 1.5;

  @include mq('md', min) {
    margin: 320px 0 0;
    font-size: 64px;
    line-height: 1.875;
  }
  &:first-child {
    margin-top: 0;
  }
  &.-type1 {
    margin: 60px 0 0;
    @include mq('md', min) {
      margin: 130px 0 0;
    }
  }
  &.-type2 {
    margin: 80px 0 0;
    @include mq('md', min) {
      margin: 238px 0 0;
    }
    font-weight: 900;
  }
}

.p-kih-block1__title3 {
  color: $text-icon---primary--white;
  font-size: 24px;
  line-height: 1.5;
  @include mq('md', min) {
    font-size: 40px;
    line-height: 1.2;
  }
}

.p-kih-block1__title4 {
  margin: 60px 0 0;
  color: $text-icon---primary--white;
  text-align: center;
  font-size: 40px;
  line-height: 1.5;
  @include mq('md', min) {
    margin: 103px 0 0;
    font-size: 96px;
    line-height: 1;
  }
}

.p-kih-block1__title5 {
  margin: 80px 0 0;
  color: $text-icon---primary--white;
  font-size: 32px;
  line-height: 1.5;
  @include mq('md', min) {
    font-size: 64px;
    line-height: 1.25;
  }
}

.p-kih-block1__title6 {
  margin: 80px 0 0;
  color: $text-icon---primary--white;
}

.p-kih-block1__title7 {
  margin: 60px 0 0;
  color: $text-icon---primary--white;
  font-size: 40px;
  line-height: 1;
  @include mq('md', min) {
    margin: 130px 0 0;
    font-size: 96px;
  }
  &:first-child {
    margin-top: 0;
  }
}

.p-kih-block1__title8 {
  margin: 40px 0 0;
  color: $text-icon---primary--white;
  @include mq('md', min) {
    margin-top: 64px;
  }
}

.p-kih-block1__title9 {
  margin: 40px 0 0;
  color: $text-icon---primary--white;
}

.p-kih-block1__text1 {
  margin-top: 32px;
  color: $text-icon---primary--white;
  font-size: 16px;
  line-height: 1.8;
  @include mq('md', min) {
    font-size: 24px;
  }
}

.p-kih-block1__image1 {
  margin: 160px 0 0;
  text-align: center;
  @include mq('md', min) {
    margin: 320px 0 0;
  }
  img {
    display: inline;
    max-width: 100%;
    height: auto;
  }

  &.-type1 {
    margin-top: 40px;
  }

  &.-type2 {
    margin-top: 80px;
  }

}

.p-kih-block1__row1 {
  flex-wrap: wrap;
  @include mq('md', min) {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
}
.p-kih-block1__row1__unit {
  margin: 20px 0 0;
  padding: 32px 12px;
  border: 4px solid $neutral--0;
  @include mq('md', min) {
    width: calc(50% - 20px);
    margin: 40px 0 0;
    padding: 64px;
  }
  *:first-child {
    @include mq('md', min) {
      margin-top: 0;
    }
  }
}

.p-kih-block1__list1 {
  counter-reset: listnum;
  list-style: none;
  display: grid;
  gap: 24px;
  color: $text-icon---primary--white;
  font-size: 24px;
  font-weight: 700;

  @include mq('md', min) {
    font-size: 40px;
  }
}

.p-kih-block1__list1__item1 {
  display: grid;

  @include mq('md', min) {
    display: flex;
    gap: 16px;
  }

  strong {
    flex: auto 0 0;
  }

  a {
    color: inherit;
  }
}

.p-kih-block1__list1__item1::before {
  counter-increment: listnum;
  content: "0"counter(listnum)".";
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  font-family: $font-family-en;

  @include mq('md', min) {
    font-size: 56px;
    transform: translateY(0);
  }
}

.p-kih-block1__list2 {
  margin-top: 16px;
  color: $text-icon---primary--white;
  @include mq('md', min) {
    margin-top: 24px;
    font-size: 24px;
  }
  .c-list12 {
    color: $text-icon---primary--white;
  }
}

.p-kih-block1__movie1 {
  //overflow: hidden;
  //position: relative;
  //height: 0;
  margin: 160px 0 0;
  @include mq('md', min) {
    margin: 320px 0 0;
  }
  iframe {
    //position: absolute;
    //top: 0;
    //left: 0;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
  }
}

.p-kih-block1__iframe1 {
  margin-top: 32px;
  iframe {
    //position: absolute;
    //top: 0;
    //left: 0;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
  }
}

.p-kih-block1__button1 {
  margin: 40px 0 0;
  @include mq('md', min) {
    margin: 80px 0 0;
  }
}

.p-kih-block1__button1__button {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  color: $neutral--700;
  text-decoration: none;
  text-align: center;
  background: $text-icon---primary--white;
  border: 1px solid $text-icon---primary--white;
  border-radius: 10rem;
  padding: 9px 40px 9px 32px;
  line-height: 1.8;
  transition: all 0.7s $easeOutQuint;

  @include mq('md', min) {
    width: auto;
    font-size: 40px;
    padding: 23px 104px 23px 64px;
    border-width: 4px;
  }

  &:hover {
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto;
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    background: $text-icon---primary--black;
    mask-image: url("/assets/images/icon_arrow1_1.svg");
    mask-size: cover;
    transition: all 0.7s $easeOutQuint;
    @include mq('md', min) {
      width: 72px;
      height: 72px;
      right: 16px;
    }
    @at-root {
      .p-kih-block1__button1__button:hover::after {
        background: $text-icon---primary--white;
        animation: arrow1 0.35s ease-out;
      }
    }
  }
}

.p-kih-block1__button2 {
  display: block;
  margin-top: 24px;
  @include mq('md', min) {
    margin-top: 90px;
  }
  .c-button5,
  .c-button15 {
    display: block;
    @include mq('md', min) {
      display: inline-block;
      width: auto;
      min-width: 169px;
    }
  }
}
