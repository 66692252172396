@use "../../global"as *;

.c-card19 {
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 20px;
}

.c-card19__image1 {
  figcaption {
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    margin: 7px 0 0;
    @include mq('md', min) {
      font-size: $font-size-body;
    }
  }
}

.c-card19__text1 {
  &>p {
    font-size: 16px;
    @include mq('md', min) {
      font-size: $font-size-body;
    }
  }
}
