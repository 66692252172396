@use "../../global"as *;

.c-textLink3 {
  color: $text-icon---primary--black;
  font-size: 16px;
  line-height: 1.8;
  display: flex;
  gap: 8px;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  &:hover {
    text-decoration: none;
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    background: url('/assets/images/icon_pdf1_1.svg') 0 0 / cover no-repeat;
    margin: 3px 0 0;

    @media (prefers-color-scheme: dark) {
      background: url('/assets/images/icon_pdf1_3.svg') 0 0 / cover no-repeat;
    }

    @include mq('md', min) {
      margin: 6px 0 0;
    }
  }

  &.-play {
    &::before {
      background: url('/assets/images/icon_play1_4.svg') 0 0 / cover no-repeat;

      @media (prefers-color-scheme: dark) {
        background: url('/assets/images/icon_play1_3.svg') 0 0 / cover no-repeat;
      }
    }
  }

  &.-xls {
    &::before {
      background: url('/assets/images/icon_xls1_3.svg') 0 0 / cover no-repeat;

      @media (prefers-color-scheme: dark) {
        background: url('/assets/images/icon_xls1_2.svg') 0 0 / cover no-repeat;
      }
    }
  }
}
