@use "../../global"as *;

.p-block13 {
  background: $neutral--100;
  padding: 40px 12px 80px;
  margin: 80px 0 0;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    margin: 160px 0 0;
    padding: 120px 100px 160px;
  }

  &.-white {
    padding: 0 12px;
    margin: 40px 0 0;
    background: $text-icon---primary--white;

    @include mq('md', min) {
      margin: 120px 0 0;
      padding: 0 100px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--1000;
    }
  }

  //+ .p-block13 {
  //  margin-top: 0;
  //  padding-top: 0;
  //}

  @at-root {
    .p-block12:not(.-white) + .p-block13:not(.-white) {
      margin-top: 0;
      padding-top: 0;
    }
    .p-block13:not(.-white) + .p-block13:not(.-white) {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.p-block13__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block13__title1 {}

.p-block13__title2 {
  margin: 48px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }

  @at-root {
    .p-block13__title1+& {
      margin: 40px 0 0;

      @include mq('md', min) {
        margin: 48px 0 0;
      }
    }
  }
}

.p-block13__title3 {
  margin: 48px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }
}

.p-block13__list1 {
  margin: 24px 0 0;
  display: grid;
  gap: 24px;

  @include mq('md', min) {
    margin: 32px 0 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }

  &:first-child {
    margin-top: 0;
  }

  &.-col1 {
    @include mq('md', min) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.-col4 {
    @include mq('md', min) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.p-block13__list2 {
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 32px 0 0;
  }
  .c-list12 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

.p-block13__list1__item1 {
  @at-root {
    .p-block13__list1.-col1 & {
      display: inline-flex;
    }
  }
}

.p-block13__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 32px 0 0;

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}

.p-block13__iframe1 {
  margin: 48px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }
}

.p-block13__button1 {
  display: block;
  margin-top: 24px;
  @include mq('md', min) {
    margin-top: 40px;
  }
  + .p-block13__button1 {
    margin-top: 10px;
  }
  .c-button15 {
    display: block;
    @include mq('md', min) {
      display: inline-block;
      width: auto;
      min-width: 169px;
    }
  }
}