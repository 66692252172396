@use "../../global" as *;

.p-keyVisual1 {
}

.p-keyVisual1__inner1 {
}

.p-keyVisual1__swiper1 {
  .swiper {
  }

  .swiper-wrapper {
  }

  .swiper-slide {
  }

  .swiper-pagination {
    position: absolute;
    bottom: 100px;
    line-height: 0;
    margin: 0;

    @include mq("md", min) {
      bottom: 166px;
    }
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    background: $text-icon---primary--white;
    opacity: 0.32;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: $text-icon---primary--white;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    width: 68px;
    height: 68px;
    border-radius: 10rem;
    transition: all 0.7s $easeOutQuint;

    @include mq("md", min) {
      display: block;
    }

    &:hover {
      animation: arrow1 0.35s ease-out;
    }

    &::after {
      display: none;
    }

    &::before {
      display: block;
      content: "";
      width: 68px;
      height: 68px;
      background: $text-icon---primary--white;
      mask-image: url("../images/icon_arrow1_1.svg");
      mask-size: cover;
    }
  }

  .swiper-button-prev {
    rotate: 180deg;
  }

  .swiper-button-disabled {
    opacity: 0;
  }
}

.p-keyVisual1__unit1 {
  position: relative;

  @include mq("md", min) {
    max-height: calc(150vh - 80px);
  }
}

.p-keyVisual1__unit1__image1 {
  width: 100vw;

  @include mq("md", min) {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($text-icon---primary--black, 0.16);
  }

  &.-type1 {
    &::before {
      background: rgba($text-icon---primary--black, 0);
    }
  }

  &.-type2 {
    &::before {
      background: rgba($text-icon---primary--black, 0);
    }
  }

  &.-type3 {
    &::before {
      background: rgba($text-icon---primary--black, 0.24);
    }
  }

  &.-type4 {
    &::before {
      background: rgba($text-icon---primary--black, 0.24);
      @include mq("md", min) {
        background: rgba($text-icon---primary--black, 0);
      }
    }
  }
  &.-type5 {
    &::before {
      background: rgba($text-icon---primary--black, 0.16);
    }
  }
  &.-type6 {
    &::before {
      background: rgba($text-icon---primary--black, 0.24);
      @include mq("md", min) {
        background: rgba($text-icon---primary--black, 0.32);
      }
    }
  }
}

.p-keyVisual1__unit1__wrap1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  padding: 0 12px 80px;

  @include mq("md", min) {
    padding: 0 100px 80px;
  }

  @include mq("xl", min) {
    padding-bottom: 126px;
  }
}

.p-keyVisual1__unit1__wrap1__inner1 {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.p-keyVisual1__unit1__label1 {
}

.p-keyVisual1__unit1__title1 {
  font-weight: 900;
  font-size: 40px;
  color: $text-icon---primary--white;
  margin: 20px 0 0;
  line-height: 1.5;

  @include mq("md", min) {
    font-size: 64px;
    margin: 40px 0 0;
  }
}

.p-keyVisual1__unit1__text1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
  color: $text-icon---primary--white;
  margin: 20px 0 0;

  @include mq("md", min) {
    font-size: 24px;
    margin: 40px 0 0;
  }
}

.p-keyVisual1__unit1__button1 {
  position: relative;
  margin: 20px 0 0;
  z-index: 1;

  @include mq("md", min) {
    margin: 40px 0 0;
  }

  .c-button20 {
    @include mq("md", min) {
      display: inline-block;
    }
  }
}

.p-keyVisual1__unit1__layer1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
