@use "../../global"as *;

.p-block27 {
  padding: 40px 12px 60px;
  background: $neutral--100;

  @include mq('md', min) {
    padding: 80px 100px 160px;
  }

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-block27__inner1 {

  @include mq('md', min) {
    position: relative;
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block27__title1 {}

.p-block27__title2 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }
}

.p-block27__list1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }
}

.p-block27__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
    font-size: $font-size-body;
  }
}

.p-block27__button1 {
  margin: 24px 0 0;

  @include mq('md', min) {
    position: absolute;
    top: 18px;
    right: 0;
    margin: 0;
  }
}
