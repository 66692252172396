@use "../../global"as *;

.c-formSelect3 {
  position: relative;
  border: 2px solid $text-icon---primary--black;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
    border-color: $text-icon---primary--white;
  }

  &>select {
    appearance: none;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 600;
    width: 100%;
    border: none;
    background: none;
    padding: 6px 40px 6px 16px;
  }

  &::before {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transform: rotate(90deg);
    pointer-events: none;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }
}
