@use "../../global"as *;

.p-block16 {
  margin: 40px 0 0;
  padding: 0 12px;

  @include mq('md', min) {
    margin: 140px 0 0;
    padding: 0 100px;
  }

  &.-gray {
    margin: 0;
    padding: 40px 12px 80px;
    background: $neutral--100;

    @include mq('md', min) {
      margin: 0;
      padding: 140px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }

  @at-root .p-block9.-gray + .p-block16.-gray {
    padding-top: 0;
  }
}

.p-block16__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block16__title1 {}

.p-block16__title2 {
  padding: 48px 0 20px;

  @include mq('md', min) {
    padding: 0;
    margin: 108px 0 40px;
  }
}

.p-block16__tabs {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }

  @at-root .p-block9.-gray + .p-block16.-gray & {
    margin-top: 0;
  }
}

.p-block16__button1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
    text-align: right;
  }

  &>button {
    @include mq('md', min) {
      display: inline-block;
      width: auto;
    }
  }

  .p-block16__tabs + & {
    @include mq('md', min) {
      margin: 0;
    }
  }

  + .p-block16__list1 {
    margin-top: 20px;

    @include mq('md', min) {
      margin-top: 40px;
    }
  }
}

.p-block16__list1 {}

.p-block16__list1__item1 {
  +.p-block16__list1__item1 {
    margin: 20px 0 0;

    @include mq('md', min) {
      margin: 40px 0 0;
    }
  }
}

.p-block16__tabBody1 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 80px 0 0;
  }
}

.p-block16__tabBody1__unit1 {
  display: none;

  &.-active {
    display: block;
  }
}
