@use "../../global"as *;

.p-block29 {
  aspect-ratio: 375/667;

  @include mq('md', min) {
    aspect-ratio: 1920/1080;
  }

  &.is-career {
    padding: 40px 12px 0;
    background: url('../images/block29_bg1_1.jpg') 0 0 / cover no-repeat;

    @include mq('md', min) {
      padding: 120px 100px 0;
      background: url('../images/block29_bg1_1_desktop.jpg') 0 0 / cover no-repeat;
    }
  }

  &.is-roadmap {
    padding: 0 12px 40px;
    background: url('../images/block29_bg2_1.jpg') 0 0 / cover no-repeat;

    @include mq('md', min) {
      padding: 120px 100px 0;
      background: url('../images/block29_bg2_1_desktop.jpg') 0 0 / cover no-repeat;
    }
  }
}

.p-block29__inner1 {
  @at-root {
    .p-block29.is-roadmap & {
      @include mq('md', max) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.p-block29__title1 {
  @at-root {
    .p-block29.is-roadmap & {
      @include mq('md', max) {
        order: 1;
      }

      .c-title1__mainLabel1 {
        font-size: 40px;
        font-weight: 900;
      }
    }
  }
}

.p-block29__text1 {
  color: $text-icon---primary--white;
  font-size: 16px;
  line-height: 1.8;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
    font-size: $font-size-body;
  }

  @at-root {
    .p-block29.is-roadmap & {
      @include mq('md', max) {
        display: none;
      }
    }
  }
}

.p-block29__button1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }

  @at-root {
    .p-block29.is-career & {
      @include mq('md', min) {
        width: 169px;
      }
    }

    .p-block29.is-roadmap & {
      @include mq('md', min) {
        width: 200px;
      }

      @include mq('md', max) {
        order: 3;
        margin-top: 20px;
      }
    }
  }
}

.p-block29__text2 {
  color: $text-icon---primary--white;
  font-size: 24px;
  margin: 20px 0 0;
  font-weight: 700;

  @include mq('md', min) {
    margin: 203px 0 0;
    font-size: 80px;
    font-weight: 900;
  }

  @at-root {
    .p-block29.is-roadmap & {
      @include mq('md', max) {
        order: 2;
      }
    }
  }
}
