@use "../../global"as *;

.c-card6 {
  padding: 24px 12px;
  border: 1px solid $neutral--300;
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);
  background: $text-icon---primary--white;

  @include mq('md', min) {
    padding: 32px;
    height: 100%;
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.c-card6__title1 {
  font-size: 24px;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  align-items: center;

  @include mq('md', min) {
    font-size: 32px;
  }

  >a {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: -10px 0 ;
    padding: 10px 8px;
    color: $text-icon---primary--black;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.7s $easeOutQuint;
    text-decoration: underline;

    @include mq('md', min) {
      padding: 10px 16px;
    }

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;
      text-decoration: none;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::before {
      display: block;
      flex: 0 0 24px;
      content: '';
      width: 24px;
      height: 24px;
      background: $text-icon---primary--black;
      mask-image: url('/assets/images/icon_external1_1.svg');
      transition: all 0.7s $easeOutQuint;

      @at-root {
        .c-card6__title1 > a:hover::before {
          animation: arrow1 0.35s ease-out;
        }
      }

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }
    }
  }
}

.c-card6__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 16px 0 0;

  @include mq('md', min) {
    margin: 24px 0 0;
    font-size: $font-size-body;
  }

  //br {
  //  display: none;

  //  @include mq('md', min) {
  //    display: block;
  //  }
  //}

  span {
    display: none;

    @include mq('md', min) {
      display: inline-block;
    }
  }
}

.c-card6__button1 {
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 40px 0 0;
  }
  .c-button15 {
    display: block;
    width: 100%;
  }
}
