@use "../../global" as *;

.p-block11 {
  margin: 40px 0 0;
  padding: 0 12px;

  @include mq("md", min) {
    margin: 120px 0 0;
    padding: 0 100px;
  }

  &.-gray {
    padding: 40px 12px 80px;
    background: $neutral--100;

    @include mq("md", min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }

    + .p-block11.-gray,
    + .p-block11.-blue,
    + .p-block7.-gray,
    + .p-block7.-blue {
      margin-top: 0;
    }
  }
  @at-root {
    .p-interview-block4 + .p-block11.-gray {
      margin-top: 0;
    }
    .p-block12 + .p-block11.-gray {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &.-blue {
    padding: 40px 12px 80px;
    background: $primary--700;

    @include mq("md", min) {
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
      border-top: 1px solid $neutral--300;
    }

    &:not(.u-hidden-md) + .p-block11.-gray,
    &:not(.u-hidden-md) + .p-block11.-blue,
    &:not(.u-hidden-md) + .p-block7.-gray,
    &:not(.u-hidden-md) + .p-block7.-blue {
      @include mq("md", min) {
        margin-top: 0;
      }
    }

    &:not(.u-hidden-sp) + .p-block11.-gray,
    &:not(.u-hidden-sp) + .p-block11.-blue,
    &:not(.u-hidden-sp) + .p-block7.-gray,
    &:not(.u-hidden-sp) + .p-block7.-blue {
      @include mq("md", max) {
        margin-top: 0;
      }
    }
  }

  .c-list12 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

.p-block11__inner1 {
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block11__title1 {
}

.p-block11__title2 {
  margin: 80px 0 0;

  @include mq("md", min) {
    margin: 120px 0 0;
  }
}

.p-block11__title3 {
  margin: 48px 0 0;

  @include mq("md", min) {
    margin: 80px 0 0;
  }
}

.p-block11__title4 {
  margin: 24px 0 0;

  @include mq("md", min) {
    margin: 24px 0 0;
  }
}

.p-block11__title5 {
  margin: 40px 0 0;

  @include mq("md", min) {
    margin: 48px 0 0;
  }
}

.p-block11__title6 {
  margin: 40px 0 0;

  @include mq("md", min) {
    margin: 64px 0 0;
  }
}

.p-block11__title7 {
  margin: 40px 0 0;

  @include mq("md", min) {
    margin: 32px 0 0;
  }
}

.p-block11__title8 {
  margin: 8px 0 0;

  @include mq("md", min) {
    margin: 16px 0 0;
  }
}

.p-block11__table1 {
  margin: 40px 0 0;

  @include mq("md", min) {
    margin: 48px 0 0;
  }
}

.p-block11__table2 {
  margin: 24px 0 0;

  @include mq("md", min) {
    margin: 40px 0 0;
  }
}

.p-block11__table3 {
  margin: 24px 0 0;

  @include mq("md", min) {
    margin: 40px 0 0;
  }
}

.p-block11__table4 {
  margin: 24px 0 0;

  @include mq("md", min) {
    margin: 40px 0 0;
  }
}

.p-block11__table4Caption1 {
  margin: 24px 0 -24px;
  text-align: right;
  font-size: 16px;

  @include mq("md", min) {
    margin: 40px 0 -40px;
    font-size: $font-size-body;
  }
}

.p-block11__table5 {
  margin: 24px 0 0 0;

  @include mq("md", min) {
    margin: 40px 0 0;
  }

  &:has(.c-table5.-scroll) {
    margin-right: -12px;

    @include mq("md", min) {
      margin-right: 0;
    }
  }
}

.p-block11__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 32px 0 0;

  @include mq("md", min) {
    font-size: $font-size-body;
  }
  &.-right {
    text-align: right;
  }
  &.-large {
    font-size: 20px;
    @include mq("md", min) {
      font-size: 24px;
    }
  }
  &.-xlarge {
    font-size: 20px;
    @include mq("md", min) {
      font-size: 32px;
    }
  }
  &.-small {
    @include mq("md", min) {
      font-size: 12px;
    }
  }
  &.-paragraph {
    margin-top: 32px;
    @include mq("md", min) {
      margin-top: 64px;
    }
  }
  &.-first {
    margin-top: 40px;
    @include mq("md", min) {
      margin-top: 48px;
    }
  }
  &.-mt0 {
    margin-top: 0;
  }
  &.-mt80 {
    margin-top: 80px;
  }
  &.-mt120 {
    margin-top: 120px;
  }
  &.-white {
    color: $text-icon---primary--white;
  }
  @at-root {
    .p-block11__title4 + {
      .p-block11__text1 {
        margin-top: 24px;
        @include mq("md", min) {
          margin-top: 16px;
        }
      }
    }
  }
}

.p-block11__text2 {
  font-size: 72px;
  line-height: 1;
  font-family: $font-family-en;
  text-align: center;
  font-weight: 900;
  margin: 48px 0 0;
  @include mq("md", min) {
    font-size: 96px;
    margin: 0 0 0;
  }
  .-num {
    font-size: 120px;
    @include mq("md", min) {
      font-size: 160px;
    }
  }
}

.p-block11__notes1 {
  margin: 32px 0 0;
  font-size: 12px;

  @include mq('md', min) {
    font-size: $font-size-note;
  }
  > li {
    position: relative;
    padding-left: 1.25em;
    &::before {
      content: "*";
      position: absolute;
      left: 0;
      display: inline-block;
    }
  }
}

.p-block11__notes2 {
  margin: 32px 0 0;

  @include mq('md', min) {
    font-size: $font-size-note;
  }
}

.p-block11__list1 {
  margin-top: 24px;
  @include mq("md", min) {
    margin-top: 24px;
  }

  .c-list12 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

.p-block11__list2 {
  margin: 32px 0 0;
  .c-list12 {
    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

.p-block11__image1 {
  margin: 40px 0 0;
  @include mq("md", min) {
    width: 1200px;
    margin: 48px auto 0;
  }
  img {
    width: 100%;
    height: auto;
  }
  &.-wmax {
    @include mq("md", min) {
      width: 100%;
    }
  }
  &.-white {
    background: $text-icon---primary--white;
  }
  &.-gray {
    background: $neutral--100;
  }
}

.p-block11__image2 {
  margin: 40px 0 0;
  @include mq("md", min) {
    width: 100%;
    margin: 64px auto 0;
  }
  img {
    width: 100%;
    height: auto;
  }
  &:first-child {
    margin-top: 0;
  }
  &.-type1 {
    text-align: center;
    img {
      @include mq("md", min) {
        display: inline-block;
        width: 796px;
        max-width: 100%;
      }
    }
  }
}

.p-block11__image3 {
  @include mq("md", min) {
    display: flex;
    gap: 24px;
  }
}

.p-block11__image3__item {
  @include mq("md", min) {
    width: calc(50% - 12px);
  }
  img {
    width: 100%;
    height: auto;
  }

  .p-block11__image2 {
    margin: 32px 0 0;
  }
}

.p-block11__image3__text1 {
  margin-top: -1.5em;
  font-size: 16px;
  text-align: center;

  @include mq("md", min) {
    font-size: $font-size-body;
  }
}

.p-block11__image4 {
  position: relative;
  width: 100%;
  margin: 24px 0 0;
  padding-top: 100%;
  text-align: center;

  @include mq("md", min) {
    display: flex;
    gap: 24px;
    margin: 32px 0 0;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.p-block11__image4__item {
  @include mq("md", min) {
    width: calc(16.666666666666667% - 33.333333333333333px);
  }
  img {
    width: 100%;
    height: auto;
  }
}

.p-block11__image5 {
  margin: 40px 0 0;
  @include mq("md", min) {
    margin: 48px 0 0;
  }
  img {
    width: 100%;
    height: auto;
    @include mq("md", min) {
      width: auto;
    }
  }
}

.p-block11__button1 {
  display: block;
  margin-top: 24px;
  @include mq("md", min) {
    margin-top: 40px;
  }

  &.-type1 {
    margin-top: 32px;
    @include mq("md", min) {
      margin-top: 64px;
    }
  }

  .c-button5,
  .c-button9,
  .c-button15 {
    display: block;
    @include mq("md", min) {
      display: inline-block;
      width: auto;
      min-width: 169px;
    }
  }
  .c-button10,
  .c-button12 {
    width: 100%;
    display: flex;
    @include mq("md", min) {
      display: inline-flex;
      width: auto;
      min-width: 360px;
    }
  }
}

.p-block11__row1 {
  flex-wrap: wrap;
  @include mq("md", min) {
    display: flex;
    margin: 0 -20px;
  }

  &.-type1 {
    margin-top: 24px;
    @include mq('md', min) {
      margin-top: 40px;
    }
  }

  &.-type2 {
    margin-top: 40px;
    @include mq('md', min) {
      margin-top: 120px;
    }
  }
}

.p-block11__row1__unit {
  margin: 40px 0 0;
  @include mq("md", min) {
    width: 33.33%;
    margin: 40px 0 0;
    padding: 0 20px;
  }

  *:first-child {
    @include mq("md", min) {
      margin-top: 0;
    }
  }
}

.p-block11__row2 {
  @include mq("md", min) {
    display: flex;
    gap: 40px;
  }

  &.-reverse {
    @include mq("md", min) {
      flex-direction: row-reverse;
    }
  }

  &.-type1 {
    @include mq("md", min) {
      justify-content: space-between;
    }
  }
}

.p-block11__row2__unit {
  @include mq("md", min) {
    width: calc(50% - 20px);
  }

  .p-block11__image2 {
    margin: 32px 0 0;
  }

  @at-root {
    .p-block11__row2.-type1 & {
      &:nth-child(odd) {
        @include mq("md", min) {
          width: 1048px;
        }
      }

      &:nth-child(even) {
        @include mq("md", min) {
          width: 504px;
        }
      }
    }
    .p-block11__row2.-type1.-reverse & {
      &:nth-child(odd) {
        @include mq("md", min) {
          width: 504px;
        }
      }

      &:nth-child(even) {
        @include mq("md", min) {
          width: 1048px;
        }
      }
    }
  }
}

.p-block11__row3 {
  margin: 24px 0 0;
  background: $neutral--100;

  @include mq('md', min) {
    display: flex;
    gap: 40px;
    margin: 32px 0 0;
  }

  + .p-block11__row3 {
    margin-top: 0;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $neutral--900;
  }

  &.-white {
    background: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }

  }

}

.p-block11__row3__unit {
  padding: 16px;

  @include mq('md', min) {
    width: calc(50% - 20px);
    padding: 64px;
  }

  &:first-child {
    @include mq('md', min) {
      display: flex;
      align-items: center;
      min-height: 776px;
    }
  }

  >*:first-child {
    margin-top: 0;
  }
}

.p-block11__row4 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  gap: 20px;

  @include mq('md', min) {
    margin-top: 64px;
    gap: 40px;
  }
}

.p-block11__row4__unit {
  width: calc((100% - 20px) * 1/2);

  @include mq("md", min) {
    width: calc((100% - 200px) * 1/6);
  }

  *:first-child {
    margin-top: 0;
  }
}

.p-block11__name1 {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  width: 240px;
  height: 62px;
  background: url("../images/block14_text1_1.png") 0 0 / cover no-repeat;
  margin: 24px 0 0 auto;

  @media (prefers-color-scheme: dark) {
    background: url("../images/block14_text1_2.png") 0 0 / cover no-repeat;
  }
}
