@use "../../global"as *;

.c-list11 {
  counter-reset: listnum;
  list-style: none;
  display: grid;
  gap: 24px;

  &.-small {
    @include mq('md', min) {
      gap: 16px;
    }
  }

}

.c-list11__item1 {
  display: grid;
  gap: 4px;

  @include mq('md', min) {
    display: flex;
    gap: 16px;
  }
}

.c-list11__item1::before {
  counter-increment: listnum;
  content: counter(listnum, upper-alpha)".";
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  font-family: $font-family-en;
  display: block;

  @at-root {
    .c-list11.-small .c-list11__item1::before {
      font-size: 16px;

      @include mq('md', min) {
        font-size: $font-size-body;
        transform: translateY(6px);
      }
    }

    .c-list11.-lower .c-list11__item1::before {
      content: counter(listnum, lower-alpha)".";
    }

  }
}

.c-list11__item1__text1 {
  font-size: 16px;
  line-height: 1.8;

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  a {
    color: inherit;
  }

  .-notes {
    display: block;
    margin-left: 0.5em;
    font-size: 12px;
    font-weight: normal;
    text-indent: -0.5em;

    @include mq('md', min) {
      font-size: $font-size-note;
    }
  }
}
