@use "../../global"as *;

.c-swiper1 {
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    margin: 0;
    top: 0;
    height: 100%;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $text-icon---primary--white;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }

      @at-root {

        .c-swiper1.-dark .swiper-button-prev:hover,
        .c-swiper1.-dark .swiper-button-next:hover {
          background: $neutral--800;
        }
      }
    }

    &::after {
      display: none;
    }

    &::before {
      display: block;
      content: "";
      transition: all 0.7s $easeOutQuint;
      width: 40px;
      height: 40px;
      background: $text-icon---primary--black;
      mask-image: url('/assets/images/icon_arrow1_1.svg');
      mask-size: cover;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {

        .c-swiper1.-dark .swiper-button-prev::before,
        .c-swiper1.-dark .swiper-button-next::before {
          background: $text-icon---primary--white;
        }
      }
    }
  }

  .swiper-button-prev {
    left: -40px;
    transform: rotate(180deg);

    @include mq('md', min) {
      left: calc(20px - 72px);
    }
  }

  .swiper-button-next {
    right: -40px;

    @include mq('md', min) {
      right: calc(20px - 72px);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
  }
}
