@use "../../global"as *;

.c-formCheck1 {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.c-formCheck1__input1 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.c-formCheck1__label1 {
  font-size: 16px;
  line-height: 1.8;
  display: flex;
  align-items: center;
  gap: 8px;

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: $text-icon---primary--black;
    mask-image: url('../images/icon_checkbox1_1.svg');

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @at-root {
      .c-formCheck1__input1:checked+.c-formCheck1__label1::before {
        mask-image: url('../images/icon_checkbox1_2.svg');
      }
    }
  }

  &.-type1 {
    font-size: 16px;
    font-weight: 700;
    @include mq('md', min) {
      font-size: 24px;
    }
  }
}
