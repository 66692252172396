@use "../../global" as *;

.c-breadcrumbs1 {
  background: $neutral--100;

  @media (prefers-color-scheme: dark) {
    background-color: $neutral--900;
  }

  &.is-alpha {
    background: rgba($text-icon---primary--white, 0.64);

    @media (prefers-color-scheme: dark) {
      background: rgba($text-icon---primary--black, 0.48);
    }
  }

  @at-root {
    .p-header1 + .p-breadcrumbs1 & {
      @media (prefers-color-scheme: dark) {
        border: none;
      }
    }
  }
}

.c-breadcrumbs1__inner1 {
  padding: 12px 20px;

  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 12px 100px;
    box-sizing: content-box;
    margin: auto;
  }
}

.c-breadcrumbs1__list1 {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 4px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.c-breadcrumbs1__list1__item1 {
  & > span,
  & > a {
    font-size: 12px;
    line-height: 1.33;
    white-space: nowrap;
  }

  & > a {
    color: $text-icon---primary--black;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      text-decoration: underline;
    }

    &::after {
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      background: $neutral--300;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .c-breadcrumbs1.is-alpha & {
          background: $text-icon---primary--black;

          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }
      }
    }
  }

  & > span {
    display: block;
    color: $neutral--300;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }
  }
}

// is-absolute
.c-breadcrumbs1.is-absolute {
  position: absolute;
  top: 72px;
  z-index: 99;
  width: 100%;
  @include mq("md", min) {
    //top: 86px;
    top: 120px;
  }
  .include_localNav_business1 + &,
  .include_localNav_career1 + &,
  .include_localNav_learn1 + & {
    @include mq("md", min) {
      //top: calc(86px + 68px);
      top: calc(120px + 68px);
    }
  }
}

// is-darkmode
.c-breadcrumbs1.is-darkmode {
  background-color: $neutral--900;
  @at-root {
    .p-header1 + .p-breadcrumbs1 & {
      border: none;
    }
  }
  .c-breadcrumbs1__list1__item1 {
    & > a {
      color: $text-icon---primary--white;
      &::after {
        background: $neutral--700;
      }
    }
    & > span {
      color: $neutral--700;
    }
  }
  &.is-absolute {
    background-color: rgba($neutral--1000, 0.48);
  }
}

// is-transparent
.c-breadcrumbs1.is-transparent {
  background-color: transparent !important;
  @at-root {
    .p-header1 + .p-breadcrumbs1 & {
      border: none;
    }
  }
  .c-breadcrumbs1__list1__item1 {
    & > a {
      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--black;
      }
      &::after {
        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--black;
        }
      }
    }
    & > span {
      @media (prefers-color-scheme: dark) {
        color: $neutral--300;
      }
    }
  }

  &.is-darkmode {
    .c-breadcrumbs1__list1__item1 {
      & > a {
        color: $text-icon---primary--white;
        &::after {
          background: $neutral--700;
        }
      }
      & > span {
        color: $neutral--700;
      }
    }
  }
}
