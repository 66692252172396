@use "../../global" as *;

.p-footer1 {
  margin: 60px 0 0;

  @include mq("md", min) {
    margin: 160px 0 0;
  }

  @at-root {
    main:has(.p-block5:last-child) + & {
      margin-top: 0;
    }
  }
}

.p-footer1__button1 {
  opacity: 0;
  pointer-events: none;
  transition: all 0.7s $easeOutQuint;

  &.-active {
    opacity: 1;
    pointer-events: all;
  }
}

.p-footer1__related1 {
  border-top: 1px solid $neutral--300;
  background: $primary--100;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
}

.p-footer1__related1__inner1 {
  padding: 40px 12px;

  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
    padding: 80px 100px 120px;
    box-sizing: content-box;
  }
}

.p-footer1__related1__title1 {
}

.p-footer1__related1__text1 {
  font-size: 16px;
  color: $text-icon---primary--black;
  margin: 24px 0 0;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq("md", min) {
    margin: 48px 0 0;
    font-size: 24px;
    line-height: 1.5;
  }
}

.p-footer1__related1__list {
}

.p-footer1__related1__list__item1 {
}

.p-footer1__related2 {
  background: $primary--700;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
    border-top: 1px solid $neutral--300;
  }
}

.p-footer1__related1__mobileSwiper1 {
  margin: 40px -12px 0;
  padding: 0 40px;
  overflow: hidden;

  @include mq("md", min) {
    display: none;
  }
}

.p-footer1__related1__desktopSwiper1 {
  display: none;

  @include mq("md", min) {
    position: relative;
    display: block;
    margin: 64px -20px 0;
  }
}

.p-footer1__related2__inner1 {
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 0 100px;
    margin: auto;
    box-sizing: content-box;
  }
}

.p-footer1__related2__mobileSwiper1 {
  padding: 40px;
  overflow: hidden;

  @include mq("md", min) {
    display: none;
  }
}

.p-footer1__related2__desktopSwiper1 {
  display: none;

  @include mq("md", min) {
    position: relative;
    display: block;
    padding: 120px 0;
    margin: 0 -20px;
  }
}

.p-footer1__related3 {
  background: $neutral--900;
  border-top: 1px solid $neutral--300;
}

.p-footer1__related3__inner1 {
  padding: 40px 20px;

  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
    padding: 120px 100px;
    box-sizing: content-box;
  }
}

.p-footer1__related3__list1 {
  display: grid;
  gap: 16px;

  @include mq("md", min) {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 0;
    margin: 0 -20px;
  }
}

.p-footer1__related3__list1__item1 {
  @include mq("md", min) {
    width: 25%;
    padding: 0 20px;
  }

  &.-col2 {
    @include mq("md", min) {
      width: 50%;
    }
  }
}

.p-footer1__breadcrumbs1 {
  @include mq("md", min) {
    display: none;
  }
}

.p-footer1__nav1 {
  background: $neutral--700;
  overflow: hidden;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }

  @include mq("md", max) {
    display: none;
  }
}

.p-footer1__nav1__inner1 {
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    padding: 120px 100px;
    box-sizing: content-box;
    margin: auto;
  }
}

.p-footer1__nav1__list1 {
  @include mq("md", min) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    gap: 40px;
  }
}

.p-footer1__nav1__list1__item1 {
  border-top: 1px solid $text-icon---primary--white;

  @include mq("md", min) {
    margin-top: -32px;
    border-top: none;
    border-bottom: 1px solid $text-icon---primary--white;
  }
}

.p-footer1__nav1__list1__item1__category1 {
  & > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text-icon---primary--white;
    font-size: 36px;
    line-height: 1.33;
    font-weight: 600;
    font-family: $font-family-en;
    font-weight: 600;
    text-decoration: none;
    padding: 36px 0 36px 20px;
    gap: 10px;
    transition: all 0.7s $easeOutQuint;

    @include mq("md", min) {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 0;
    }

    &:hover {
      background: $neutral--800;
    }

    &::after {
      display: block;
      content: "";
      width: 48px;
      min-width: 48px;
      height: 48px;
      background: $text-icon---primary--white;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      transition: all 0.7s $easeOutQuint;

      @at-root {
        .p-footer1__nav1__list1__item1__category1:hover > a::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

.p-footer1__nav1__list2 {
}

.p-footer1__nav1__list2__item1 {
  border-top: 1px solid rgba($text-icon---primary--white, 0.24);

  @include mq("md", min) {
    border-top: 1px solid $text-icon---primary--white;
  }

  & > a,
  & > button {
    background: none;
    width: 100%;
    border: none;
    font-size: 20px;
    line-height: 1.8;
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0 8px 20px;
    gap: 10px;
    color: $text-icon---primary--white;
    text-decoration: none;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $neutral--800;
    }

    @include mq("md", min) {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 0;
      font-size: 24px;
      line-height: 1.5;
    }

    &::after {
      display: block;
      content: "";
      width: 48px;
      min-width: 48px;
      height: 48px;
      background: $text-icon---primary--white;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      transform: rotate(90deg);
      transition: all 0.7s $easeOutQuint;

      @at-root {
        .p-footer1__nav1__list2__item1 > a:hover::after {
          animation: arrow1 0.35s ease-out;
        }

        .p-footer1__nav1__list2__item1 > button:hover::after {
          animation: arrow2 0.35s ease-out;
        }

        .p-footer1__nav1__list2__item1 > button[aria-expanded="true"]::after {
          transform: rotate(-90deg);
        }

        .p-footer1__nav1__list2__item1 > button[aria-expanded="true"]:hover::after {
          animation: arrow3 0.35s ease-out;
        }
      }
    }
  }

  & > a {
    &::after {
      transform: rotate(0);
    }
  }
}

.p-footer1__nav1__list3 {
  //display: none;

  //@at-root {
  //  .p-footer1__nav1__list2__item1 > button[aria-expanded="true"] + & {
  //    display: block;
  //  }
  //}
}

.p-footer1__nav1__list3__item1 {
  border-top: 1px solid rgba($text-icon---primary--white, 0.24);

  & > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text-icon---primary--white;
    text-decoration: none;
    font-size: 20px;
    line-height: 2.25;
    gap: 10px;
    padding: 10px 8px 10px 40px;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $neutral--800;
    }

    //@at-root {
    //  .p-footer1__nav1__list3__item1:first-child > a {
    //    padding-left: 20px;

    //    @include mq("md", min) {
    //      padding-left: 0;
    //    }
    //  }
    //}

    &::after {
      display: block;
      content: "";
      width: 24px;
      min-width: 24px;
      height: 24px;
      background: $text-icon---primary--white;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;
      transition: all 0.7s $easeOutQuint;

      @at-root {
        .p-footer1__nav1__list3__item1 > a:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

.p-footer1__copy1 {
  background: $neutral--700;
  border-top: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.p-footer1__copy1__inner1 {
  padding: 40px 20px;

  @include mq("md", min) {
    padding: 21px 100px;
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.p-footer1__copy1__text1 {
  font-size: 16px;
  line-height: 1.8;
  font-family: $font-family-en;
  color: $text-icon---primary--white;
  display: block;
}

.p-footer1__copy1__list1 {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 24px 0 0;

  @include mq("md", min) {
    margin: 0;
    gap: 10px;
  }

  a {
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid $text-icon---primary--white;
    border-radius: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    position: relative;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $text-icon---primary--white;
    }

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      inset: 0;
      margin: auto;
      background: $text-icon---primary--white;
      transition: all 0.7s $easeOutQuint;

      @at-root {
        .p-footer1__copy1__list1 a.-youtube::before {
          mask-image: url("/assets/images/icon_youtube1_1.svg");
        }

        .p-footer1__copy1__list1 a.-x::before {
          mask-image: url("/assets/images/icon_x1_1.svg");
        }

        .p-footer1__copy1__list1 a.-tiktok::before {
          mask-image: url("/assets/images/icon_tiktok1_1.svg");
        }

        .p-footer1__copy1__list1 a.-instagram::before {
          mask-image: url("/assets/images/icon_instagram1_1.svg");
        }

        .p-footer1__copy1__list1 a.-facebook::before {
          mask-image: url("/assets/images/icon_facebook1_1.svg");
        }

        .p-footer1__copy1__list1 a.-linkedin::before {
          mask-image: url("/assets/images/icon_linkedin1_1.svg");
        }

        .p-footer1__copy1__list1 a.-line::before {
          mask-image: url("/assets/images/icon_line1_1.svg");
        }

        .p-footer1__copy1__list1 a:hover.-line::before,
        .p-footer1__copy1__list1 a:hover.-linkedin::before,
        .p-footer1__copy1__list1 a:hover.-facebook::before,
        .p-footer1__copy1__list1 a:hover.-instagram::before,
        .p-footer1__copy1__list1 a:hover.-tiktok::before,
        .p-footer1__copy1__list1 a:hover.-x::before,
        .p-footer1__copy1__list1 a:hover.-youtube::before {
          background: $text-icon---primary--black;
        }
      }
    }
  }
}

.p-footer1__copy1__list2 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.2;

  @include mq("md", min) {
    gap: 20px;
    width: 100%;
    margin-bottom: 16px;
    font-size: 20px;
  }

  li {
    + li {
      padding-left: 16px;
      border-left: 1px solid $text-icon---primary--white;
      @include mq("md", min) {
        padding-left: 20px;
      }
    }
  }

  a {
    color: $text-icon---primary--white;
    &:hover {
      text-decoration: none;
    }
  }
}

.p-footer1__mail1 {
  background: $primary--100;
  border-top: 1px solid $neutral--300;
  padding: 40px 12px;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq("md", min) {
    padding: 80px 100px 120px;
  }
}

.p-footer1__mail1__inner1 {
  @include mq("md", min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-footer1__mail1__title1 {
}

.p-footer1__mail1__policy1 {
  margin: 40px 0 0;

  @include mq("md", min) {
    margin: 48px 0 0;
  }
}

.p-footer1__mail1__policy1__checkbox1 {
  margin: 24px 0 0;
}

.p-footer1__mail1__form1 {
  margin: 32px 0 0;

  @include mq("md", min) {
    margin: 64px 0 0;
  }
}

.p-footer1__mail1__form1__button1 {
  margin: 24px 0 0;
}

.p-footer1__mail1__button1 {
  margin: 40px 0 0;

  @include mq("md", min) {
    margin: 48px 0 0;
  }

  .c-button13 {
    @include mq("md", min) {
      width: auto;
    }
  }
}
