@use "../../global"as *;

.c-button5 {
  position: relative;
  display: block;
  text-decoration: none;
  color: $text-icon---primary--black;
  border: 1px solid $text-icon---primary--black;
  border-radius: 10rem;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  padding: 9px 40px 9px 32px;
  background: $text-icon---primary--white;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;
    border-color: $neutral--300;
  }

  &.is-noBorder {
    border: none;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    margin: auto;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button5:hover::after {
        animation: arrow1 0.35s ease-out;
      }
    }

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }
  }

  &.is-external {
    &::after {
      right: 16px;
      mask-image: url('/assets/images/icon_external1_1.svg');
      mask-size: cover;

      @at-root {
        .c-button5:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}
