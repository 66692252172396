@use "../../global" as *;

// heading1
.p-interview-heading1 {
  padding: 0 12px;
  overflow: hidden;

  @include mq("md", min) {
    position: relative;
    padding: 0 100px;
  }
}
.p-interview-heading1__image1 {
  position: relative;
  height: 667px;
  margin: 0 -12px;
  overflow: hidden;

  @include mq("md", min) {
    height: 1080px;
    margin: 0 -100px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.32);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.p-interview-heading1__image1__label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0 12px;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: $text-icon---primary--white;

  @include mq("md", min) {
    font-size: 80px;
    min-width: 1000px;
    max-width: 1600px;
    height: calc(100% - 86px - 40px);
    padding: 0 100px;
    padding-top: calc(86px + 40px);
    box-sizing: content-box;
    right: 0;
    margin: auto;
  }
}
.p-interview-heading1__image1__labelJp1 {
  //display: inline-flex;
  display: inline-block;
  align-items: center;
  padding: 6px 12px;
  border: 2px solid $text-icon---primary--white;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  vertical-align: middle;

  @include mq("md", min) {
    font-size: 40px;
    padding: 10px 40px;
  }

  &:has(span) {
    line-height: 42px;

    @include mq("md", min) {
      line-height: 96px;
    }
  }

  span {
    font-size: 20px;
    font-weight: 900;
    vertical-align: bottom;

    @include mq("md", min) {
      font-size: 64px;
    }
  }
}
.p-interview-heading1__image1__labelEn1 {
  font-size: 24px;
  line-height: 1;
  font-family: $font-family-en;
  font-weight: 600;
  white-space: nowrap;
  position: absolute;
  right: 8px;
  bottom: -24px;
  color: $text-icon---primary--white;
  text-transform: uppercase;

  @include mq("md", min) {
    font-size: 160px;
    right: 0;
    bottom: -82px;
  }

  span {
    display: inline-block;
    font-size: 144px;
    transform: translateY(20px);

    @include mq("md", min) {
      font-size: 480px;
      transform: translateY(80px);
    }
  }

  &.-small {
    font-size: 120px;
    right: auto;
    left: 57px;

    @include mq("md", min) {
      right: -120px;
      left: auto;
      font-size: 240px;
      bottom: -55px;
    }
  }
}
.p-interview-heading1__image1__text1 {
  margin-top: 8px;
  font-size: 40px;
  line-height: 1.5;
  font-weight: 900;

  @include mq("md", min) {
    font-size: 80px;
  }
}

// block1
.p-interview-block1 {
  @include mq("md", min) {
    display: flex;
    flex-direction: row-reverse;
  }
  > * {
    @include mq("md", min) {
      width: 50%;
    }
  }
  &.is-reverse {
    @include mq("md", min) {
      flex-direction: row;
      align-items: center;
    }
  }
  &.-gray {
    background: $neutral--100;
    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }
}
.p-interview-block1__text1 {
  padding: 40px 12px 32px;
  @include mq("md", min) {
    width: calc(100% * (680 / 1920));
    margin: auto;
    margin-top: calc(100% * (240 / 1920));
    margin-bottom: calc(100% * (240 / 1920));
    padding: 0;
  }
}
.p-interview-block1__text1__title {
  margin-bottom: 48px;
}
.p-interview-block1__text1__titleMain {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    font-size: 56px;
  }
}
.p-interview-block1__text1__titleSub {
  margin-bottom: 24px;
  font-size: 16px;
  @include mq("md", min) {
    font-size: 24px;
  }
}
.p-interview-block1__text1__text1 {
  @include mq('md', min) {
    font-size: $font-size-body;
  }
}
.p-interview-block1__text1__notes1 {
  font-size: 12px;

  @include mq('md', min) {
    font-size: $font-size-note;
  }

  > li {
    position: relative;
    padding-left: 1.25em;
    &::before {
      content: "*";
      position: absolute;
      left: 0;
      display: inline-block;
    }
  }
}
.p-interview-block1__image1 {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// block2
.p-interview-block2 {
  @include mq("md", min) {
    position: relative;
  }
}
.p-interview-block2__text1 {
  padding: 40px 12px 32px;
  color: $text-icon---primary--white;
  background: $text-icon---primary--black;
  @include mq("md", min) {
    position: absolute;
    left: calc(50% + 100% * (100 / 1920));
    z-index: 2;
    width: calc(100% * (680 / 1920));
    margin: auto;
    margin-top: calc(100% * (240 / 1920));
    margin-bottom: calc(100% * (240 / 1920));
    padding: 0;
    background: transparent;
  }
  .p-interview-block2.is-reverse & {
    left: initial;
    right: calc(50% + 100% * (100 / 1920));
  }
}
.p-interview-block2__text1__title {
  margin-bottom: 48px;
}
.p-interview-block2__text1__titleMain {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    font-size: 56px;
  }
}
.p-interview-block2__text1__titleSub {
  margin-bottom: 24px;
  font-size: 16px;
  @include mq("md", min) {
    font-size: 24px;
  }
}
.p-interview-block2__text1__text1 {
  @include mq('md', min) {
    font-size: $font-size-body;
  }
  &.-large {
    font-size: 16px;
    @include mq("md", min) {
      font-size: 24px;
    }
  }
}
.p-interview-block2__image1 {
  @include mq("md", min) {
    position: relative;
    height: 1080px;
  }
  //&::before {
  //  @include mq('md', min) {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    display: block;
  //    width: 100%;
  //    height: 100%;
  //    background: $text-icon---primary--black;
  //    opacity: 0.24;
  //  }
  //}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// block3
.p-interview-block3 {
  background: $neutral--100;
  @include mq("md", min) {
    position: relative;
  }
  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }
  &.-white {
    background: $neutral--0;
    @media (prefers-color-scheme: dark) {
      background: $neutral--1000;
      color: $text-icon---primary--white;
    }
  }
}
.p-interview-block3__inner {
  padding: 40px 12px 80px;
  @include mq("md", min) {
    padding: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 120px 100px 160px;
    box-sizing: content-box;
    right: 0;
    margin: auto;
  }
  @at-root {
    .p-interview-block3.-white + .p-interview-block3.-white,
    .p-interview-block3:not(.-white) + .p-interview-block3:not(.-white) {
      .p-interview-block3__inner {
        padding-top: 0;
      }
    }
  }
}
.p-interview-block3__text1 {
  margin-bottom: 32px;
  @include mq("md", min) {
    margin-bottom: 64px;
  }
  &:last-child {
    margin-bottom: 0;
    @include mq("md", min) {
      margin-bottom: 0;
    }
  }
}
.p-interview-block3__text1__title {
  margin-bottom: 48px;
}
.p-interview-block3__text1__titleMain {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    font-size: 56px;
  }
}
.p-interview-block3__text1__titleSub {
  margin-bottom: 24px;
  font-size: 16px;
  @include mq("md", min) {
    font-size: 24px;
  }
}
.p-interview-block3__text1__text1 {
  @include mq('md', min) {
    font-size: $font-size-body;
  }
  p + p {
    margin-top: 1.5em;
  }
  &.-large {
    font-size: 16px;
    @include mq("md", min) {
      font-size: 24px;
    }
  }
}

// block4
.p-interview-block4 {
  background: $neutral--800;
  color: $text-icon---primary--white;

  &.-white {
    background: #fff;
    color: $text-icon---primary--black;
  }
}
.p-interview-block4__inner {
  padding: 40px 12px;
  @include mq("md", min) {
    padding: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 120px 100px 160px;
    box-sizing: content-box;
    right: 0;
    margin: auto;
  }

  @at-root {
    .p-interview-block4.-white & {
      @include mq("md", min) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  }
}
.p-interview-block4__title1 {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  @include mq("md", min) {
    margin-bottom: 32px;
    font-size: 40px;
  }
}
.p-interview-block4__text1 {
  font-size: 16px;
  @include mq("md", min) {
    font-size: 24px;
  }
  &.-small {
    @include mq("md", min) {
      font-size: 20px;
    }  }
}
.p-interview-block4_list1 {
  border-top: 1px solid $neutral--0;
  @include mq("md", min) {
    display: flex;
    width: 100%;
    padding: 24px 0;
    gap: 10px;
  }
  + .p-interview-block4_list1 {
    margin-top: 24px;
    @include mq("md", min) {
      margin-top: 32px;
    }
  }
}
.p-interview-block4_list1_title {
  font-weight: 700;
  @include mq("md", min) {
    width: 320px;
    font-size: 24px;
  }
}
.p-interview-block4_list1_text {
  line-height: 1.8;
  @include mq("md", min) {
  }
  p + p {
    margin-top: 24px;
    @include mq("md", min) {
      margin-top: 24px;
    }
  }
}
