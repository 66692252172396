@use "../../global"as *;

.c-title9 {}

.c-title9__mainLabel1 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 24px;
  }
}

.c-title9__title1 {
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    display: flex;
    gap: 12px;
  }
}

.c-title9__title1__number1 {
  font-size: 24px;
  line-height: 1;
  font-family: $font-family-en;

  @include mq('md', min) {
    line-height: 1.2;
    font-size: 32px;
  }
}

.c-title9__title1__mainLabel1 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;

  @include mq('md', min) {
    font-size: 24px;
  }
}