@use "../../global"as *;

.c-card13 {
  background: $primary--700;
  color: $text-icon---primary--white;
  padding: 20px 0 34px;
  box-shadow: 0 8px 16px rgba($text-icon---primary--black, 0.12);
  height: 100%;
  display: grid;
  align-items: center;

  @include mq('md', min) {
    padding: 20px 0;
  }
}

.c-card13__title1 {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;

  @include mq('md', min) {
    font-size: 40px;
  }
}

.c-card13__text1 {
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  border: 2px solid $text-icon---primary--white;
  border-radius: 10rem;
  font-weight: 700;
  width: 148px;
  height: 148px;
  display: grid;
  align-items: center;
  margin: 16px auto 0;

  @include mq('md', min) {
    font-size: 24px;
    margin: 21px auto 0;
  }

  @include mq('3xl', min) {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    margin: 0 auto;
  }
}

.c-card13__inner1 {
  position: relative;
}

.c-card13__subInner1 {
  margin: 40px 0 0;

  @include mq('3xl', min) {
    margin: 22px auto 0;
    display: flex;
    justify-content: space-between;
    max-width: 653px;
    padding: 0 20px;
  }

  @media screen and (min-width: 1815px) {
    transform: translateX(18px);
  }
}

.c-card13__unit1 {}

.c-card13__unit1__image1 {
  width: 110px;
  margin: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.c-card13__unit1__title1 {
  font-size: 16px;
  text-align: center;
  margin: 10px 0 0;

  @include mq('md', min) {
    margin: 26px 0 0;
  }
}

.c-card13__unit1__text1 {
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 1;
  font-family: $font-family-en;
  margin: 2px 0 0;

  @include mq('md', min) {
    font-size: 56px;
  }

  span {
    font-size: 32px;
  }
}

.c-card13__unit1__text2 {
  font-size: 12px;
  margin: 5px 0 0;
  text-align: center;
}

.c-card13__unit2 {
  margin: 40px 0 0;

  @include mq('3xl', min) {
    margin: 0;
  }
}

.c-card13__unit2__image1 {
  width: 110px;
  margin: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.c-card13__unit2__title1 {
  font-size: 16px;
  text-align: center;
  margin: 10px 0 0;

  @include mq('md', min) {
    margin: 26px 0 0;
  }
}

.c-card13__unit2__text1 {
  display: flex;
  justify-content: center;
  font-weight: 700;
  gap: 67px;
  margin: 8px 0 0;
  line-height: 1;

  @include mq('md', min) {
    gap: 20px;
  }

  &>span {
    font-size: 48px;
    font-family: $font-family-en;

    @include mq('md', min) {
      font-size: 56px;
    }

    &>span {
      font-size: 32px;
    }
  }
}
