@use "../../global"as *;

.c-list1 {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 0;

  @include mq("md", min) {
    font-size: $font-size-body;
  }

  @at-root {
    .c-list1>.c-list1__item1>.c-list1 {
      margin: 0 -20px;
    }
  }
}

.c-list1__item1 {
  width: 25%;
  padding: 0 20px;

  @at-root {
    .c-list1__item1>.c-list1>& {
      width: 50%;
    }
  }

  &.-col2 {
    width: 50%;

    @at-root {
      .c-list1__item1>.c-list1>.c-list1__item1.-col2 {
        width: 100%;
      }
    }
  }

  &.-row2 {
    height: 100%;
  }
}
