// Breakpoint
$screen-xs: 360;
$screen-sm: 640;
$screen-md: 769;
$screen-lg: 1024;
$screen-xl: 1280;
$screen-2xl: 1440;
$screen-3xl: 1536;
$screen-4xl: 1920;

$screen: ('xs': $screen-xs,
  'sm': $screen-sm,
  'md': $screen-md,
  'lg': $screen-lg,
  'xl': $screen-xl,
  '2xl': $screen-2xl,
  '3xl': $screen-3xl) !default;

// Colors
$text-icon---primary--black: #000000;
$text-icon---secondary--black: #1D2433;
$text-icon---disabled--black: #DCDCDC;
$text-icon---primary--white: #FFFFFF;
$text-icon---secondary--white: #FFFFFF;
$text-icon---disabled--white: #FFFFFF;
$neutral--0: #FFFFFF;
$neutral--100: #F4F4F4;
$neutral--200: #EAEAEA;
$neutral--300: #CCCCCC;
$neutral--700: #464646;
$neutral--800: #333333;
$neutral--900: #191919;
$neutral--1000: #000000;
$primary--100: #F0F5FF;
$primary--700: #0069C8;
$primary--800: #005BAD;
$primary--900: #004E94;
$primary--400: #91B5FD;
$primary--200: #DCE7FE;
$secondary--100: #F8F5FF;
$secondary--700: #8B54F7;
$secondary--800: #6D35DE;
$secondary--900: #5221B5;
$secondary--400: #CCB4FD;
$success--100: #EDFDF6;
$success--700: #00994D;
$success--800: #007A47;
$success--900: #06603A;
$success--400: #7BE7B1;
$warning--100: #FFF8EB;
$warning--800: #96530F;
$warning--900: #80460D;
$danger--100: #FEF1F2;
$danger--700: #E02D3C;
$danger--800: #BA2532;
$danger--900: #981B25;
$danger--400: #FCA6AD;
$brand---facebook: #3C5A96;
$brand---linkedin: #1178B3;
$brand---mailchimp: #2C9AB7;
$brand---microsoft: #F35426;
$brand---twitter: #2AA3EF;
$brand---pinterest: #BB0F23;
$brand---paypal: #053385;
$brand---reddit: #FC471E;
$brand---google: #E8453C;
$brand---dropbox: #1581E2;
$brand---r-s-s: #F0652F;
$brand---salesforce: #2399BF;
$brand---dribbble: #E84F89;
$brand---instagram: #713DBB;
$brand---slack: #DE1D64;
$brand---youtube: #FC0D1B;
$warm--700: #FE9B19;
$warm--100: #FFF7ED;
$warm--400: #FFCB82;

// Font Family
$font-family-en: 'Montserrat',
sans-serif;

$font-family-jp: "Noto Sans JP",
sans-serif;

// Easing
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

// Font Size
$font-size-body: 20px;
$font-size-note: 16px；
