@use "../../global"as *;

.p-block19 {
  margin: 40px 0 0;
  padding: 0 12px;

  @include mq('md', min) {
    padding: 0 100px;
    margin: 120px 0 0;
  }
}

.p-block19__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block19__title1 {}

.p-block19__text1 {
  font-size: 16px;
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
    font-size: $font-size-body;
  }
}

.p-block19__image1 {
  margin: 24px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }

  img {
    @media (prefers-color-scheme: dark) {
      display: none;
    }

    &:nth-last-of-type(1) {
      display: none;

      @media (prefers-color-scheme: dark) {
        display: block;
      }
    }
  }

  figcaption {
    font-size: 12px;
    margin: 8px 0 0;

    @include mq('md', min) {
      margin: 0;
    }
  }
}
