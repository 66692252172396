@use "../../global"as *;

.p-block9 {
  padding: 64px 12px 0;

  @include mq('md', min) {
    min-height: 840px;
    padding: 120px 100px 0;
  }

  &.-gray {
    padding: 64px 12px 80px;
    background: $neutral--100;

    @include mq('md', min) {
      min-height: initial;
      padding: 120px 100px 160px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }
}

.p-block9__inner1 {
  position: relative;

  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }

  &::before {
    display: block;
    content: '';
    aspect-ratio: 346/170;
    width: 100%;
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin: auto;
    background: $neutral--100;
    mask-image: url('/assets/images/block9_img1_1.svg');
    mask-size: contain;

    @include mq('md', min) {
      top: -64px;
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--900;
    }
  }

  @at-root {
    .p-block9.-gray .p-block9__inner1 {
      .p-block9__areaDetail1:first-child {
        margin-top: 0;
        .p-block9__areaDetail1__inner1 {
          margin-top: 0;
          padding-top: 0;
        }
      }
      &::before {
        display: none;
      }
    }
  }

}

.p-block9__areaSelect1 {
  display: grid;
  gap: 24px;

  @include mq('md', min) {
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
}

.p-block9__areaSelect1__item1 {}

.p-block9__areaSelect1__item1__formRadio1 {}

.p-block9__areaSelect2 {
  display: none;

  @include mq('md', min) {
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    margin: 60px 0 0;
  }

  &.-active {
    @include mq('md', min) {
      display: grid;
    }
  }
}

.p-block9__areaSelect1__item1__formSelect1 {
  width: 270px;
  margin: 24px auto;
  display: none;

  @include mq('md', min) {
    display: none;
  }

  &.-active {
    display: block;

    @include mq('md', min) {
      display: none;
    }
  }
}

.p-block9__areaDetail1 {
  position: relative;
  margin: 64px -12px 0;

  @include mq('md', min) {
    margin: 0;
  }

  @at-root {
    .p-block16__tabs + & {
      margin-top: 0;
    }
  }
}

.p-block9__areaDetail1__inner1 {
  background: $neutral--100;
  padding: 48px 12px 80px;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    background: none;
    padding: 0;
    margin: 108px 0 0;

  }
}

.p-block9__areaDetail1__unit1 {}

.p-block9__areaDetail1__unit1__title1 {
  font-size: 32px;
  line-height: 1.5;

  @include mq('md', min) {
    font-size: 56px;
  }
}

.p-block9__areaDetail1__unit1__list1 {
  margin: 24px 0 0;
  display: grid;
  gap: 24px;

  @include mq('md', min) {
    margin: 40px 0 0;
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.p-block9__areaDetail1__unit1__list1__item1 {}

.p-block9__areaDetail1__unit1__button1 {
  display: block;
  margin-top: 24px;
  @include mq('md', min) {
    margin-top: 40px;
  }
  .c-button5,
  .c-button15 {
    display: block;
    @include mq('md', min) {
      display: inline-block;
      width: auto;
      min-width: 169px;
    }
  }
}
.p-block9__areaDetail1__unit1__text1 {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.8;
  @include mq('md', min) {
    margin-top: 40px;
    font-size: $font-size-body;
  }
  + .p-block9__areaDetail1__unit1__list1 {
    margin-top: 40px;
    @include mq('md', min) {
      margin-top: 80px;
    }
  }
}
