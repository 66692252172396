@use "../../global"as *;

.c-formText2 {
  &>input {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    padding: 8px 32px;
    height: 80px;
    width: 100%;
    border-radius: 10rem;
    border: 1px solid $neutral--300;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }

    &::placeholder {
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }
    }

    &:disabled {
      &::placeholder {
        color: $text-icon---disabled--black;
        @media (prefers-color-scheme: dark) {
          color: $neutral--800;
        }
      }
    }

  }
}
