@use "../../global"as *;

.c-formText1 {
  position: relative;

  @include mq('md', min) {
    border-radius: 10rem;
    cursor: pointer;
  }

  &>input {
    width: 100%;
    border-radius: 10rem;
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 0 8px 40px;
    transition: all 0.7s $easeOutQuint;
    border: 1px solid $neutral--300;
    background: $text-icon---primary--black;
    color: $text-icon---primary--white;

    @include mq('md', min) {
      background: $neutral--0;
      border: 1px solid $neutral--300;
      color: $text-icon---primary--black;
      width: 0;
      cursor: pointer;
    }

    @at-root {
      .c-formText1:not(.-active):hover input {
        @include mq('md', min) {
          background: $neutral--200;
        }
      }

      .c-formText1.-active input {
        @include mq('md', min) {
          width: 100%;
          cursor: auto;
        }
      }
    }

    &::placeholder {
      color: $text-icon---primary--white;

      @include mq('md', min) {
        color: $text-icon---secondary--black;
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 9px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: $text-icon---primary--white;
    mask-image: url('/assets/images/icon_search1_1.svg');

    @include mq('md', min) {
      width: 42px;
      height: 42px;
      left: 0;
      background: url('/assets/images/icon_search1_1.svg') center center / cover no-repeat;
      background-size: 24px auto;
      mask-image: none;
    }

    @at-root {
      .c-formText1.-active::before {
        cursor: auto;
      }
    }
  }
}
