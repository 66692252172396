@use "../../global"as *;

.c-list8 {
  @include mq('md', min) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-list8__item1 {}
