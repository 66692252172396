@use "../../global"as *;

.c-list6 {
  display: grid;
  gap: 40px;

  @include mq('md', min) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

.c-list6__item1 {}
