@use "../../global"as *;

.c-table1 {
  box-shadow: 0 2px 8px rgba($text-icon---primary--black, 0.12);

  table {
    border-collapse: collapse;
    width: 100%;

    @media (prefers-color-scheme: dark) {
      border: 1px solid $neutral--300;
    }
  }

  tr {
    border-top: 1px solid $neutral--300;

    &:first-child {
      border: none;
    }

    @include mq('md', min) {

      &:first-of-type {
        border: none;
      }

      &:nth-of-type(odd) {
        background: $text-icon---primary--white;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--black;
        }
      }
      &:nth-of-type(even) {
        background: $neutral--100;

        @media (prefers-color-scheme: dark) {
          background: $neutral--900;
        }
      }
    }
  }

  th,
  td {
    display: block;

    @include mq('md', min) {
      display: table-cell;
    }

    &.-nowrap-pc {
      @include mq('md', min) {
        white-space: nowrap;
      }
    }
  }

  th {
    font-size: 18px;
    line-height: 2;
    padding: 8px 12px;
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }

    @include mq('md', min) {
      width: 320px;
      text-align: left;
      padding: 20px 24px;
      font-size: 24px;
      line-height: 1.5;
    }
  }

  td {
    padding: 16px 12px;
    font-size: 16px;
    line-height: 1.8;

    @include mq('md', min) {
      padding: 24px;
      font-size: $font-size-body;
    }

    &>* {
      margin: 8px 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }
}

.c-table1__button {
  .c-button15 {
    display: inline-block;
    width: auto;
    font-weight: 400;
  }
}
