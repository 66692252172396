@use "../../global"as *;

.p-localNav2 {
  background: $neutral--100;
  padding: 40px 12px;
  border-top: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    background: $neutral--900;
  }

  @include mq('md', min) {
    display: none;
  }
}

.p-localNav2__inner1 {}

.p-localNav2__subInner1 {}

.p-localNav2__title1 {
  a {
    text-decoration: none;
    color: $text-icon---primary--black;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

  }
}

.p-localNav2__list1 {
  margin: 24px 0 0;
  display: grid;
  gap: 16px;
}

.p-localNav2__list1__item1 {}
