@use "../../global"as *;

.c-card12 {
  padding: 20px 20px 30px;
  background: $text-icon---primary--white;
  box-shadow: 0 8px 16px rgba($text-icon---primary--black, 0.12);
  height: 100%;

  @include mq('md', min) {
    display: grid;
    align-items: center;
    padding: 24px 24px 24px;
  }

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.c-card12__title1 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;

  @include mq('md', min) {
    font-size: 56px;
  }
}

.c-card12__text1 {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 20px 0 0;
  font-weight: 700;

  @include mq('md', min) {
    font-size: 32px;
    margin: 16px 0 0;
  }
}

.c-card12__graph1 {
  position: relative;
  margin: 0 -10px;
  background: url('../images/card12_img1_1.png') 0 center / contain no-repeat;

  @media (prefers-color-scheme: dark) {
    background: url('../images/card12_img1_1_dark.png') 0 center / contain no-repeat;
  }

  @include mq('md', min) {
    margin: 0;
  }
}

.c-card12__graph1__inner1 {
  position: absolute;
  inset: 0;
  display: grid;
  align-items: center;
  transform: translateY(-13px);

  @include mq('md', min) {
    transform: translateY(13px);
  }
}

.c-card12__graph1__subInner1 {}

.c-card12__graph1__mainLabel1 {
  display: grid;
  justify-content: center;

  span {
    text-align: center;

    &:nth-of-type(1) {
      font-size: 56px;
      font-weight: 700;
      line-height: 2.14;
      font-family: $font-family-en;

      @include mq('md', min) {
        font-size: 88px;
        line-height: 1.36;
      }
    }

    &:nth-of-type(2) {
      font-size: 36px;
      font-weight: 700;
      line-height: 2.36;
      margin: -53px 0 0;

      @include mq('md', min) {
        font-size: 56px;
        line-height: 1.5;
        margin: -17px 0 0;
      }
    }
  }
}

.c-card12__graph1__subLabel1 {
  font-size: 12px;
  text-align: center;
  display: block;
  margin: -5px 0 0;

  @include mq('md', min) {
    font-size: 16px;
  }
}

.c-card12__graph1__image1 {
  display: flex;
  justify-content: center;
}

.c-card12__graph2 {
  position: relative;
  margin: 0 -10px;
}

.c-card12__graph2__inner1 {
  position: relative;
}

.c-card12__graph2__subInner1 {
  position: absolute;
  z-index: 2;
  inset: 0;
  display: grid;
  align-items: center;
  transform: translateY(-13px);

  @include mq('md', min) {
    transform: translateY(13px);
  }
}


.c-card12__graph2__mainLabel1 {
  display: grid;
  justify-content: center;

  span {
    text-align: center;

    &:nth-of-type(1) {
      font-size: 56px;
      font-weight: 700;
      line-height: 2.14;
      font-family: $font-family-en;

      @include mq('md', min) {
        font-size: 88px;
        line-height: 1.36;
      }
    }

    &:nth-of-type(2) {
      font-size: 36px;
      font-weight: 700;
      line-height: 2.36;
      margin: -53px 0 0;

      @include mq('md', min) {
        font-size: 56px;
        line-height: 1.5;
        margin: -17px 0 0;
      }
    }
  }
}

.c-card12__graph2__subLabel1 {
  font-size: 12px;
  text-align: center;
  display: block;
  margin: -5px 0 0;

  @include mq('md', min) {
    font-size: 16px;
  }
}

.c-card12__graph2__image1 {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.c-card12__graph2__image1__list1 {
  @include mq('md', min) {
    display: none;
  }

  &>li {
    position: absolute;
    font-family: $font-family-en;
    font-size: 14px;
    line-height: 30px;
    font-weight: 700;

    &:nth-child(1) {
      top: 50%;
      left:  calc(50% + (100% * (136/311)));
      transform: translateY(-50%);
    }

    &:nth-child(2) {
      top: calc(100% * (8/311));
      right: calc(50% + (100% * (47/311)));
    }

    &:nth-child(3) {
      top: calc(100% * (56/311));
      right: calc(50% + (100% * (112/311)));
    }

    &:nth-child(4) {
      top: calc(100% * (97/311));
      right: calc(50% + (100% * (131/311)));
    }

    &:nth-child(5) {
      top: calc(100% * (132/311));
      right: calc(50% + (100% * (139/311)));
    }

    &:nth-child(6) {
      top: calc(100% * (162/311));
      right: calc(50% + (100% * (138/311)));
    }

    &:nth-child(7) {
      top: calc(100% * (189/311));
      right: calc(50% + (100% * (131/311)));
    }

    &:nth-child(8) {
      top: calc(100% * (209/311));
      right: calc(50% + (100% * (124/311)));
    }

    &:nth-child(9) {
      top: calc(100% * (224/311));
      right: calc(50% + (100% * (113/311)));
    }

    &:nth-child(10) {
      top: calc(100% * (273/311));
      right: calc(50% + (100% * (51/311)));
    }
  }
}

.c-card12__graph2__list1 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 24px;

  @include mq('md', min) {
    display: block;
  }

  &>li {
    display: flex;
    gap: 4px;
    width: calc(50% - 12px);

    @include mq('md', min) {
      position: absolute;
      display: block;
      width: auto;
    }

    &.-long {
      width: 100%;

      @include mq('md', min) {
        width: auto;
      }
    }

    &>span {
      font-size: 16px;
      line-height: 1.4;

      @include mq('md', min) {
        line-height: 1.8;
      }

      &:first-child {
        flex: 33px 0 0;
        width: 33px;
        font-size: 20px;
        line-height: 1.25;
        font-family: $font-family-en;
        font-weight: 700;

        @include mq('md', min) {
          flex: auto 0 0;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    &>ol {
      display: flex;
      gap: 20px;

      &>li {
        display: flex;
        gap: 4px;

        &>span {
          font-size: 16px;
          line-height: 1.4;

          @include mq('md', min) {
            line-height: 1.8;
          }

          &:first-child {
            flex: auto 0 0;
            font-size: 20px;
            line-height: 1.25;
            font-family: $font-family-en;
            font-weight: 700;

            @include mq('md', min) {
              flex: auto 0 0;
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }

    &:nth-child(1) {
      @include mq('md', min) {
        top: 50%;
        left: calc(50% + 219px);
        transform: translateY(-50%);
      }

      &>span {
        &:first-child {
          @include mq('md', min) {
            display: none;
          }
        }
      }

      &>ol {
        @include mq('md', min) {
          flex-direction: column;
          gap: 16px;
        }
        &>li {
          &>span {
            &:first-child {
              &::before {
                @include mq('md', min) {
                  content: "01-";
                }
              }
            }
          }
        }
      }
    }

    &:nth-child(2) {
      @include mq('md', min) {
        top: 31px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(3) {
      @include mq('md', min) {
        top: 88px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(4) {
      @include mq('md', min) {
        top: 156px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(5) {
      @include mq('md', min) {
        top: 209px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(6) {
      @include mq('md', min) {
        top: 248px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(7) {
      @include mq('md', min) {
        top: 287px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(8) {
      @include mq('md', min) {
        top: 328px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(9) {
      @include mq('md', min) {
        top: 366px;
        left: calc(50% - 322px);
      }
    }

    &:nth-child(10) {
      @include mq('md', min) {
        top: 406px;
        left: calc(50% - 322px);
      }
    }
  }
}

.c-card12__list1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 12px;
  margin: 0 -20px -13px;

  @include mq('2xl', min) {
    display: flex;
    gap: 40px;
    justify-content: center;
    //margin: -12px 0 0;
    margin: 0;
  }

  @include mq('3xl', min) {
    gap: 64px;
  }

  &>li {
    position: relative;
    text-align: center;

    &>span {
      font-weight: 700;

      &:nth-of-type(1) {
        font-size: 16px;
        line-height: 2.25;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        @include mq('md', min) {
          font-size: 24px;
          line-height: 1.5;
        }
      }

      &:nth-of-type(2) {
        font-size: 48px;
        line-height: 2.5;
        font-family: $font-family-en;

        @include mq('md', min) {
          margin: 11px 0 0;
          display: block;
        }

        &>span {
          font-size: 40px;
        }
      }
    }
  }
}

.c-card12__list2 {
  display: flex;
  gap: 20px;
  margin-top: 24px;

  @include mq('md', min) {
    gap: 40px;
    margin-top: 30px;
  }

  &>li {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);

    @include mq('md', min) {
      gap: 16px;
      width: calc(50% - 20px);
      font-size: 16px;
    }

    &>span {
      display: block;

      &:first-child {
        text-align: center;
        font-size: 14px;
        font-weight: 700;

        @include mq('md', min) {
          font-size: 24px;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
