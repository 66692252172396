@use "../../global"as *;

.p-block30 {
  background: $neutral--100;
  //border-top: 1px solid $neutral--300;
  padding: 40px 12px 80px;

  @media (prefers-color-scheme: dark) {
    background-color: $neutral--900;
  }

  @include mq('md', min) {
    padding: 120px 100px 160px;
  }

  &.-white {
    padding: 0 12px;
    margin: 40px 0 0;
    background: $text-icon---primary--white;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
    }

    @include mq('md', min) {
      margin: 120px 0 0;
      padding: 0 100px;
    }
  }
}

.p-block30__inner1 {
  @include mq('md', min) {
    min-width: 1000px;
    max-width: 1600px;
    margin: auto;
  }
}

.p-block30__title1 {}
.p-block30__title2 {
  margin: 80px 0 0;

  @include mq('md', min) {
    margin: 120px 0 0;
  }
}

.p-block30__text1 {
  font-size: 16px;
  line-height: 1.8;
  margin: 32px 0 0;

  @include mq('md', min) {
    font-size: $font-size-body;
  }
}

.p-block30__policy1 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
  }
}

.p-block30__policy1__checkbox1 {
  margin: 24px 0 0;
}

.p-block30__form1 {
  margin: 32px 0 0;

  @include mq('md', min) {
    margin: 64px 0 0;
  }
}

.p-block30__formGroup1 {
  margin: 24px 0 0;
}

.p-block30__form1__button1 {
  margin: 24px 0 0;
}

.p-block30__button1 {
  margin: 40px 0 0;

  @include mq('md', min) {
    margin: 48px 0 0;
  }

  .c-button13 {
    @include mq('md', min) {
      width: auto;
    }
  }
}