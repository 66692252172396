@use "../../global" as *;

.p-header1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  z-index: 9999;

  @include mq("md", min) {
    min-width: 1200px;
  }

  &.-open {
    position: fixed;
  }
}

// mobile
.p-header1__mobile1 {
  position: fixed;
  top: 0;
  left: 0;
  background: $neutral--0;
  width: 100%;
  height: 72px;

  @include mq("md", min) {
    display: none;
  }
}

.p-header1__mobile1__logo1 {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  margin: auto;

  a {
    width: 98px;
    height: 29px;
    background-color: $text-icon---primary--black;
    mask-image: url("/assets/images/logo_kurita1_1.svg");
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
  }
}

.p-header1__mobileNav1 {
}

.p-mobileNav1 {
}

.p-mobileNav1__button1 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  padding: 0;
  background: none;
  border: none;
  width: 72px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: $text-icon---primary--white;
  border-left: 1px solid $text-icon---primary--black;
  background: $text-icon---primary--black;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    border-left: 1px solid $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--800;
  }

  &[aria-expanded="true"] {
    border-color: $text-icon---primary--black;
    color: $text-icon---primary--white;
    background: $text-icon---primary--black;

    @media (prefers-color-scheme: dark) {
      border-left: 1px solid $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--800;
    }
  }
}

.p-mobileNav1__button1__inner1 {}

.p-mobileNav1__button1__icon1 {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto 8px;
  position: relative;

  & > span {
    position: absolute;
    inset: 0;
    margin: auto;
    display: block;
    width: 18px;
    height: 2px;
    border-radius: 10rem;
    background: $text-icon---primary--white;
    transform-origin: center;
    transition: all 0.7s $easeOutQuint;

    &:nth-of-type(1) {
      translate: 0 -5px;

      @at-root {
        .p-mobileNav1__button1[aria-expanded="true"] .p-mobileNav1__button1__icon1 > span:nth-of-type(1) {
          rotate: 45deg;
          translate: 0;
          background: $text-icon---primary--white;
        }

        .p-mobileNav1__button1[aria-expanded="true"]:hover .p-mobileNav1__button1__icon1 > span:nth-of-type(1) {
          background: $text-icon---primary--white;
        }
      }
    }

    &:nth-of-type(2) {
      @at-root {
        .p-mobileNav1__button1[aria-expanded="true"] .p-mobileNav1__button1__icon1 > span:nth-of-type(2) {
          opacity: 0;
        }
      }
    }

    &:nth-of-type(3) {
      translate: 0 5px;

      @at-root {
        .p-mobileNav1__button1[aria-expanded="true"] .p-mobileNav1__button1__icon1 > span:nth-of-type(3) {
          rotate: -45deg;
          translate: 0;
          background: $text-icon---primary--white;
        }

        .p-mobileNav1__button1[aria-expanded="true"]:hover .p-mobileNav1__button1__icon1 > span:nth-of-type(3) {
          background: $text-icon---primary--white;
        }
      }
    }
  }
}

.p-mobileNav1__button1__label1 {
  display: block;
  margin: 0;
  line-height: 1.33;
}

.p-mobileNav1__inner1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($text-icon---primary--white, 0.96);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  @media (prefers-color-scheme: dark) {
    background: rgba($text-icon---primary--black, 0.88);
  }

  @at-root {
    .p-header1.-open & {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }
  }
}

.p-header1__mobile1__inner1 {
}

.p-header1__mobile1__header1 {
  position: relative;
  height: 72px;
  z-index: 100;
  background: $text-icon---primary--white;
  border-bottom: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.p-header1__mobile1__header1__logo1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 22px;
  margin: auto;
  display: flex;
  align-items: center;

  a {
    width: 126px;
    height: 29px;
    background-color: $primary--700;
    mask-image: url("/assets/images/logo_kurita2_1.svg");
    mask-size: cover;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    &:hover {
      opacity: 0.5;
    }

    @at-root {
      .p-header1.-open & {
        background-color: $primary--700;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--white;
        }
      }
    }
  }
}

.p-header1__mobile1__header1__button1 {
  position: absolute;
  top: 0;
  right: 72px;
  width: 72px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $neutral--300;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  color: $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @at-root {
    .p-header1.-open & {
      color: $text-icon---primary--white;
    }
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }

    @at-root {
      .p-header1.-open &:hover {
        background: $neutral--800;
      }
    }
  }

  span {
    font-size: 12px;
    font-weight: 600;
    font-family: $font-family-en;
    letter-spacing: 0.02em;
    line-height: 1.33;

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      margin: 0 auto 8px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_user1_1.svg");
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-header1.-open & {
          background: $text-icon---primary--white;
        }
      }
    }
  }
}

.p-header1__mobile1__header1__button2 {
  position: absolute;
  top: 0;
  right: 72px;
  width: 72px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-left: 1px solid $neutral--300;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  color: $text-icon---primary--black;
  background: none;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @at-root {
    .p-header1.-open & {
      background: $text-icon---primary--white;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--black;
      }
    }
  }

  &:hover {
    background: $neutral--100;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }

    @at-root {
      .p-header1.-open &:hover {
        background: $neutral--100;

        @media (prefers-color-scheme: dark) {
          background: $neutral--800;
        }
      }
    }
  }

  &.-active {
    color: $text-icon---primary--black;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--black;
      color: $text-icon---primary--white;
    }
  }

  span {
    font-size: 14px;
    font-weight: 600;
    font-family: $font-family-en;
    letter-spacing: 0.02em;
    line-height: 1.33;
    pointer-events: none;

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      margin: 0 auto 8px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_search1_1.svg");
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-header1.-open .p-header1__mobile1__header1__button2 > span::before,
        .p-header1__mobile1__header1__button2.-active:hover > span::before,
        .p-header1__mobile1__header1__button2.-active > span::before {
          background: $text-icon---primary--black;
        }

        .p-header1__mobile1__header1__button2.-active > span::before {
          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }

        .p-header1__mobile1__header1__button2.-active:hover > span::before {
          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }

        .p-header1.-open .p-header1__mobile1__header1__button2 > span::before {
          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }

        .p-header1__mobile1__header1__button2:hover > span::before {
          background: $text-icon---primary--black;

          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }
      }
    }
  }
}

.p-mobileNav1__navLv1 {
  position: fixed;
  width: 100%;
  top: calc(155px - 84px);
  left: 0;
  overflow-y: scroll;
  height: calc(100dvh - 155px + 84px);
  transition: all 0.7s $easeOutQuint;
  border-top: 1px solid $neutral--300;

  @at-root {
    .p-header1:has(.p-header1__mobile1__header1__button2.-active) & {
      top: 156px;
      height: calc(100dvh - 155px);
    }

    .p-mobileNav1__inner1.-support &,
    .p-mobileNav1__inner1.-company &,
    .p-mobileNav1__inner1.-service & {
      transform: translateX(-100%);
    }
  }
}

.p-mobileNav1__search1 {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 0 20px;
  height: 84px;
  display: grid;
  align-items: center;
  background: $text-icon---primary--white;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }
}

.p-mobileNav1__list1 {
  padding: 24px 0;
  border-bottom: 1px solid $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid $text-icon---primary--white;
  }
}

.p-mobileNav1__list1__item1 {
  color: $text-icon---primary--white;

  a {
    color: $text-icon---primary--white;
  }
}

.p-mobileNav1__list1__item1__button1 {
  background: none;
  border: 0;
  padding: 16px 48px 16px 12px;
  display: grid;
  width: 100%;
  justify-content: left;
  text-align: left;
  gap: 8px;
  position: relative;
  transition: all 0.7s $easeOutQuint;
  color: $text-icon---primary--black;
  overflow: hidden;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  &::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: $text-icon---primary--black;
    mask-image: url("/assets/images/icon_arrow1_1.svg");
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @at-root {
      .p-mobileNav1__list1__item1__button1:hover::before {
        animation: arrow1 0.35s ease-out;
      }
    }
  }
}

.p-mobileNav1__list1__item1__button1__mainLabel1 {
  font-size: 36px;
  font-family: $font-family-en;
  font-weight: 600;
  line-height: 1.33;
}

.p-mobileNav1__list1__item1__button1__subLabel1 {
  font-size: 24px;
  line-height: 1.5;
}

.p-mobileNav1__lang1 {
  padding: 20px 12px;
  border-top: 1px solid $neutral--300;
  border-bottom: 1px solid $neutral--300;
  margin: -1px 0 0;
  position: relative;

  @media (prefers-color-scheme: dark) {
    border-top: 1px solid $text-icon---primary--white;
    border-bottom: 1px solid $text-icon---primary--white;
  }
}

.p-mobileNav1__sns1 {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 24px 0;

  a {
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid $text-icon---primary--black;
    border-radius: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    position: relative;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      border: 1px solid $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      inset: 0;
      margin: auto;
      background: $text-icon---primary--black;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-mobileNav1__sns1 a.-youtube::before {
          mask-image: url("/assets/images/icon_youtube1_1.svg");
        }

        .p-mobileNav1__sns1 a.-x::before {
          mask-image: url("/assets/images/icon_x1_1.svg");
        }

        .p-mobileNav1__sns1 a.-facebook::before {
          mask-image: url("/assets/images/icon_facebook1_1.svg");
        }

        .p-mobileNav1__sns1 a.-linkedin::before {
          mask-image: url("/assets/images/icon_linkedin1_1.svg");
        }
      }
    }
  }
}

.p-mobileNav1__navLv2 {
  position: fixed;
  top: calc(155px - 84px);
  left: 0;
  width: 100%;
  padding: 0 0 0 56px;
  overflow-y: scroll;
  height: calc(100dvh - 155px + 84px);
  transform: translateX(100%);
  transition: all 0.7s $easeOutQuint;
  border-top: 1px solid $neutral--300;

  @at-root {
    .p-header1:has(.p-header1__mobile1__header1__button2.-active) & {
      top: 156px;
      height: calc(100dvh - 155px);
    }
  }

  &.-service {
    @at-root {
      .p-mobileNav1__inner1.-service & {
        transform: translateX(0);
      }
    }
  }

  &.-company {
    @at-root {
      .p-mobileNav1__inner1.-company & {
        transform: translateX(0);
      }
    }
  }

  &.-support {
    @at-root {
      .p-mobileNav1__inner1.-support & {
        transform: translateX(0);
      }
    }
  }
}

.p-mobileNav1__button2 {
  position: fixed;
  z-index: 10;
  top: 72px;
  left: 0;
  width: 56px;
  height: calc(100dvh - 155px + 83px);
  transform: translateX(100vw);
  transition: all 0.7s $easeOutQuint;

  @at-root {
    .p-mobileNav1__inner1.-support &,
    .p-mobileNav1__inner1.-company &,
    .p-mobileNav1__inner1.-service & {
      transform: translateX(0);
    }
  }

  button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    height: calc(100dvh - 155px + 83px);
    border-right: 1px solid $text-icon---primary--black;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      border-right: 1px solid $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    & > span {
      background: none;
      width: 56px;
      color: $text-icon---primary--black;
      font-family: $font-family-en;
      font-weight: 600;
      text-align: left;
      line-height: 2.25;
      font-size: 16px;
      display: grid;
      justify-content: center;
      gap: 10px;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }

      &::before {
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        background: $text-icon---primary--black;
        mask-image: url("/assets/images/icon_arrow1_1.svg");
        mask-size: cover;
        transform: rotate(180deg);
        transition: all 0.7s $easeOutQuint;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--white;
        }
      }
    }
  }
}

.p-mobileNav1__navLv2__title1 {
  & > a {
    font-size: 36px;
    font-family: $font-family-en;
    font-weight: 600;
    color: $text-icon---primary--black;
    line-height: 1.33;
    padding: 36px 58px 36px 12px;
    border-bottom: 1px solid $neutral--300;
    display: block;
    text-decoration: none;
    position: relative;
    transition: all 0.7s $easeOutQuint;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
      border-bottom: 1px solid rgba($text-icon---primary--white, 0.36);
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::before {
      display: block;
      content: "";
      width: 48px;
      height: 48px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-mobileNav1__navLv2__title1 > a:hover::before {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

.p-mobileNav1__navLv2__list1 {
}

.p-mobileNav1__navLv2__list1__item1 {
  & > a,
  & > button {
    text-decoration: none;
    background: none;
    border: none;
    width: 100%;
    display: grid;
    text-align: left;
    gap: 8px;
    padding: 14px 48px 14px 12px;
    position: relative;
    border-bottom: 1px solid $neutral--300;
    transition: all 0.7s $easeOutQuint;
    color: $text-icon---primary--black;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
      border-bottom: 1px solid rgba($text-icon---primary--white, 0.36);
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::before {
      display: block;
      content: "";
      width: 48px;
      height: 48px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      transform: rotate(90deg);
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-mobileNav1__navLv2__list1__item1 > a:hover::before {
          animation: arrow1 0.35s ease-out;
        }

        .p-mobileNav1__navLv2__list1__item1 > button:hover::before {
          animation: arrow2 0.35s ease-out;
        }

        .p-mobileNav1__navLv2__list1__item1 > button[aria-expanded="true"]::before {
          transform: rotate(-90deg);
        }

        .p-mobileNav1__navLv2__list1__item1 > button[aria-expanded="true"]:hover::before {
          animation: arrow3 0.35s ease-out;
        }

        .p-mobileNav1__navLv2__list1__item1 > a::before {
          transform: rotate(0);
        }
      }
    }

    & > span {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.5;
    }
  }
}

.p-mobileNav1__navLv2__list2 {
  display: none;

  @at-root {
    .p-mobileNav1__navLv2__list1__item1 > button[aria-expanded="true"] + & {
      display: block;
    }
  }
}

.p-mobileNav1__navLv2__list2__item1 {
  border-bottom: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid rgba($text-icon---primary--white, 0.36);
  }

  & > a,
  & > button {
    text-decoration: none;
    background: none;
    border: none;
    width: 100%;
    display: block;
    text-align: left;
    font-size: 20px;
    line-height: 1.5;
    color: $text-icon---primary--black;
    text-decoration: none;
    padding: 13px 8px 13px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.7s $easeOutQuint;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    @at-root {
      .p-mobileNav1__navLv2__list2__item1:first-child a {
        padding-left: 12px;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-mobileNav1__navLv2__list2__item1 > a:hover::after {
          animation: arrow1 0.35s ease-out;
        }

        .p-mobileNav1__navLv2__list2__item1 > button:hover::after {
          animation: arrow2 0.35s ease-out;
        }

        .p-mobileNav1__navLv2__list2__item1 > button::after {
          transform: rotate(90deg);
        }

        .p-mobileNav1__navLv2__list2__item1 > button[aria-expanded="true"]::after {
          transform: rotate(-90deg);
        }

        .p-mobileNav1__navLv2__list2__item1 > button[aria-expanded="true"]:hover::after {
          animation: arrow3 0.35s ease-out;
        }

      }
    }

    & > span {
      font-size: 20px;
      line-height: 1.5;
    }
  }
}

.p-mobileNav1__navLv2__list3 {
  display: none;

  @at-root {
    .p-mobileNav1__navLv2__list2__item1 > button[aria-expanded="true"] + & {
      display: block;
    }
  }
}

.p-mobileNav1__navLv2__list3__item1 {
  margin-left: 40px;
  border-top: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    border-top: 1px solid rgba($text-icon---primary--white, 0.36);
  }

  &:first-child {
    margin-left: 0;
    padding-left: 40px;
  }

  & > a {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    color: $text-icon---primary--black;
    text-decoration: none;
    padding: 16px 8px 16px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.7s $easeOutQuint;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-mobileNav1__navLv2__list2__item1 > a:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

.p-mobileNav1__navLv2__list4 {
  padding: 12px 0;
}

.p-mobileNav1__navLv2__list4__item1 {
  a {
    display: block;
    font-size: 20px;
    line-height: 1.5;
    color: $text-icon---primary--black;
    text-decoration: none;
    padding: 13px 8px 13px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.7s $easeOutQuint;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    @at-root {
      .p-mobileNav1__navLv2__list2__item1:first-child a {
        padding-left: 12px;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-mobileNav1__navLv2__list2__item1 > a:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

// desktop
.p-header1__desktop1 {
  display: none;

  @include mq("md", min) {
    display: block;
  }
}

.p-header1__desktop1__inner1 {
  position: relative;
  //height: 86px;
  height: 120px;
  border-bottom: 1px solid $neutral--300;
  transition: background-color 0.7s $easeOutQuint;
  background: $text-icon---primary--white;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--black;
  }

  @at-root {
    .p-header1.-open & {
      background: $neutral--0;
      width: calc(100% + 15px);

      @media screen and (min-width: 1400px) {
        width: calc(100%);
      }

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--black;
      }
    }
  }
}

.p-header1__desktop1__subInner1 {
  min-width: 1000px;
  max-width: 1600px;
  padding: 0 100px;
  height: 100%;
  box-sizing: content-box;
  margin: auto;
  display: flex;
  align-items: center;
  //justify-content: space-between;
}

.p-header1__desktop1__logo1 {
  line-height: 1;
  margin: 36px 80px 0 0;

  a {
    display: block;
    width: 214px;
    height: 50px;
    background-color: $primary--700;
    mask-image: url("/assets/images/logo_kurita2_1.svg");
    mask-size: 214px 50px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.p-header1__desktop1__list1 {
  display: flex;
  height: 76px;
  //position: relative;
  margin-top: 44px;
}

.p-header1__desktop1__list1__item1 {
  > a {
    text-decoration: none;
    color: $text-icon---primary--black;
    font-size: 24px;
    line-height: 1;
    display: block;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    height: 100%;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:not(.-active):hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &.-current {
      font-weight: 600;
    }

    @at-root {
      .p-header1.-open & {
        &:not(.-active):hover {
          background: $neutral--200;

          @media (prefers-color-scheme: dark) {
            background: $neutral--800;
          }
        }
      }
    }

    &.-active {
      font-weight: 600;
    }

    &::before {
      display: block;
      opacity: 0;
      transition: all 0.7s $easeOutQuint;
      content: "";
      width: calc(100% - 40px);
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 20px;
      background: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-header1__desktop1__list1__item1 > a.-current::before,
        .p-header1__desktop1__list1__item1 > a.-active::before {
          opacity: 1;
        }

        .p-header1.-open .p-header1__desktop1__list1__item1 > a.-current::before,
        .p-header1.-open .p-header1__desktop1__list1__item1 > a.-active::before {
          background: $text-icon---primary--black;
          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }
      }
    }
  }
}

.p-header1__desktop1__list1__item2 {
  position: relative;
  margin-left: 20px;
}

.p-header1__desktop1__search1 {
  position: absolute;
  right: 0;
  top: 0;
  //bottom: 0;
  //margin: 0 0 0 20px;
  display: none;
  flex-direction: row-reverse;
  align-items: center;
  gap: 8px;
  padding: 15px 20px;
  background: $text-icon---primary--white;
  box-shadow: 0 8px 32px 0 rgba($text-icon---primary--black, 0.16);

  &.-active {
    display: flex;
  }

  @at-root {
    .p-header1__desktop1__searchButton1.-active + &,
    .p-header1__desktop1__list1__item2:hover  & {
      display: flex;
    }
  }
}

.p-header1__desktop1__search1__close1 {
  appearance: none;
  border: none;
  background: none;
  position: relative;
  width: 24px;
  height: 24px;
  transition: all 0.7s $easeOutQuint;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 2px;
    margin: auto;
    border-radius: 2px;
    background: $text-icon---secondary--black;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  @at-root {
    .p-header1__desktop1__search1__close1:hover {
      animation: arrow1 0.35s ease-out;
    }
  }
}

.p-header1__desktop1__searchButton1 {
  appearance: none;
  border: none;
  display: flex;
  gap: 4px;
  background: transparent;
  color: $text-icon---primary--black;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  height: 100%;
  transition: all 0.7s $easeOutQuint;

  &::after {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    background-color: $text-icon---secondary--black;
    mask-image: url("/assets/images/icon_search1_1.svg");
    mask-size: cover;

    @at-root {
      .c-formText1.-active::before {
        cursor: auto;
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;

    &::after {
      background-color: $text-icon---primary--white;
    }
  }

  &:not(.-active):hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }
}

.p-header1__desktop1__utility1 {
  position: absolute;
  top: 0;
  right: 40px;
  display: flex;
  gap: 40px;
  height: 44px;
  padding-top: 22px;
}

.p-header1__desktop1__button2 {
  position: absolute;
  top: 0;
  right: 120px;
  width: 120px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $neutral--300;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  color: $text-icon---primary--black;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @at-root {
    .p-header1.-open & {
      color: $text-icon---primary--white;
    }
  }

  &:hover {
    background: $neutral--100;

    @at-root {
      .p-header1.-open &:hover {
        color: $text-icon---primary--black;
        background: $neutral--800;
        color: $text-icon---primary--white;

        @media (prefers-color-scheme: dark) {
          color: $text-icon---primary--white;
        }
      }
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  span {
    font-size: 12px;
    font-weight: 600;
    font-family: $font-family-en;
    letter-spacing: 0.02em;
    line-height: 1.33;

    @include mq("md", min) {
      font-size: 16px;
    }

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      margin: 0 auto 8px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_user1_1.svg");
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-header1.-open & {
          background: $text-icon---primary--white;
        }

        .p-header1__desktop1__button2:hover > span::before {
          background: $text-icon---primary--black;

          @media (prefers-color-scheme: dark) {
            background: $text-icon---primary--white;
          }
        }
      }
    }
  }
}

.p-header1__desktop1__button3 {
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  color: $text-icon---primary--black;
  font-size: 16px;
  line-height: 20px;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--100;

    @at-root {
      .p-header1.-open &:hover {
        background: $neutral--200;

        @media (prefers-color-scheme: dark) {
          background: $neutral--800;
          color: $text-icon---primary--white;
        }
      }
    }

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }
}

.p-header1__desktop1__lang1 {
}

.p-header1__desktop1__button1 {
  position: fixed;
  left: 0;
  top: 11px;
  padding: 0;
  background: none;
  text-align: left;
  border: none;
  width: 64px;
  height: 64px;
  display: grid;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: $text-icon---primary--black;
  border: 1px solid $text-icon---primary--black;
  border-left: 0;
  border-radius: 0 10rem 10rem 0;
  transition: all 0.7s $easeOutQuint;
  background: $text-icon---primary--white;

  @media (prefers-color-scheme: dark) {
    background: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--300;
    }
  }

  &[aria-expanded="true"] {
    color: $text-icon---primary--black;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--black;
    }

    &:hover {
      background: $text-icon---primary--white;
      color: $text-icon---primary--black;

      @media (prefers-color-scheme: dark) {
        background: $neutral--300;
      }
    }
  }
}

.p-header1__desktop1__button1__icon1 {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0 0 16px;
  position: relative;

  & > span {
    position: absolute;
    inset: 0;
    margin: auto;
    display: block;
    width: 18px;
    height: 2px;
    border-radius: 10rem;
    background: $neutral--700;
    transform-origin: center;
    transition: all 0.7s $easeOutQuint;

    &:nth-of-type(1) {
      translate: 0 -5px;

      @at-root {
        .p-header1__desktop1__button1[aria-expanded="true"] .p-header1__desktop1__button1__icon1 > span:nth-of-type(1) {
          rotate: 45deg;
          translate: 0;
        }
      }
    }

    &:nth-of-type(2) {
      @at-root {
        .p-header1__desktop1__button1[aria-expanded="true"] .p-header1__desktop1__button1__icon1 > span:nth-of-type(2) {
          opacity: 0;
        }
      }
    }

    &:nth-of-type(3) {
      translate: 0 5px;

      @at-root {
        .p-header1__desktop1__button1[aria-expanded="true"] .p-header1__desktop1__button1__icon1 > span:nth-of-type(3) {
          rotate: -45deg;
          translate: 0;
        }
      }
    }
  }
}

.p-header1__desktop1__button1__label1 {
  display: block;
  margin: 0 0 0 8.5px;
}

.p-header1__desktop1__nav1 {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  background: rgba($text-icon---primary--white, 0.96);
  transition: opacity 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    background: rgba($text-icon---primary--black, 0.88);
  }

  @at-root {
    .p-header1.-open &,
      .p-header1__desktop1__list1__item1:hover > a + & {
      opacity: 1;
      pointer-events: all;
      backdrop-filter: blur(7px);
    }
  }
}

.p-desktopNav1 {
  //width: 100vw;
  width: calc(100vw * 1.5);
  //height: calc(100vh - 86px);
  overflow: hidden;
  height: auto;
  @at-root {
    .p-header1.-open.-all & {
      overflow: scroll;
      height: calc((100vh * 1.5) - 86px);
    }
    .p-header1.-open:not(.-all) & {
      overflow: hidden;
      height: auto;
    }
  }
}

.p-desktopNav1__inner1 {
  min-width: 1000px;
  max-width: 1600px;
  padding: 128px 100px;
  box-sizing: content-box;
  margin: auto;
}

.p-desktopNav1__list1 {
  gap: 40px;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  display: none;

  @at-root {
    .p-header1.-open.-all & {
      display: grid;
    }
  }
}

.p-desktopNav1__list1__item1 {
  border-bottom: 1px solid $text-icon---primary--white;
}

.p-desktopNav1__list1__item1__category1 {
  display: grid;
  font-family: $font-family-en;
  font-weight: 600;
  gap: 8px;
  color: $text-icon---primary--black;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  padding: 0 0 32px;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;
    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  & > span {
    &:first-child {
      font-size: 64px;
      line-height: 0.75;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-icon---primary--black;
      line-height: 2;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }

      &::after {
        display: block;
        content: "";
        width: 48px;
        height: 48px;
        background: $text-icon---primary--black;
        mask-image: url("/assets/images/icon_arrow1_1.svg");
        transition: all 0.7s $easeOutQuint;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--white;
        }

        @at-root {
          .p-desktopNav1__list1__item1__category1:hover span:last-child::after {
            animation: arrow1 0.35s ease-out;
          }
        }
      }
    }
  }
}

.p-desktopNav1__list2 {
}

.p-desktopNav1__list2__item1 {
  border-top: 1px solid $neutral--300;

  @media (prefers-color-scheme: dark) {
    border-color: $text-icon---primary--white;
  }

  & > a,
  & > button {
    padding: 32px 0;
    display: block;
    text-decoration: none;
    color: $text-icon---primary--black;
    background: none;
    border: none;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 48px;
      height: 48px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      transform: rotate(90deg);
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-desktopNav1__list2__item1:hover > a::after {
          animation: arrow1 0.35s ease-out;
        }

        .p-desktopNav1__list2__item1 > a::after {
          transform: rotate(0deg);
        }

        .p-desktopNav1__list2__item1 > button:hover::after {
          animation: arrow2 0.35s ease-out;
        }

        .p-desktopNav1__list2__item1 > button[aria-expanded="true"]::after {
          transform: rotate(-90deg);
        }

        .p-desktopNav1__list2__item1 > button[aria-expanded="true"]:hover::after {
          animation: arrow3 0.35s ease-out;
        }
      }
    }
  }
}

.p-desktopNav1__list3 {
  display: none;

  @at-root {
    .p-desktopNav1__list2__item1 > button[aria-expanded="true"] + & {
      display: block;
    }
  }
}

.p-desktopNav1__list3__item1 {
  border-top: 1px solid rgba($text-icon---primary--white, 0.24);

  & > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: $text-icon---primary--black;
    font-size: 16px;
    line-height: 2.25;
    padding: 10px 8px 10px 40px;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    @at-root {
      .p-desktopNav1__list3__item1:first-child > a {
        padding-left: 0;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      mask-size: cover;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-desktopNav1__list3__item1 > a:hover::after {
          animation: arrow1 0.35s ease-out;
        }
      }
    }
  }
}

.p-desktopNav1__list4 {
  //display: none;

  @at-root {
    .p-header1.-open.-service &.-service {
      display: block;
    }

    .p-header1.-open.-company &.-company {
      display: block;
    }

    .p-header1.-open.-support &.-support {
      display: block;
    }
  }
}

.p-desktopNav1__list4__item1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 40px;
}

.p-desktopNav1__list4__item1__category1 {
  display: grid;
  font-weight: 600;
  gap: 8px;
  color: $text-icon---primary--black;
  text-decoration: none;
  transition: all 0.7s $easeOutQuint;
  padding: 0 0 32px;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  &:hover {
    background: $neutral--200;
    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }
  }

  & > span {
    //&:first-child {
    //  font-size: 48px;
    //  line-height: 0.75;
    //}

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-icon---primary--black;
      //font-family: $font-family-en;
      //font-size: 24px;
      font-size: 48px;
      line-height: 2;
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        color: $text-icon---primary--white;
      }

      &::after {
        display: block;
        content: "";
        width: 48px;
        height: 48px;
        background: $text-icon---primary--black;
        mask-image: url("/assets/images/icon_arrow1_1.svg");
        transition: all 0.7s $easeOutQuint;

        @media (prefers-color-scheme: dark) {
          background: $text-icon---primary--white;
        }

        @at-root {
          .p-desktopNav1__list4__item1__category1:hover span:last-child::after {
            animation: arrow1 0.35s ease-out;
          }
        }
      }
    }
  }
}

.p-desktopNav1__list5 {
  grid-column: 1 / 4;
  grid-row-start: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 40px;
}

.p-desktopNav1__list5__item1 {
  border-top: 1px solid $neutral--300;
  border-bottom: 1px solid $neutral--300;
  margin: -1px 0 0;

  @media (prefers-color-scheme: dark) {
    border-color: $text-icon---primary--white;
  }

  & > a {
    padding: 32px 0;
    display: block;
    text-decoration: none;
    color: $text-icon---primary--black;
    background: none;
    border: none;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.7s $easeOutQuint;

    @media (prefers-color-scheme: dark) {
      color: $text-icon---primary--white;
    }

    &:hover {
      background: $neutral--200;

      @media (prefers-color-scheme: dark) {
        background: $neutral--800;
      }
    }

    &::after {
      display: block;
      content: "";
      width: 48px;
      height: 48px;
      background: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_arrow1_1.svg");
      transform: rotate(90deg);
      transition: all 0.7s $easeOutQuint;

      @media (prefers-color-scheme: dark) {
        background: $text-icon---primary--white;
      }

      @at-root {
        .p-desktopNav1__list5__item1:hover > a::after {
          animation: arrow1 0.35s ease-out;
        }

        .p-desktopNav1__list5__item1 > a::after {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.p-desktopNav2 {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

// is-darkmode
.is-darkmode {
}

// programmablesearchengine.google - OverWrite
.p-header1__desktop1__search1,
.p-mobileNav1__search1 {
  .gsc-control-cse {
    padding: 0;
    background: transparent;
    border: none;
  }
  form.gsc-search-box {
    margin: 0;
    font-size: 16px;
  }
  table.gsc-search-box {
    display: block;
    margin: 0;
    td.gsc-input {
      display: block;
      padding-right: 0;
    }
    .gsib_a {
      display: block;
      padding: 0;
    }
    .gsib_b {
      display: none;
    }
    @include mq("md", min) {
      .gsib_b {
        display: block;
      }
    }
    tbody,
    tr {
      display: block;
    }
  }
  .gsc-input-box {
    position: relative;
    border: none;
    background: transparent;
    @include mq("md", min) {
      padding: 0;
      border-radius: 0;
      cursor: pointer;
    }
    @include mq("md", max) {
    }
    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 9px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: $text-icon---primary--black;
      mask-image: url("/assets/images/icon_search1_1.svg");

      @include mq("md", min) {
        width: 42px;
        height: 42px;
        left: 0;
        background: url("/assets/images/icon_search1_1.svg") center center / cover no-repeat;
        background-size: 24px auto;
        mask-image: none;
      }

      @at-root {
        .c-formText1.-active::before {
          cursor: auto;
        }
      }
    }
    input.gsc-input {
      content: "フリーワードで探す";
      width: 100%;
      height: auto !important;
      border-radius: 10rem;
      font-size: 16px;
      line-height: 1.5;
      font-family: $font-family-jp;
      margin: 0 !important;
      padding: 8px 0 8px 40px !important;
      transition: all 0.7s $easeOutQuint;
      border: 1px solid $neutral--300 !important;
      background: $text-icon---primary--white !important;
      color: $text-icon---primary--black !important;

      @include mq("md", min) {
        background: $neutral--0 !important;
        border-radius: 0;
        border: none!important;
        border-bottom: 2px solid $text-icon---primary--black !important;
        padding: 10px 0 10px 40px !important;
        color: $text-icon---primary--black !important;
        width: 400px !important;
        margin-right: 24px !important;
        cursor: pointer;
      }

      &::placeholder {
        font-size: 16px;
        color: $text-icon---primary--black;

        @include mq("md", min) {
          color: $text-icon---secondary--black;
        }
      }

      &:where(:focus-visible) {
        outline: 2px solid #91b5fd !important;
        outline-offset: 2px !important;
      }
    }
    &:not(.-active):hover input.gsc-input {
      @include mq("md", min) {
        background: $neutral--200 !important;
      }
    }
    &.-active input.gsc-input {
      @include mq("md", min) {
        //width: 100%!important;
        //width: 213px !important;
        cursor: auto;
      }
    }
  }
  table.gsc-input {
    display: block;
  }
  .gsc-search-button,
  .gsc-clear-button {
    display: none;
  }
  @include mq("md", min) {
    .gsst_b {
      position: static !important;
    }
    .gsst_a {
      position: absolute;
      right: -8px;
      top: 0;
      bottom: 0;
      display: block !important;
      width: 24px;
      height: 24px;
      margin: auto;
      transition: all 0.7s $easeOutQuint;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 18px;
        height: 2px;
        margin: auto;
        border-radius: 2px;
        background: $text-icon---secondary--black;
      }

      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }

      &:hover {
        animation: arrow1 0.35s ease-out;
      }

      span {
        display: none;
      }
    }
  }
}

// shutto-translation - OverWrite
.stt-lang-select {
  opacity: 0 !important;
  pointer-events: none;
}
