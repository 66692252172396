@use "../../global"as *;

.c-textLink5 {
  color: $text-icon---primary--black;
  font-size: 16px;
  line-height: 1.8;
  display: inline-flex;
  font-weight: 700;
  gap: 8px;
  padding: 0 8px 0 0;
  transition: all 0.7s $easeOutQuint;

  @media (prefers-color-scheme: dark) {
    color: $text-icon---primary--white;
  }

  @include mq('md', min) {
    font-size: $font-size-body;
  }

  &:hover {
    text-decoration: none;
    background: $neutral--200;

    @media (prefers-color-scheme: dark) {
      background: $neutral--800;
    }

    @at-root {
      .c-textLink5.-hoverWhite:hover {
        background: $text-icon---primary--white;
      }
    }
  }

  &::before {
    display: block;
    content: '';
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    background: $text-icon---primary--black;
    mask-image: url('/assets/images/icon_arrow1_1.svg');
    mask-size: cover;
    transition: all 0.7s $easeOutQuint;
    margin: 4px 0 0;

    @media (prefers-color-scheme: dark) {
      background: $text-icon---primary--white;
    }

    @include mq('md', min) {
      margin: 6px 0 0;
    }

    @at-root {
      .c-textLink5:hover::before {
        animation: arrow1 0.35s ease-out;
      }

      .c-textLink5.-disabled::before {
        background: $text-icon---disabled--black;
      }
    }
  }

  &.-disabled {
    color: $text-icon---disabled--black;
    text-decoration: none;
    pointer-events: none;
  }

  &.is-external {
    &::before {
      right: 8px;
      mask-image: url("/assets/images/icon_external1_1.svg");
      mask-size: cover;

      &:hover::after {
        animation: arrow1 0.35s ease-out;
      }
    }
  }

}
