@use "../../global"as *;

.p-block24 {
  margin: 80px 0 0;
  padding: 0 12px;
  overflow: hidden;

  @include mq('md', min) {
    position: relative;
    padding: 0 100px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.p-block24__inner1 {
  @include mq('md', min) {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    min-width: 1000px;
    max-width: 1600px;
    padding: 0 100px;
    box-sizing: content-box;
    margin: auto;
    z-index: 10;
  }
}

.p-block24__image1 {
  position: relative;
  height: 280px;
  margin: 0 -12px;
  overflow: hidden;

  @include mq('md', min) {
    height: 1080px;
    margin: 0 -100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-block24__image1__labelEn1 {
  font-size: 60px;
  line-height: 1;
  font-family: $font-family-en;
  font-weight: 600;
  white-space: nowrap;
  position: absolute;
  right: -40px;
  bottom: -12px;
  color: $text-icon---primary--white;
  text-transform: uppercase;

  @include mq('md', min) {
    font-size: 240px;
    right: -82px;
    bottom: -47px;
  }
}
